import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadPartType(action) {
    const {
        data: {
            page,
            pageSize,
            companyID,
            orderBy,
            orderType,
            accessToken,
            filterObj
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/getparttype`,
            {
                page,
                pageSize,
                companyID,
                orderBy,
                orderType,
                filterObj
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.loadPartType.success(res.data, page));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadPartType.failure());
    }
}


function* createPartType(action){
    const {
        data : {
            company_id, parttype_name, accessToken
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/parttype/create`,
            {
                company_id, parttype_name
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.createPartType.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.createPartType.failure());
    }
}

function* loadDetailPartType(action) {
    const {
        data: {
            id,
            accessToken
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.get,
                `${process.env.REACT_APP_API_ENDPOINT}/parttype/detali/${id}`,
                { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.loadDetailPartType.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadDetailPartType.failure());
    }
}

function* updatePartType(action){
    const {
        data : {
            id, parttype_names, accessToken
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/parttype/update`,
            {
                id, parttype_names
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.updatePartType.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.updatePartType.failure());
    }
}

export default function* watchPartType() {
    yield all([
        takeEvery(actions.LOAD_PARTTYPE.REQUEST, loadPartType),
        takeEvery(actions.CREATE_PARTTYPE.REQUEST, createPartType),
        takeEvery(actions.DETAIL_PARTTYPE.REQUEST, loadDetailPartType),
        takeEvery(actions.UPDATE_PARTTYPE.REQUEST, updatePartType),
    ]);
}
export {
    loadPartType,
    createPartType,
    loadDetailPartType,
    updatePartType
}