import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID
} from "./../../../constants/local_storage";
import * as actions from "./actions";

function* loadTrip(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      vehicle_visibility,
      filterObj
    }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/trip`,
      // `http://booking-api/api/trip`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        vehicle_visibility,
        filterObj
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadTrip.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadTrip.failure());
  }
}

function* loadTripDetail(action) {
  const {
    data: { id },
    cb
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/trip/detail/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    // cb(res.data[0]);

    yield put(actions.loadTripDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadTripDetail.failure());
  }
}

function* createTrip(action) {
  const {
    data: {
      trip_code,
      vehicle_id,
      driver_id,
      plant_id,
      site_id,
      start_deliver_at,
      trip_type
    },
    cb
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/trip/create`,
      {
        company_id: COMPANY_ID,
        trip_code,
        vehicle_id,
        driver_id,
        plant_id,
        site_id,
        start_deliver_at,
        trip_type,
        created_by: USER_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    cb(res.data);
    yield put(actions.createTrip.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createTrip.failure());
  }
}

function* updateTrip(action) {
  const {
    data: {
      trip_id,
      trip_code,
      vehicle_id,
      driver_id,
      plant_id,
      site_id,
      start_deliver_at,
      trip_type
    },
    cb
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/trip/update`,
      {
        trip_id,
        company_id: COMPANY_ID,
        trip_code,
        vehicle_id,
        driver_id,
        plant_id,
        site_id,
        start_deliver_at,
        trip_type,
        updated_by: USER_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    cb();

    yield put(actions.updateTrip.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateTrip.failure());
  }
}

export default function* watchTripState() {
  yield all([
    takeEvery(actions.CREATE_TRIP.REQUEST, createTrip),
    takeEvery(actions.LOAD_TRIP.REQUEST, loadTrip),
    takeEvery(actions.LOAD_TRIP_DETAIL.REQUEST, loadTripDetail),
    takeEvery(actions.UPDATE_TRIP.REQUEST, updateTrip)
  ]);
}

export { createTrip, loadTrip, loadTripDetail, updateTrip };
