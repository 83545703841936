import { createTypes, createAction } from "lib/action";

const LOAD_ALARMALERTBYPLATENO2 = createTypes("truckinspectionbytruck", "load");
const LOAD_VEHICLE_ALARMALERTBYPLATENO2 = createTypes(
  "vehicle_alarmalertbyplateno",
  "load"
);
const LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO2 = createTypes("vehicletype_alarmalertbyplateno", "load");

const loadAlarmAlertByPlateno = {
  request: (data, loading) =>
    createAction(LOAD_ALARMALERTBYPLATENO2.REQUEST, { data, loading }),
  success: data => createAction(LOAD_ALARMALERTBYPLATENO2.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARMALERTBYPLATENO2.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_ALARMALERTBYPLATENO2.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_ALARMALERTBYPLATENO2.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_ALARMALERTBYPLATENO2.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO2.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO2.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO2.FAILURE)
};

export {
  loadAlarmAlertByPlateno,
  LOAD_ALARMALERTBYPLATENO2,
  loadVehicle,
  LOAD_VEHICLE_ALARMALERTBYPLATENO2,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO2
};
