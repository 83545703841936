import { createTypes, createAction } from "lib/action";

const LOAD_TRUCKINSPECTIONBYMONTH = createTypes("truckinspectionbymonth", "load");
const LOAD_COMPANY_TRUCKINSPECTIONBYMONTH = createTypes(
  "company_truckinspectionbymonth",
  "load"
);
const LOAD_PLANT_TRUCKINSPECTIONBYMONTH = createTypes(
  "plant_truckinspectionbymonth",
  "load"
);
const LOAD_TRAINER_TRUCKINSPECTIONBYMONTH = createTypes(
  "trainer_truckinspectionbymonth",
  "load"
);
//const LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO = createTypes("vehicletype_alarmalertbyplateno", "load");

const loadAlarmAlertByPlateno = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKINSPECTIONBYMONTH.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRUCKINSPECTIONBYMONTH.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCKINSPECTIONBYMONTH.FAILURE)
};

const loadVehicle = {
  request: (loading_vehicle) =>
    createAction(LOAD_COMPANY_TRUCKINSPECTIONBYMONTH.REQUEST, {
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_COMPANY_TRUCKINSPECTIONBYMONTH.SUCCESS, { data }),
  failure: () => createAction(LOAD_COMPANY_TRUCKINSPECTIONBYMONTH.FAILURE)
};

const loadPlant = {
  request: (loading_plant) =>
    createAction(LOAD_PLANT_TRUCKINSPECTIONBYMONTH.REQUEST, {
      loading_plant
    }),
  success: data =>
    createAction(LOAD_PLANT_TRUCKINSPECTIONBYMONTH.SUCCESS, { data }),
  failure: () => createAction(LOAD_PLANT_TRUCKINSPECTIONBYMONTH.FAILURE)
};

const loadTrainer = {
  request: (loading_trainer) =>
    createAction(LOAD_TRAINER_TRUCKINSPECTIONBYMONTH.REQUEST, {
      loading_trainer
    }),
  success: data =>
    createAction(LOAD_TRAINER_TRUCKINSPECTIONBYMONTH.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRAINER_TRUCKINSPECTIONBYMONTH.FAILURE)
};



export {
  loadAlarmAlertByPlateno,
  LOAD_TRUCKINSPECTIONBYMONTH,
  loadVehicle,
  LOAD_COMPANY_TRUCKINSPECTIONBYMONTH,
  loadPlant,
  LOAD_PLANT_TRUCKINSPECTIONBYMONTH,
  loadTrainer,
  LOAD_TRAINER_TRUCKINSPECTIONBYMONTH
  //loadVehicleType,
  //LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO
};
