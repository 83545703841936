import { createTypes, createAction } from "lib/action";


const LOAD_MDVRDOWNLOADFILE = createTypes("mdvrdownloadfile", "load");
const LOAD_VEHICLE_MDVRDOWNLOADFILE = createTypes(
  "vehicle_mdvrdownloadfile",
  "load"
);

const loadMdvrdownloadfile = {
  request: (data, loading) =>
    createAction(LOAD_MDVRDOWNLOADFILE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_MDVRDOWNLOADFILE.SUCCESS, { data }),
  failure: () => createAction(LOAD_MDVRDOWNLOADFILE.FAILURE)
};



const loadVehicle = {
  request: () => createAction(LOAD_VEHICLE_MDVRDOWNLOADFILE.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_MDVRDOWNLOADFILE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_MDVRDOWNLOADFILE.FAILURE)
};

export {
  loadMdvrdownloadfile,
  LOAD_MDVRDOWNLOADFILE,
  loadVehicle,
  LOAD_VEHICLE_MDVRDOWNLOADFILE

};
