import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { USER_ID } from "./../../../constants/local_storage";
import * as actions from "./actions";

function* loadDeliveryItem(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      filterObj,
      accessToken
    }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/deliveryitem`,
      // `http://booking-api/api/deliveryitem`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
        accessToken
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadDeliveryItem.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDeliveryItem.failure());
  }
}

function* loadDeliveryItemDetail(action) {
  const {
    data: { id, accessToken },
    cb
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/deliveryitem/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    // cb(res.data[0]);
    yield put(actions.loadDeliveryItemDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDeliveryItemDetail.failure());
  }
}

function* createDeliveryItem(action) {
  const {
    data: {
      files,
      channel,
      job_type,
      uploader,
      order_code_prefix,
      order_code,
      end_warranty_date,
      remark,
      user_id,
      detailData,
      deletedDetail,
      accessToken
    },
    cb
  } = action.payload;

  let formData = new FormData();
  let uploadFile = null;

  files.forEach(file => {
    uploadFile = file;
  });

  let new_order_code =
    order_code_prefix != "" ? order_code_prefix + order_code : order_code;
  formData.append("files", uploadFile);
  formData.append("channel", channel);
  formData.append("job_type", job_type);
  formData.append("order_code", new_order_code);
  formData.append("end_warranty_date", end_warranty_date);
  formData.append("remark", remark);
  formData.append("user_id", user_id);
  formData.append("detailData", JSON.stringify(detailData));
  formData.append("deletedDetail", JSON.stringify(deletedDetail));

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/deliveryitem/create`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    cb(res);
    yield put(actions.createDeliveryItem.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createDeliveryItem.failure());
  }
}

function* updateDeliveryItem(action) {
  const {
    data: {
      deliveryitem_id,
      state,
      files,
      channel,
      job_type,
      uploader,
      order_code_prefix,
      order_code,
      end_warranty_date,
      remark,
      user_id,
      detailData,
      deletedDetail,
      accessToken
    },
    cb
  } = action.payload;

  let formData = new FormData();
  let uploadFile = null;

  if (typeof files != "undefined") {
    files.forEach(file => {
      uploadFile = file;
    });
  } else {
    uploadFile = null;
  }

  formData.append("deliveryitem_id", deliveryitem_id);
  formData.append("state", state);
  formData.append("files", uploadFile);
  formData.append("channel", channel);
  formData.append("job_type", job_type);
  formData.append("order_code", order_code);
  formData.append("end_warranty_date", end_warranty_date);
  formData.append("remark", remark);
  formData.append("user_id", user_id);
  formData.append("detailData", JSON.stringify(detailData));
  formData.append("deletedDetail", JSON.stringify(deletedDetail));

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/deliveryitem/update`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    cb(res);

    yield put(actions.updateDeliveryItem.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateDeliveryItem.failure());
  }
}

export default function* watchDeliveryItemMasterDataState() {
  yield all([
    takeEvery(actions.CREATE_DELIVERYITEM.REQUEST, createDeliveryItem),
    takeEvery(actions.LOAD_DELIVERYITEM.REQUEST, loadDeliveryItem),
    takeEvery(actions.LOAD_DELIVERYITEM_DETAIL.REQUEST, loadDeliveryItemDetail),
    takeEvery(actions.UPDATE_DELIVERYITEM.REQUEST, updateDeliveryItem)
  ]);
}

export {
  createDeliveryItem,
  loadDeliveryItem,
  loadDeliveryItemDetail,
  updateDeliveryItem
};
