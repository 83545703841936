import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Table,
  Form,
  Row,
  Col,
  Input,
  Pagination,
  Spin,
  Popconfirm,
  Tooltip,
  Icon,
  message,
} from "antd";
import axios from "axios";

import IndexFilterDrawer from "./IndexFilterDrawer";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { helper_convertDateformat } from "../../../../lib/helper";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import * as actions from "../actions";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import history from "../../../../lib/history";
import TableDetail from "./TableDetail";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilter: false,
      filterVisible: false,
      expand: false,
      currentPage: 1,
      page: 1,
      pageSize: 10,
      searchName: "",
      visible: false,
      cancelReason: "",
      selectedJob: "",
      orderBy: "created_at",
      orderType: "desc",
      filterObj: {
        FilterObjTripCode: "",
        FilterObjDriver: "",
        FilterObjVehicleCode: "",
        FilterObjPlateNo: "",
        FilterObjLocation: "",
        FilterObjInvoice: "",
        FilterObjStartDate: "",
        FilterObjEndDate: ""
      }
    };

    this.columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.t.tripCode}</LangContext.Consumer>
        ),
        dataIndex: "trip_code",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.i.invoice_number}</LangContext.Consumer>
        ),
        dataIndex: "invoice_number",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.v.vehicleCode}
          </LangContext.Consumer>
        ),
        dataIndex: "code",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer>
        ),
        dataIndex: "plate_no",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.d.driverName}
          </LangContext.Consumer>
        ),
        dataIndex: "driver_name",
        // width: 230,
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.l.Location}</LangContext.Consumer>
        ),
        dataIndex: "location",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.p.plan_start_deliver_at}
          </LangContext.Consumer>
        ),
        dataIndex: "load_datetime",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: (
              <div>
                {helper_convertDateformat(
                  text,
                  "YYYY-MM-DD HH:mm:ss",
                  "DD/MM/YYYY HH:mm"
                )}
              </div>
            )
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.c.createdAt}
          </LangContext.Consumer>
        ),
        dataIndex: "created_at",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: (
              <div>
                {helper_convertDateformat(
                  text,
                  "YYYY-MM-DD HH:mm:ss",
                  "DD/MM/YYYY HH:mm"
                )}
              </div>
            )
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.c.created_by}</LangContext.Consumer>
        ),
        dataIndex: "created_by",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.a.action}</LangContext.Consumer>
        ),
        key: "action_btn",
        render: (text, record, index) => {
          return (
            <div>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {i18n => i18n.d.delete}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Popconfirm
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.d.doYouWantToDeleteTheseItems}
                    </LangContext.Consumer>
                  }
                  onConfirm={() => {
                    let self = this;
                    self.deleteTrip(record.trip_id);
                  }}
                >
                  <a
                    href="javascript:;"
                    style={{ padding: "0px 15px 0px 5px" }}
                  >
                    <Icon type="close" />
                  </a>
                </Popconfirm>
              </Tooltip>

              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {i18n => i18n.e.edit}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Link
                  to={`/deliverytrip/edit/${record.trip_id}`}
                // disabled={disabled}
                >
                  <Icon type="edit" />
                </Link>
              </Tooltip>
            </div>
          );
        }
      }
    ];
  }

  componentDidMount() {
    this.loadData(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataResponse } = this.props.deliverytripState;

    if (dataResponse.action_type == "create" || dataResponse.action_type == "update") {
      if (dataResponse.status == "success") {
        message.info("สำเร็จ");
      } else if (dataResponse.status == "fail") {
        message.info("ไม่สำเร็จ");
      }
      this.props.clearDataResponse();

    }


  }

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      this.setState({
        orderBy: "created_at",
        orderType: "asc"
      });
      orderBy = "created_at";
      orderType = "asc";
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == "descend" ? "desc" : "asc"
      });
      orderBy = sorter.columnKey;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadTrip(
      {
        companyID: this.props.auth.profile.company_id,
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        job_status: "<= 20",
        orderBy: orderBy,
        orderType: orderType,
        filterObj: this.state.filterObj
      },
      true
    );
  };

  loadData = loading => {
    this.props.loadTrip(
      {
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        companyID: this.props.auth.profile.company_id,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: this.state.filterObj
      },
      loading
    );
  };

  handleSearch = value => {
    if (value != this.state.searchName) {
      this.props.loadTrip(
        {
          vehicle_visibility: this.props.auth.profile.vehicle_visibility,
          companyID: this.props.auth.profile.company_id,
          page: 1,
          pageSize: 10,
          searchName: value,
          orderBy: this.state.orderBy,
          orderType: this.state.orderType,
          filterObj: this.state.filterObj
        },
        true
      );
      this.setState({
        searchName: value,
        currentPage: 1
      });
    }
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadTrip(
      {
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        companyID: this.props.auth.profile.company_id,
        page: page,
        pageSize: pageSize,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: this.state.filterObj
      },
      true
    );
  };

  deleteTrip = id => {
    let self = this;

    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/deliverytrip/delete/${id}`, {
        headers: { Authorization: `Bearer ${this.props.auth.accessToken}` }
      })
      .then(function (response) {
        self.loadData(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  showFilterDrawer = () => {
    this.setState({
      filterVisible: true
    });
  };

  onFilterDrawerClose = () => {
    this.setState({
      filterVisible: false
    });
  };

  onFilterButtonClick = e => {
    this.loadData(true);
  };

  handleFilterObjTripCode = e => {
    this.setState({
      filterObj: { ...this.state.filterObj, FilterObjTripCode: e.target.value },
      isFilter: e.target.value != null && e.target.value != ""
    });
  };

  handleFilterObjInvoice = e => {
    this.setState({
      filterObj: { ...this.state.filterObj, FilterObjInvoice: e.target.value },
      isFilter: e.target.value != null && e.target.value != ""
    });
  };

  handleFilterObjDriver = e => {
    this.setState({
      filterObj: { ...this.state.filterObj, FilterObjDriver: e.target.value },
      isFilter: e.target.value != null && e.target.value != ""
    });
  };

  handleFilterObjVehicleCode = e => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        FilterObjVehicleCode: e.target.value
      },
      isFilter: e.target.value != null && e.target.value != ""
    });
  };

  handleFilterObjPlateNo = e => {
    this.setState({
      filterObj: { ...this.state.filterObj, FilterObjPlateNo: e.target.value },
      isFilter: e.target.value != null && e.target.value != ""
    });
  };

  handleFilterObjLocation = e => {
    this.setState({
      filterObj: { ...this.state.filterObj, FilterObjLocation: e.target.value },
      isFilter: e.target.value != null && e.target.value != ""
    });
  };

  handleFilterObjTripDate = (start, end) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        FilterObjStartDate: start,
        FilterObjEndDate: end
      },
      isFilter: start != null && start != ""
    });
  };

  render() {
    const { total, loading } = this.props.deliverytripState;
    const columns = this.columns.map(col => col);

    return (
      // <AuthorizeComponent matching_name="trip">
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
        </Row>
        <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
          <Col span={18}>
            <Link to="/deliverytrip/create/">
              <Button type="primary" icon="plus" size="small">
                {" "}
                <LangContext.Consumer>
                  {i18n => i18n.a.add}
                </LangContext.Consumer>
              </Button>
            </Link>
          </Col>
          <Col span={4} />
          <Col span={2}>
            <Badge dot={this.state.isFilter} align="right">
              <Button
                icon="filter"
                size="small"
                align="right"
                onClick={this.showFilterDrawer}
              >
                <span></span>
                <LangContext.Consumer>
                  {i18n => i18n.f.filter}
                </LangContext.Consumer>
              </Button>
            </Badge>

            <IndexFilterDrawer
              onClose={this.onFilterDrawerClose}
              visible={this.state.filterVisible}
              onFilterButtonClick={this.onFilterButtonClick}
              handleFilterObjTripCode={this.handleFilterObjTripCode}
              handleFilterObjInvoice={this.handleFilterObjInvoice}
              handleFilterObjDriver={this.handleFilterObjDriver}
              handleFilterObjVehicleCode={this.handleFilterObjVehicleCode}
              handleFilterObjPlateNo={this.handleFilterObjPlateNo}
              handleFilterObjLocation={this.handleFilterObjLocation}
              handleFilterObjTripDate={
                this.handleFilterObjTripDate
              }
            />
          </Col>
        </Row>

        <Row>
          <Spin spinning={loading}>
            <Table
              bordered
              rowKey="trip_id"
              columns={columns}
              dataSource={this.props.deliverytripState.lists}
              size="small"
              pagination={false}
              onChange={this.handleTableChange}
            />
          </Spin>
        </Row>
        <Row type="flex" justify="end" style={{ padding: "15px" }}>
          <Col pan={24} align="right">
            <Pagination
              defaultPageSize={this.state.pageSize}
              size="small"
              current={this.state.currentPage}
              total={total}
              onChange={this.handlePageChange}
            />
          </Col>
        </Row>
      </GeneralStyledContent>
      // </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ deliverytripState, auth }) => ({
  deliverytripState,
  auth
});

const mapDispatchToProps = {
  loadTrip: actions.loadTrip.request,
  loadVehicle: actions.loadVehicle.request,
  clearDataResponse: actions.clearDataResponse
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);

