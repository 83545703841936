import { createTypes, createAction } from "lib/action";

const LOAD_TRUCKINSPECTIONMAINTENANCE = createTypes("truckinspectionmaintenance", "load");
const LOAD_COMPANY_TRUCKINSPECTIONMAINTENANCE = createTypes(
  "company_truckinspectionmaintenance",
  "load"
);
const LOAD_PLANT_TRUCKINSPECTIONMAINTENANCE = createTypes(
  "plant_truckinspectionmaintenance",
  "load"
);
const LOAD_TRAINER_TRUCKINSPECTIONMAINTENANCE = createTypes(
  "trainer_truckinspectionmaintenance",
  "load"
);
//const LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO = createTypes("vehicletype_alarmalertbyplateno", "load");

const loadAlarmAlertByPlateno = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKINSPECTIONMAINTENANCE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRUCKINSPECTIONMAINTENANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCKINSPECTIONMAINTENANCE.FAILURE)
};

const loadVehicle = {
  request: (loading_vehicle) =>
    createAction(LOAD_COMPANY_TRUCKINSPECTIONMAINTENANCE.REQUEST, {
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_COMPANY_TRUCKINSPECTIONMAINTENANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_COMPANY_TRUCKINSPECTIONMAINTENANCE.FAILURE)
};

const loadPlant = {
  request: (loading_plant) =>
    createAction(LOAD_PLANT_TRUCKINSPECTIONMAINTENANCE.REQUEST, {
      loading_plant
    }),
  success: data =>
    createAction(LOAD_PLANT_TRUCKINSPECTIONMAINTENANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_PLANT_TRUCKINSPECTIONMAINTENANCE.FAILURE)
};

const loadTrainer = {
  request: (loading_trainer) =>
    createAction(LOAD_TRAINER_TRUCKINSPECTIONMAINTENANCE.REQUEST, {
      loading_trainer
    }),
  success: data =>
    createAction(LOAD_TRAINER_TRUCKINSPECTIONMAINTENANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRAINER_TRUCKINSPECTIONMAINTENANCE.FAILURE)
};



export {
  loadAlarmAlertByPlateno,
  LOAD_TRUCKINSPECTIONMAINTENANCE,
  loadVehicle,
  LOAD_COMPANY_TRUCKINSPECTIONMAINTENANCE,
  loadPlant,
  LOAD_PLANT_TRUCKINSPECTIONMAINTENANCE,
  loadTrainer,
  LOAD_TRAINER_TRUCKINSPECTIONMAINTENANCE
  //loadVehicleType,
  //LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO
};
