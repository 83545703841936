import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";
import moment from "moment";

function* loadContactmanagement(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );


  const {
    data: { page, pageSize, orderBy, orderType, filterObj }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/contactmanagement`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        company_id: COMPANY_ID,
        vehicle_visibility,

      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadContactmanagement.success(res.data));
  } catch (err) {
    yield put(actions.loadContactmanagement.failure());
  }
}


function* createContactmanagement(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const {
    data: { list_vehicles, emails }
  } = action.payload;


  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/contactmanagement/create`,
      {
        list_vehicles,
        emails,
        company_id: COMPANY_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.createContactmanagement.success(res.data));
  } catch (err) {
    yield put(actions.createContactmanagement.failure());
  }
}




export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_CONTACTMANAGEMENT.REQUEST,
      loadContactmanagement
    ),
    takeEvery(actions.CREATE_CONTACTMANAGEMENT.REQUEST,
      createContactmanagement)

  ]);
}

export { loadContactmanagement, createContactmanagement };
