import { createTypes, createAction } from "lib/action";

const BASE_ACTION = "app/ui";
const SET_FLASH_MESSAGE = `${BASE_ACTION}/flash/set`;
const CLEAR_FLASH_MESSAGE = `${BASE_ACTION}/flash/clear`;
const INCREASE_NOTIFICATION_COUNT = `INCREASE_NOTIFICATION_COUNT`;
const LOAD_NOTIFICATION_COUNT = createTypes("notification_count", "load");
const DECREASE_NOTIFICATION_COUNT = `UPDATE_NOTIFICATION_COUNT`;
const UPDATE_DRAWING_TABLE_AT = `UPDATE_DRAWING_TABLE_AT`;
const REMOVE_UI_STATE = `REMOVE_UI_STATE`;

function removeUIState() {
  return {
    type: REMOVE_UI_STATE
  };
}
function newNotificationArrive(topic, message, packet) {
  // let message = new TextDecoder("utf-8").decode(message);
  //console.log(topic, message, packet);
  return {
    type: INCREASE_NOTIFICATION_COUNT,
    payload: { topic, message }
  };
}

function increaseNotificationcount(topic, message) {
  return {
    type: INCREASE_NOTIFICATION_COUNT,
    payload: { topic, message }
  };
}

function newDrawingTableArrive(topic, message, packet) {
  return {
    type: UPDATE_DRAWING_TABLE_AT,
    payload: { topic, message }
  };
}

function updateDrawingTableAt(topic) {
  return {
    type: UPDATE_DRAWING_TABLE_AT,
    payload: { topic }
  };
}

function setFlashMessage(type, message) {
  return {
    type: SET_FLASH_MESSAGE,
    payload: { type, message }
  };
}

function clearFlashMessage() {
  return {
    type: CLEAR_FLASH_MESSAGE
  };
}

function decreaseNotificationcount() {
  return {
    type: DECREASE_NOTIFICATION_COUNT
  };
}

const loadNotificationCount = {
  request: (apiName, val, seen, offset, limit) =>
    createAction(LOAD_NOTIFICATION_COUNT.REQUEST, {
      apiName,
      val,
      seen,
      offset,
      limit
    }),
  success: data => createAction(LOAD_NOTIFICATION_COUNT.SUCCESS, { data }),
  failure: () => createAction(LOAD_NOTIFICATION_COUNT.FAILURE)
};

export {
  SET_FLASH_MESSAGE,
  CLEAR_FLASH_MESSAGE,
  LOAD_NOTIFICATION_COUNT,
  setFlashMessage,
  clearFlashMessage,
  loadNotificationCount,
  decreaseNotificationcount,
  DECREASE_NOTIFICATION_COUNT,
  increaseNotificationcount,
  INCREASE_NOTIFICATION_COUNT,
  newNotificationArrive,
  newDrawingTableArrive,
  updateDrawingTableAt,
  UPDATE_DRAWING_TABLE_AT,
  removeUIState,
  REMOVE_UI_STATE
};
