import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadPartner(action) {
    const {
        data: {
            page,
            pageSize,
            companyID,
            orderBy,
            orderType,
            accessToken,
            filterObj
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/getpartner`,
            {
                page,
                pageSize,
                companyID,
                orderBy,
                orderType,
                filterObj
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.loadPartner.success(res.data, page));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadPartner.failure());
    }
}

function* loadAutocomplete(action) {
    const { url, companyID, accessToken, queryString } = action.payload;
    try {
      if (queryString == "" || queryString.length <= 1) {
        yield put(actions.loadAutocomplete.success([]));
      } else {
        const res = yield call(
          axios.get,
          `${process.env.REACT_APP_API_ENDPOINT}/${url}/${companyID}/${queryString}`,
  
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
  
        yield put(actions.loadAutocomplete.success(res.data));
      }
    } catch (err) {
  
      console.log(err.message);
      yield put(actions.loadAutocomplete.failure());
    }
}

function* createPartner(action){
    const {
        data : {
            company_id, partner_id, accessToken
        }
    } = action.payload;
    console.log(partner_id)
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/addpartner`,
            {
                company_id, partner_id
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.createPartner.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.createPartner.failure());
    }
}


export default function* watchPartner() {
    yield all([
        takeEvery(actions.LOAD_PARTNER.REQUEST, loadPartner),
        takeEvery(actions.LOAD_AUTOCOMPLETE.REQUEST, loadAutocomplete),
        takeEvery(actions.CREATE_PARTNER.REQUEST, createPartner),
    ]);
}
export {
    loadPartner,
    loadAutocomplete,
    createPartner
}