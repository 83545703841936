import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID
} from "./../../../constants/local_storage";
import * as actions from "./actions";

function* loadDatavisibilitySelectedVehicleGroup(action) {
  const {
    data: { id }
  } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/datavisibility/getselectedvehiclegroup/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadDatavisibilitySelectedVehicleGroup.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDatavisibilitySelectedVehicleGroup.failure());
  }
}

function* loadDatavisibilitySelectedVehicle(action) {
  const {
    data: { id }
  } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/datavisibility/getselectedvehicle/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadDatavisibilitySelectedVehicle.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDatavisibilitySelectedVehicle.failure());
  }
}

function* loadDatavisibilitySelectedLocationGroup(action) {
  const {
    data: { id }
  } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/datavisibility/getselectedlocationgroup/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(
      actions.loadDatavisibilitySelectedLocationGroup.success(res.data)
    );
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDatavisibilitySelectedLocationGroup.failure());
  }
}

function* loadDatavisibilitySelectedLocation(action) {
  const {
    data: { id }
  } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/datavisibility/getselectedlocation/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadDatavisibilitySelectedLocation.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDatavisibilitySelectedLocation.failure());
  }
}

function* loadDatavisibilitySelectedUser(action) {
  const {
    data: { id }
  } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/datavisibility/getselecteduser/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadDatavisibilitySelectedUser.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDatavisibilitySelectedUser.failure());
  }
}

export default function* watchDatavisibilityMasterDataState() {
  yield all([
    takeEvery(
      actions.LOAD_DATAVISIBILITY_SELECTED_VEHICLEGROUP.REQUEST,
      loadDatavisibilitySelectedVehicleGroup
    ),
    takeEvery(
      actions.LOAD_DATAVISIBILITY_SELECTED_VEHICLE.REQUEST,
      loadDatavisibilitySelectedVehicle
    ),
    takeEvery(
      actions.LOAD_DATAVISIBILITY_SELECTED_LOCATIONGROUP.REQUEST,
      loadDatavisibilitySelectedLocationGroup
    ),
    takeEvery(
      actions.LOAD_DATAVISIBILITY_SELECTED_LOCATION.REQUEST,
      loadDatavisibilitySelectedLocation
    ),
    takeEvery(
      actions.LOAD_DATAVISIBILITY_SELECTED_USER.REQUEST,
      loadDatavisibilitySelectedUser
    )
  ]);
}

export {
  loadDatavisibilitySelectedVehicleGroup,
  loadDatavisibilitySelectedVehicle,
  loadDatavisibilitySelectedLocationGroup,
  loadDatavisibilitySelectedLocation,
  loadDatavisibilitySelectedUser
};
