import React, { Component } from "react";
import { Menu } from "antd";
import { autoGenKey } from "../../../lib/helper";

const checkMenuVisibility = (list, permissions) => {
  if (
    typeof permissions == "undefined" ||
    typeof permissions == "string" ||
    permissions == []
  )
    return true;

  let hideMenu = true;
  for (let i = 0; i < list.length; i++) {
    let menu = list[i];

    let canAccess = permissions.find(ele => {
      return ele.matching_name == menu && ele.can_view == 1;
    });

    if (typeof canAccess != "undefined") {
      hideMenu = false;
    }
  }

  return hideMenu;
};

export class AutorizeMenu extends Component {
  render() {
    let { permissions, children } = this.props;
    let haveChild = false;
    let isSubmenu = false;

    if (typeof children.length == "number") {
      children.forEach(ele => {
        if (typeof ele.props.children.length == "number") haveChild = true;
      });
    } else {
      if (typeof children.props.children.length == "number") haveChild = true;
      if (children.type.displayName == "SubMenu") isSubmenu = true;
      children = [children];
    }

    if (!haveChild && !isSubmenu) {
      return (
        <Menu theme="dark" mode="inline">
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            style={{
              display:
                checkMenuVisibility(
                  children.map(ele => ele.props.matching_name),
                  permissions
                ) && "none"
            }}
            key={autoGenKey(`sub-menu`)}
            title={this.props.title}
          >
            {children.filter(ele => {
              let menu = ele.props.matching_name;
              let canAccess = permissions.find(ele => {
                return ele.matching_name == menu && ele.can_view == 1;
              });

              return canAccess;
            })}
          </Menu.SubMenu>
        </Menu>
      );
    } else {
      let resultArr = [];

      children.forEach(ele => {
        if (typeof ele.props.children.length == "number") {
          let result = ele.props.children.map(ele1 => ele1.props.matching_name);
          result.map(ele => resultArr.push(ele));
        } else {
          resultArr.push(ele.props.children.props.matching_name);
        }
      });

      let resultChildren = children.filter(ele => {
        let subChildren = ele.props.children;
        if (typeof subChildren.length != "number") subChildren = [subChildren];
        let res = false;

        subChildren.forEach(ele1 => {
          let canAccess = false;

          permissions.forEach(ele2 => {
            if (
              ele2.matching_name == ele1.props.matching_name &&
              ele2.can_view == 1
            ) {
              canAccess = true;
              return;
            }
          });

          if (canAccess) {
            res = true;
            return;
          }
        });

        return res;
      });

      resultChildren = resultChildren.map(ele => {
        let subChildren = ele.props.children;
        if (typeof subChildren.length != "number") subChildren = [subChildren];

        subChildren = subChildren.filter(ele1 => {
          let find = permissions.find(
            ele2 =>
              ele2.matching_name == ele1.props.matching_name &&
              ele2.can_view == 1
          );

          if (typeof find != "undefined") {
            return true;
          }
          return false;
        });

        return { ...ele, props: { ...ele.props, children: subChildren } };
      });

      return (
        <Menu theme="dark" mode="inline">
          <Menu.SubMenu
            style={{
              display: checkMenuVisibility(resultArr, permissions) && "none"
            }}
            key={autoGenKey(`sub-menu`)}
            title={this.props.title}
          >
            {resultChildren}
          </Menu.SubMenu>
        </Menu>
      );
    }
  }
}

export default { AutorizeMenu };
