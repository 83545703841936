import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

const asyncLocalStorage = {
  setItem: function(key, value) {
    return Promise.resolve().then(function() {
      localStorage.setItem(key, value);
    });
  },
  getItem: function(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key);
    });
  }
};

function* checkLogin(action) {
  const {
    credentials: { username, password }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/auth/checkuser`,
      {
        username,
        password
      }
    );
    let {
      data: {
        token,
        permissions,
        vehicle_list,
        location_list,
        vehicle_group_list
      }
    } = res;
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let deToken = JSON.parse(window.atob(base64));

    yield call(() =>
      actions.storeCredentials(
        token,
        deToken,
        permissions,
        vehicle_list,
        location_list,
        vehicle_group_list
      )
    );
    yield put(
      actions.checkLogin.success(
        token,
        deToken,
        permissions,
        vehicle_list,
        location_list,
        vehicle_group_list
      )
    );
  } catch (err) {
    if (err.message.indexOf("400") !== -1)
      yield put(
        actions.checkLogin.failure("error", "Invalid username or password!")
      );
    else if (err.message.indexOf("401") !== -1)
      yield put(actions.checkLogin.failure("error", "Account expired!"));
    else if (err.message.indexOf("503") !== -1)
      yield put(actions.checkLogin.failure("error", "Service Unavailable!"));
    else if (err.message.indexOf("502") !== -1)
      yield put(actions.checkLogin.failure("error", "Bad Gateway!"));
    else if (err.message.indexOf("504") !== -1)
      yield put(actions.checkLogin.failure("error", "Gateway Timeout!"));
    else
      yield put(actions.checkLogin.failure("error", "Something went wrong!"));
  }
}

export default function* watchAuthState() {
  yield all([takeEvery(actions.CHECK_LOGIN.REQUEST, checkLogin)]);
}

export { checkLogin };
