import {
  LOAD_DASHCAMERA,
  REMOVE_LISTS,
  LOAD_EMERGENCYCASE,
  LOAD_EARNINGWIDGET,
  LOAD_ACCEARNING,
  LOAD_BOOKINGWIDGET,
  LOAD_ACCBOOKING,
  LOAD_TOPBOOKINGDATA,
  LOAD_TRACKING,
  LOAD_VEHICLE_TYPE_MASTER_DATA_FILTER,
  LOAD_VEHICLE_GROUP_MASTER_DATA_FILTER,
  LOAD_VEHICLE_MASTER_DATA_FILTER,
  LOAD_ALERT_ALARM,
  LOAD_HEAT_MAP,
  LOAD_NEXT_EVENT_DETAIL
} from "./actions";

const initialState = {
  loadedDateTime: null,
  lists: [],
  total: 1,
  actualTotal: 0,
  loading: false,
  trackingList: [],
  trackingLoading: false,
  alertAlarmList: [],
  alertAlarmListWidget: {
    speedOverNormalRoad: 0,
    speedOverCityRoad: 0,
    driveOver4Hour: 0,
    driveOver10Hour: 0,
    entryRestrictedArea: 0,
    speedOverCrossRoad: 0
  },
  previousAlertAlarmList: [],
  heatMapList: [],
  heatMapListForPaginate: [],
  emergencycase: 0,
  earningWidget: { total: 0, receive: 0, diff: 0, job_amount: 0 },
  earningInfo: { receipt: [], receive: [], earningType: [], topDriver: [] },
  bookingWidget: {
    finish: 0,
    cancel: 0,
    on_process: 0,
    job_amount: 0
  },
  bookingInfo: {
    booking: [],
    bookingType: []
  },
  topBookingData: {
    top_customer: [],
    top_destination: []
  },
  vehicleTypeMasterDataTreeFilter: [],
  vehicleGroupMasterDataTreeFilter: [],
  vehicleMasterDataTreeFilter: [],
  masterDataTreeFilterLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState;
    case LOAD_DASHCAMERA.REQUEST:
      return {
        ...state,
        loading: typeof action.payload.data.intervalLoading === "undefined"
      };
    case LOAD_DASHCAMERA.SUCCESS:
      return {
        ...state,
        lists: action.payload.lists.markers,
        actualTotal: action.payload.lists.total,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        loadedDateTime: new Date().getTime()
      };
    case LOAD_EMERGENCYCASE.SUCCESS:
      let {
        payload: { emergencycase }
      } = action;
      return {
        ...state,
        emergencycase: emergencycase
      };
    case LOAD_EARNINGWIDGET.SUCCESS:
      let { booking_amount, receipt_info } = action.payload.data;

      return {
        ...state,
        earningWidget: {
          total: receipt_info.total,
          receive: receipt_info.receive,
          diff: receipt_info.diff,
          job_amount: booking_amount.job_amount
        },
        loading: false
      };
    case LOAD_EARNINGWIDGET.REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_ACCEARNING.SUCCESS:
      let {
        driver,
        receipt,
        receive,
        earningTypeFlatRate,
        earningTypeMeter
      } = action.payload.data;

      let earningType = earningTypeMeter.map((value, index) => ({
        name: value.name.replace(/_/g, "/"),
        meter: parseInt(value.value),
        flatRate: parseInt(earningTypeFlatRate[index].value)
      }));

      return {
        ...state,
        earningInfo: {
          topDriver: driver,
          receipt: receipt,
          receive: receive,
          earningType: earningType
        },
        loading: false
      };

    case LOAD_BOOKINGWIDGET.SUCCESS:
      let { booking_info } = action.payload.data;

      return {
        ...state,
        bookingWidget: {
          finish: booking_info.finish,
          cancel: booking_info.cancel,
          on_process: booking_info.on_process,
          job_amount: booking_info.job_amount
        },
        loading: false
      };
    case LOAD_ACCBOOKING.REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_ACCBOOKING.SUCCESS:
      let {
        booking,
        bookingTypeMeter,
        bookingTypeFlatRate
      } = action.payload.data;

      let bookingType = bookingTypeMeter.map((value, index) => ({
        name: value.name.replace(/_/g, "/"),
        meter: typeof value.value !== "undefined" ? parseInt(value.value) : 0,
        flatRate:
          typeof bookingTypeFlatRate[index].value !== "undefined"
            ? parseInt(bookingTypeFlatRate[index].value)
            : 0
      }));

      return {
        ...state,
        bookingInfo: {
          booking: booking,
          bookingType: bookingType
        },
        loading: false
      };

    case LOAD_TOPBOOKINGDATA.SUCCESS:
      let { top_customer, top_destination } = action.payload.data;

      return {
        ...state,
        topBookingData: {
          top_customer: top_customer,
          top_destination: top_destination
        },
        loading: false
      };

    case LOAD_TRACKING.REQUEST:
      return {
        ...state,
        trackingLoading:
          typeof action.payload.data.intervalLoading === "undefined"
      };

    case LOAD_TRACKING.SUCCESS:
      return {
        ...state,
        trackingList: action.payload.data.markers,
        trackingLoading: false,
        loadedDateTime: new Date().getTime()
      };

    case LOAD_VEHICLE_TYPE_MASTER_DATA_FILTER.SUCCESS:
      return {
        ...state,
        vehicleTypeMasterDataTreeFilter: action.payload.data,
        masterDataTreeFilterLoading: false
      };

    case LOAD_VEHICLE_GROUP_MASTER_DATA_FILTER.SUCCESS:
      return {
        ...state,
        vehicleGroupMasterDataTreeFilter: action.payload.data,
        masterDataTreeFilterLoading: false
      };

    case LOAD_VEHICLE_MASTER_DATA_FILTER.SUCCESS:
      return {
        ...state,
        vehicleMasterDataTreeFilter: action.payload.data,
        masterDataTreeFilterLoading: false
      };

    case LOAD_ALERT_ALARM.REQUEST:
      return {
        ...state,
        loading: typeof action.payload.data.intervalLoading === "undefined"
      };

    case LOAD_ALERT_ALARM.SUCCESS:
      let lists = [];
      let previousDataValue = [];
      let haveNewAlertAlarm = false;
      let playSound = false;
      let speedOverNormalRoad = 0;
      let speedOverCityRoad = 0;
      let driveOver4Hour = 0;
      let driveOver10Hour = 0;
      let entryRestrictedArea = 0;
      let speedOverCrossRoad = 0;
      let { isSearch } = action.payload;
      if (isSearch) previousDataValue = action.payload.data.markers;
      else previousDataValue = state.alertAlarmList;

      if (action.payload.data.markers.length != 0)
        action.payload.data.markers.map((value, index) => {
          //let info = JSON.parse(value.info.driver_license_info);

          if (previousDataValue.length != 0) {
            let previousValue = previousDataValue.filter(
              ele => ele.vehicle_id == value.vehicle_id
            );

            if (previousValue.length > 0) {
              if (
                previousValue[0].event_type_1 == null &&
                value.event_type_1 != null
              ) {
                haveNewAlertAlarm = true;
              }
              if (
                previousValue[0]["event_type_2"] == null &&
                value.event_type_2 != null
              ) {
                haveNewAlertAlarm = true;
              }
              if (
                previousValue[0]["event_type_3"] == null &&
                value.event_type_3 != null
              ) {
                haveNewAlertAlarm = true;
              }
              if (
                previousValue[0]["event_type_4"] == null &&
                value.event_type_4 != null
              ) {
                haveNewAlertAlarm = true;
              }
              if (
                previousValue[0]["event_type_5"] == null &&
                value.event_type_5 != null
              ) {
                haveNewAlertAlarm = true;
              }
              if (
                previousValue[0]["event_type_6"] == null &&
                value.event_type_6 != null
              ) {
                haveNewAlertAlarm = true;
              }
            } else {
              haveNewAlertAlarm = false;
            }
          }

          if (value.event_type_1 != null) {
            speedOverNormalRoad++;
          }
          if (value.event_type_2 != null) {
            speedOverCityRoad++;
          }
          if (value.event_type_3 != null) {
            driveOver4Hour++;
          }
          if (value.event_type_4 != null) {
            driveOver10Hour++;
          }
          if (value.event_type_5 != null) {
            entryRestrictedArea++;
          }
          if (value.event_type_6 != null) {
            speedOverCrossRoad++;
          }

          const listValue = {
            key: index + 1,
            plate_no: value.plate_no,
            vehicle_id: value.vehicle_id,
            driver_phone: value.driver_phone,
            device_phone: value.device_phone,
            vehicle_group: value.info.vehicle_group,
            vehicle_group: value.info.vehicle_group,
            // title: info.title,
            // fname: info.fname,
            // lname: info.lname,
            event_type_1: value.event_type_1,
            event_type_2: value.event_type_2,
            event_type_3: value.event_type_3,
            event_type_4: value.event_type_4,
            event_type_5: value.event_type_5,
            event_type_6: value.event_type_6,
            haveNewAlertAlarm: !isSearch && haveNewAlertAlarm
          };
          if (haveNewAlertAlarm) lists.unshift(listValue);
          else lists.push(listValue);
          if (haveNewAlertAlarm) playSound = true;

          haveNewAlertAlarm = false;
        });
      else lists = [];

      if (!isSearch && playSound) {
        document.getElementById("audio-alert").play();
      }

      return {
        ...state,
        previousAlertAlarmList: state.alertAlarmList,
        alertAlarmList: lists,
        alertAlarmListWidget: {
          speedOverNormalRoad,
          speedOverCityRoad,
          driveOver4Hour,
          driveOver10Hour,
          entryRestrictedArea,
          speedOverCrossRoad
        },
        loading: false,
        loadedDateTime: new Date().getTime()
      };

    case LOAD_HEAT_MAP.REQUEST:
      return {
        ...state,
        heatMapListForPaginate: [],
        loading: typeof action.payload.data.intervalLoading === "undefined"
      };

    case LOAD_HEAT_MAP.SUCCESS:
      return {
        ...state,
        heatMapList: action.payload.data.markers,
        heatMapListForPaginate:
          action.payload.data.markers.length > 50
            ? action.payload.data.markers.splice(0, 50)
            : action.payload.data.markers,
        loading: false,
        loadedDateTime: new Date().getTime()
      };
    case LOAD_NEXT_EVENT_DETAIL:
      return {
        ...state,
        heatMapListForPaginate: action.payload
      };
    default:
      return state;
  }
};
