import {
  CREATE_POLICY,
  UPDATE_POLICY,
  LOAD_POLICY,
  REMOVE_LISTS,
  LOAD_POLICY_DETAIL
} from "./actions";

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  detailLoading: false,
  draw: -1,
  selectedPolicy: {
    policy_id: null,
    policy_code: "",
    vehicle_id: "",
    vehicle_id_text: "",
    driver_id: "",
    driver_id_text: "",
    plant_id: "",
    plant_id_text: "",
    site_id: "",
    site_id_text: "",
    start_deliver_at: null,
    policy_type: "3",
    load_start_at: null,
    load_amount: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState;
    case LOAD_POLICY.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading
      };
    case LOAD_POLICY.SUCCESS:
      const { page } = action.payload;
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1)
      };
    case LOAD_POLICY_DETAIL.REQUEST:
      return {
        ...state,
        detailLoading: true
      };
    case LOAD_POLICY_DETAIL.SUCCESS:
      return {
        ...state,
        selectedPolicy: action.payload.policy.data,
        detailLoading: false
      };
    case CREATE_POLICY.SUCCESS:
      const { res } = action.payload;
      console.log(res);
      return {
        ...state,
        selectedPolicy: { ...res.values.data },
        policy_id: res.policy_id,
        detailLoading: false
      };
    case UPDATE_POLICY.REQUEST:
      return {
        ...state,
        detailLoading: true
      };
    case UPDATE_POLICY.SUCCESS:
      return {
        ...state,
        detailLoading: false
      };
    default:
      return state;
  }
};
