import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
//import { push } from "connected-react-router";

import * as actions from "./actions";
import moment from "moment";

function* loadAlarmAlertByPlateno(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const USER_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "id"
  );

  const {
    data: { list_vehicle_id, year }
  } = action.payload;
  //const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD");
  //const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD");
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/summaryscorebyvendoragg`,
      {
        list_vehicle_id,
        company_id: COMPANY_ID,
        user_id: USER_ID,
        year,
        
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadAlarmAlertByPlateno.success(res.data));
    window.location = res.data.data;
  } catch (err) {
    yield put(actions.loadAlarmAlertByPlateno.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  //const { vehicletypeID } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getcompanytruckinspection`,
      {
        company_id: COMPANY_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_SUMMARYSCOREBYVENDORAGG.REQUEST,
      loadAlarmAlertByPlateno
    ),
    takeEvery(actions.LOAD_COMPANY_SUMMARYSCOREBYVENDORAGG.REQUEST, loadVehicle),
    //takeEvery(actions.LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO.REQUEST, loadVehicleType)
  ]);
}

export { loadAlarmAlertByPlateno, loadVehicle };
