import { combineReducers } from "redux";

// etc
import auth from "modules/auth/reducer";
import ui from "modules/ui/reducer";

// delivery management
import tripState from "modules/delivery-management/trip/reducer";
import deliverytripState from "modules/delivery-management/delivery-trip/reducer";

// setting
import policyState from "modules/setting/policy/reducer";

// master data
import customerMasterDataState from "modules/master-data/customer/reducer";
import usergroupMasterDataState from "modules/master-data/usergroup/reducer";
import pullVehicleSink from "modules/master-data/pullvehiclesink/reducer";
import permissionMasterDataState from "modules/master-data/permission/reducer";
import datavisibilityMasterDataState from "modules/master-data/datavisibility/reducer";
import placeMasterData from "modules/master-data/facility/reducer";
import userMaster from "modules/master-data/user/reducer";
import dataVehicleGroup from "modules/master-data/vehicleGroup/reducer";
import routeMasterData from "modules/master-data/route/reducer";
import vehicle from "modules/master-data/vehicle/reducer";
import vehicleSharingMasterDataState from "modules/master-data/vehicle-sharing/reducer";
import verification from "modules/master-data/vehicle/reducer";
import deviceUser from "modules/master-data/deviceuser/reducer";
// import permissionMasterDataState from "modules/master-data/permission/reducer";
import locationgroup from "modules/master-data/locationgroup/reducer";
import nearbyLocation from "modules/master-data/nearbylocation/reducer";
import zone from "modules/master-data/zone/reducer";
import refuel from "modules/master-data/refuel/reducer";
import partner from "modules/master-data/partner/reducer";
import locationSharing from "modules/master-data/locations-sharing/reducer";
import parttype from "modules/master-data/parttype/reducer";
import partgroup from "modules/master-data/partgroup/reducer";
import managementMaintenance from "modules/master-data/maintenance/reducer";

// operation job
import deliveryitemMasterDataState from "modules/operate-job/delivery-item/reducer";
import deliveryitemDeleteHistoryMasterDataState from "modules/operate-job/delivery-item-delete-history/reducer";
import gpsCertificationRequestState from "modules/operate-job/gps-certification/reducer";

// booking
import bookingPoolState from "modules/booking-pool/reducer";

//help
import servicerepairdevice from "modules/help/servicerepairdevice/reducer";

// dashboard
import monitoringState from "modules/monitoring/reducer";
import videoMonitoring from "modules/monitoring/video/reducer";
import cashierState from "modules/cashier/reducer";
import mdvrdownloadfile from "modules/monitoring/mdvrdownloadfile/reducer";

//management
import downloadcertificate from "modules/management/downloadcertificate/reducer";
import contactmanagement from "modules/management/contactmanagement/reducer";

// report
//import alarmAlertByPlateno from "modules/reports/alarmalertbyplateno/reducer";
import truckInspectionByVendor from "modules/reports/truckinspectionbyvendor/reducer";
import truckInspectionByTruck from "modules/reports/truckinspectionbytruck/reducer";
import summaryScoreByVendor from "modules/reports/summaryscorebyvendor/reducer";
import truckInspectionByVendorAgg from "modules/reports/truckinspectionbyvendoragg/reducer";
import summaryScoreByVendorAgg from "modules/reports/summaryscorebyvendoragg/reducer";
import truckInspectionMaintenance from "modules/reports/truckinspectionmaintenance/reducer";
import truckInspectionByMonth from "modules/reports/truckinspectionbymonth/reducer";
import alarmAlertByPlatenoOnyear from "modules/reports/alarmalertbyplatenoonyear/reducer";
import alarmAlertByDriver from "modules/reports/alarmalertbydriver/reducer";
import alarmAlertFrequencyByDriver from "modules/reports/alarmalertfrequencybydriver/reducer";
import alarmAlertByDurationtime from "modules/reports/alarmalertbydurationtime/reducer";
import drivingBehavior from "modules/reports/drivingbehavior/reducer";
import drivingLicensescanBehavior from "modules/reports/drivinglicensescanbehavior/reducer";
import separateVehicleStatus from "modules/reports/separatevehiclestatus/reducer";
import compareVehicleScco from "modules/reports/comparevehiclescco/reducer";
import alertDriverLicense from "modules/reports/alertdriverlicense/reducer";
import parkingAreaOutside from "modules/reports/parkingareaoutside/reducer";
//import truckusage from "modules/reports/MoveReport/Truckusage/reducer";
//import drivingdistance from "modules/reports/MoveReport/drivingdistance/reducer";
import parking from "modules/reports/parking/reducer";
import drivinglicensescanlog from "modules/reports/drivinglicensescanlog/reducer";
import speedoverlimitsummary from "modules/reports/speedoverlimitsummary/reducer";
import drivinglog from "modules/reports/drivinglog/reducer";
import event from "modules/reports/event/reducer";
import summarykpi from "modules/reports/summarykpi/reducer";
import loginhistory from "modules/reports/loginhistory/reducer";
import gpsstatus from "modules/reports/gpsstatus/reducer";
import trackingdata from "modules/reports/trackingdata/reducer";
import truckusage from "modules/reports/truckusage/reducer";
import drivingdistance from "modules/reports/drivingdistance/reducer";
import temperature from "modules/reports/temperature/reducer";
import maintenance from "modules/reports/maintenance/reducer";
import distfromlastmaintenance from "modules/reports/distfromlastmaintenance/reducer";
import truckcurrentlocation from "modules/reports/truckcurrentlocation/reducer";
import driverinformation from "modules/reports/driverinformation/reducer";
import numberofdaysworked from "modules/reports/numberofdaysworked/reducer";
import dozeoffrisk from "modules/reports/dozeoffrisk/reducer";
import delivery from "modules/reports/delivery/reducer";
import triptemperature from "modules/reports/triptemperature/reducer";
import tripsumbyveh from "modules/reports/tripsumbyveh/reducer";
import daily from "modules/reports/daily/reducer";
import velocitysummary from "modules/reports/velocitysummary/reducer";
import truckengineon from "modules/reports/truckengineon/reducer";
import truckengineoff from "modules/reports/truckengineoff/reducer";
import trucknotuse from "modules/reports/trucknotuse/reducer";
import drivinghour from "modules/reports/drivinghour/reducer";
import tripsummary from "modules/reports/tripsummary/reducer";
import drivingoverlimit from "modules/reports/drivingoverlimit/reducer";
import tripsumbylocation from "modules/reports/tripsumbylocation/reducer";
import insurance from "modules/reports/insurance/reducer";
import vehicleperformanceconclude from "modules/reports/vehicleperformanceconclude/reducer";
import reportrefuel from "modules/reports/refuel/reducer";
import riskbehaviour from "modules/reports/riskbehaviour/reducer";
import vehicleinarea from "modules/reports/vehicleinarea/reducer";
import maintenancedeviceconclude from "modules/reports/maintenancedeviceconclude/reducer";


export default combineReducers({
  auth,
  ui,
  bookingPoolState,
  monitoringState,
  cashierState,
  customerMasterDataState,
  //alarmAlertByPlateno,
  truckInspectionByVendor,
  truckInspectionByTruck,
  summaryScoreByVendor,
  truckInspectionByVendorAgg,
  summaryScoreByVendorAgg,
  truckInspectionMaintenance,
  truckInspectionByMonth,
  gpsCertificationRequestState,
  alarmAlertByDriver,
  alarmAlertByDurationtime,
  drivingBehavior,
  drivingLicensescanBehavior,
  alarmAlertByPlatenoOnyear,
  separateVehicleStatus,
  compareVehicleScco,
  alertDriverLicense,
  parkingAreaOutside,
  usergroupMasterDataState,
  deliveryitemMasterDataState,
  deliveryitemDeleteHistoryMasterDataState,
  pullVehicleSink,
  parking,
  summarykpi,
  datavisibilityMasterDataState,
  placeMasterData,
  userMaster,
  dataVehicleGroup,
  routeMasterData,
  vehicle,
  loginhistory,
  gpsstatus,
  trackingdata,
  vehicleSharingMasterDataState,
  videoMonitoring,
  drivingdistance,
  drivinglicensescanlog,
  speedoverlimitsummary,
  drivinglog,
  event,
  permissionMasterDataState,
  alarmAlertFrequencyByDriver,
  verification,
  deviceUser,
  locationgroup,
  nearbyLocation,
  zone,
  refuel,
  truckusage,
  temperature,
  maintenance,
  distfromlastmaintenance,
  truckcurrentlocation,
  driverinformation,
  numberofdaysworked,
  dozeoffrisk,
  delivery,
  triptemperature,
  tripsumbyveh,
  daily,
  velocitysummary,
  truckengineon,
  truckengineoff,
  trucknotuse,
  drivinghour,
  tripState,
  tripsummary,
  drivingoverlimit,
  tripsumbylocation,
  insurance,
  vehicleperformanceconclude,
  reportrefuel,
  riskbehaviour,
  partner,
  locationSharing,
  vehicleinarea,
  maintenancedeviceconclude,
  downloadcertificate,
  parttype,
  partgroup,
  policyState,
  managementMaintenance,
  // policyState
  contactmanagement,
  mdvrdownloadfile,
  servicerepairdevice,
  deliverytripState

});
