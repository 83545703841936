import { createTypes, createAction } from "lib/action";

const LOAD_SUMMARYSCOREBYVENDORAGG = createTypes("summaryscorebyvendoragg", "load");
const LOAD_COMPANY_SUMMARYSCOREBYVENDORAGG = createTypes(
  "company_summaryscorebyvendoragg",
  "load"
);
//const LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO = createTypes("vehicletype_alarmalertbyplateno", "load");

const loadAlarmAlertByPlateno = {
  request: (data, loading) =>
    createAction(LOAD_SUMMARYSCOREBYVENDORAGG.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SUMMARYSCOREBYVENDORAGG.SUCCESS, { data }),
  failure: () => createAction(LOAD_SUMMARYSCOREBYVENDORAGG.FAILURE)
};

const loadVehicle = {
  request: (loading_vehicle) =>
    createAction(LOAD_COMPANY_SUMMARYSCOREBYVENDORAGG.REQUEST, {
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_COMPANY_SUMMARYSCOREBYVENDORAGG.SUCCESS, { data }),
  failure: () => createAction(LOAD_COMPANY_SUMMARYSCOREBYVENDORAGG.FAILURE)
};



export {
  loadAlarmAlertByPlateno,
  LOAD_SUMMARYSCOREBYVENDORAGG,
  loadVehicle,
  LOAD_COMPANY_SUMMARYSCOREBYVENDORAGG
  //loadVehicleType,
  //LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO
};
