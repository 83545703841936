import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadPartGroup(action) {
    const {
        data: {
            page,
            pageSize,
            companyID,
            orderBy,
            orderType,
            accessToken,
            filterObj
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/partgroup/getpartgroup`,
            {
                page,
                pageSize,
                companyID,
                orderBy,
                orderType,
                filterObj
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.loadPartGroup.success(res.data, page));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadPartGroup.failure());
    }
}

function* loadPartGroupDetail(action) {
    const {
        data: {
            page,
            pageSize,
            companyID,
            id,
            orderBy,
            orderType,
            accessToken,
            filterObj
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/partgroup/getdetail`,
            {
                page,
                pageSize,
                companyID,
                id,
                orderBy,
                orderType,
                filterObj
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.loadPartGroupDetail.success(res.data, page));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadPartGroupDetail.failure());
    }
}


function* createPartGroup(action){
    const {
        data : {
            companyID, name, vehicle_type_id, type, first_rules, rules,
            accessToken,
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/partgroup/create`,
            {
                companyID, name, vehicle_type_id, type, first_rules, rules,
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.createPartGroup.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.createPartGroup.failure());
    }
}

export default function* watchPartGroup() {
    yield all([
        takeEvery(actions.CREATE_PARTGROUP.REQUEST, createPartGroup),
        takeEvery(actions.LOAD_PARTGROUP.REQUEST, loadPartGroup),
        takeEvery(actions.LOAD_PARTGROUP_DETAIL.REQUEST, loadPartGroupDetail),
    ]);
}
export {
    createPartGroup,
    loadPartGroupDetail,
    loadPartGroup
}
