import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Row, Col, Alert, Icon, Card, Spin } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";

import HomeDashboard from "modules/home-dashboard/index/components/HomeDashboard";
import {
  GeneralStyledContent,
  PureBackgroundStyledContent,
  StyledLogo,
  StyledDiv,
  StyledRow,
  StyledCol
} from "../../../styled/common-styled";
import { checkLogin } from "../actions";
import Form from "./Form";

class Login extends Component {
  render() {
    let alertMsg = "";
    if (typeof this.props.location !== "undefined") {
      if (
        typeof this.props.location.data != "undefined" &&
        typeof this.props.location.data.msg != ""
      ) {
        alertMsg = (
          <Alert
            message="เรียนผู้ใช้บริการ"
            description={this.props.location.data.msg}
            type="warning"
            showIcon
            closable
            style={{ margin: "0px 0px 15px 0px" }}
          />
        );
      }
    }

    return this.props.auth.done ? (
      <PureBackgroundStyledContent>
        {/* <Alert
          message="เรียนผู้ใช้บริการ"
          description="เนื่องจาก TERMINUS จะทำการปรับปรุงเซิฟเวอร์ฐานข้อมูล ในวันที่ 18 สิงหาคม เวลา 00:01 - 07:00 จึงทำให้ระบบจะใช้งานไม่ได้ในเวลาดังกล่าว ขออภัยในความไม่สะดวก"
          type="warning"
          showIcon
          closable
          style={{ margin: "0px 0px 15px 0px" }}
        /> */}
        {/* <Row>
          <Col span={10} offset={6} xs={24} sm={24} md={10} lg={10} xl={10}>
            <Card
              title={<LangContext.Consumer>{i18n => i18n.u.userProfile}</LangContext.Consumer>}
              style={{
                padding: "0px 0px 5px 10px"
              }}
            >
              <Avatar
                shape="square"
                size={64}
                icon="user"
                style={{ margin: "15px 0px 15px 0px" }}
              />
              <Row type="flex">
                <Col xs={24} sm={24} md={10} lg={10} xl={10} align="left">
                  <h4>
                    <LangContext.Consumer>
                      {i18n => i18n.u.userName}
                    </LangContext.Consumer>
                    :{" "}
                  </h4>
                </Col>
                <Col xs={24} sm={24} md={14} lg={14} xl={14} align="right">
                  <p>{this.props.auth.profile.username}</p>
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10} align="left">
                  <h4>
                    <LangContext.Consumer>
                      {i18n => i18n.f.firstName}
                    </LangContext.Consumer>
                    :{" "}
                  </h4>
                </Col>
                <Col xs={24} sm={24} md={14} lg={14} xl={14} align="right">
                  <p>{this.props.auth.profile.firstname}</p>
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10} align="left">
                  <h4>
                    <LangContext.Consumer>
                      {i18n => i18n.l.lastName}
                    </LangContext.Consumer>
                    :{" "}
                  </h4>
                </Col>
                <Col xs={24} sm={24} md={14} lg={14} xl={14} align="right">
                  <p>{this.props.auth.profile.lastname}</p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row> */}
        <HomeDashboard />
      </PureBackgroundStyledContent>
    ) : (
      <StyledDiv>
        <StyledRow>
          <StyledCol
            xs={{ span: 18, offset: 3 }}
            sm={{ span: 18, offset: 3 }}
            md={{ span: 9, offset: 8 }}
            lg={{ span: 7, offset: 8 }}
            xl={{ span: 7, offset: 8 }}
          >
            <StyledLogo span={24} src="/img/logo.png" />
            <Spin
              spinning={this.props.auth.loading}
              size="large"
              style={{ height: "100%" }}
            >
              <Form auth={this.props.auth} onSubmit={this.props.checkLogin} />{" "}
            </Spin>
          </StyledCol>
        </StyledRow>
      </StyledDiv>
    );
  }
}

export default connect(
  ({ token, profile, auth }) => ({ token, profile, auth }),
  {
    checkLogin: checkLogin.request
  }
)(Login);
