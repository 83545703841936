import {
  LOAD_SUMMARYSCOREBYVENDOR,
  LOAD_COMPANY_SUMMARYSCOREBYVENDOR
} from "./actions";

const initialState = {
  alarmalertbyplatenoUrl: "",
  vehicleLists: [],
  alarmalertbyplatenoLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SUMMARYSCOREBYVENDOR.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        alarmalertbyplatenoLoading: loading
      };
    case LOAD_SUMMARYSCOREBYVENDOR.SUCCESS:
      return {
        ...state,
        alarmalertbyplatenoUrl: action.payload.data.data,
        alarmalertbyplatenoLoading: false
      };
    case LOAD_COMPANY_SUMMARYSCOREBYVENDOR.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        alarmalertbyplatenoLoading: loading_vehicle
      };
    case LOAD_COMPANY_SUMMARYSCOREBYVENDOR.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        alarmalertbyplatenoLoading: false
        //  loading: false
      };
    default:
      return state;
  }
};
