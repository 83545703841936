const th = {
  a: {
    after5min: "ผ่านไป 5 นาที",
    after30min: "ผ่านไป 30 นาที",
    after1hour: "ผ่านไป 1 ชั่วโมง",
    after4hour: "ผ่านไป 4 ชั่วโมง",
    after8hour: "ผ่านไป 8 ชั่วโมง",
    after1day: "ผ่านไป 1 วัน",
    alertNoti: "แจ้งเตือน",
    actionRequired: "ผู้ใช้ต้องดำเนินการกับเคสที่เกิด",
    allowWorkingTime: "ช่วงเวลาที่อนุญาติทำงาน",
    apply: "ปรับใช้",
    action: "ดำเนินการ",
    amount: "ครั้ง",
    all: "ทั้งหมด",
    addVehicleGroup: "เพิ่มกลุ่มพาหนะ",
    actualTransitDistance: "ระยะทางที่ขนส่งจริง",
    adjustTheTable: "ปรับตาราง",
    african: "แอฟริกัน",
    arab: "อาหรับ",
    alarm: "Alarm",
    alert: "Alert",
    agency: "Agency",
    assignTo: "ผู้รับมอบหมาย",
    account: "บัญชีผู้ใช้",
    address: "ที่อยู่",
    active: "Active",
    add: "เพิ่ม",
    administrator: "ผู้ดูแลระบบ",
    addInformation: "เพิ่มข้อมูล",
    alarmAndAlertReportClassifiedByYearlyCarRegistration:
      "รายงานแจ้ง Alarm และ Alert แยกตามทะเบียน รายปี",
    alarmAndAlertReportClassifiedByDailyCarRegistration:
      "รายงานแจ้ง Alarm และ Alert แยกตามทะเบียน รายวัน",
    avgEarningJob: "Avg. Earning/Job",
    ACCUMULATEEARNINGSTATISTICS: "ACCUMULATE EARNING STATISTICS",
    addARow: "เพิ่มเเถว",
    alertAlarm: "ระบบแจ้งเตือนการฝ่าฝืน",
    announcement: "ประกาศ",
    announcementBroadcast: "กระจายข่าว / เผยแพร่ข่าว ...",
    allowSharedPartnerEdit: "ผู้ว่าจ้างสามารถแก้ไข",
    addDrivinglicense: "Add Driving License",
    amountPlant: "จำนวนแพลนต์ใกล้เคียง",
    accessory: "อุปกรณ์เสริม",
    and: "เเละ",
    appointment_date: "วัน-เวลานัดหมาย",
    appointment_place: "สถานที่นัด",
    amphur: "เขต/อำเภอ"
  },

  b: {
    BOOM: "BOOM",
    behavior: "พฤติกรรม",
    BOOKINGSTATISTICS: "สถิติการจอง",
    booking: "งานจอง",
    baseTemplate: "Template พื้นฐาน",
    base: "พื้นฐาน",
    bookedDate: "Booked Date",
    busStopPoint: "Bus Stop Point",
    busStop1: "Bus Stop 1",
    busStop2: "Bus Stop 2",
    boxNumber: "เบอร์กล่อง",
    boxID: "รหัสกล่อง",
    bookingRemark: "Booking Remark",
    bookingDate: "วันที่จอง",
    button: "ปุ่ม",
    back: "กลับ",
    bookingPool: "งานจอง",
    bookingOverview: "ภาพรวมงานจอง",
    brand: "ยี่ห้อ",
    behavior_report_menu: "พฤติกรรม"
  },

  c: {
    continuousDrive: "ทำงานต่อเนื่องได้ไม่เกิน",
    continuousDriveInADay: "ทำงานต่อเนื่องในหนึ่งวันได้ไม่เกิน",
    criticalLevel: "ระดับความรุนแรง",
    category: "หมวด",
    concreteDeliveryDashboard: "สถานะการส่งคอนกรีต",
    collapse: "ย่อ",
    close: "ปิด",
    clear: "ล้าง",
    customer: "ลูกค้า",
    customerDetail: "รายละเอียดลูกค้า",
    chineseKoreanJapanese: "จีน/ กาหลี/ญี่ปุ่น",
    communityArea: "เขตชุมชน",
    crossroad: "ในทางเเยก",
    customerName: "ชื่อลูกค้า",
    createdAt: "สร้างเมื่อ",
    customerType: "ประเภทลูกค้า",
    criticality: "ภาวะวิกฤต",
    comment: "รายละเอียด",
    channel: "ช่องทาง",
    closed: "ปิด",
    confirmToDeleteThisItem: "ยืนยัน ต้องการลบรายการนี้",
    carService: "ศูนย์บริการ",
    confirmToAddThisItem: "ยืนยัน ต้องการเพิ่มรายการนี้",
    changeLanguage: "เปลี่ยนภาษา",
    company: "บริษัท",
    confirmPassword: "ยืนยันรหัสผ่าน",
    cancelled: "ยกเลิก",
    cancel: "ยกเลิก",
    connection: "เชื่อมต่อสัญญาณ",
    currentAddress: "ที่อยู่ปัจจุบัน",
    chauffeur: "พนักงานขับขี่",
    clearUp: "ล้างค่า",
    cancelReason: "เหตุผลการยกเลิก",
    changeProduct: "เปลี่ยนสินค้า",
    confirm: "ยืนยัน",
    configuration: "ปรับค่า",
    custom: "ปรับแต่ง",
    contractor: "ผู้รับจ้าง",
    currentVehicle: "พาหนะปัจจุบัน",
    courseName: "ชื่อหลักสูตร",
    Color: "สี",
    companyBilling: "บริษัทที่เรียกเก็บเงิน",
    chasisSerialNumber: "หมายเลขตัวถัง",
    createdBy: "สร้างโดย",
    CompareVehicleSccoReport: "รายงาน เปรียบเทียบพาหนะ SCCO",
    cycleOfDrumRotation: " จำนวนรอบการหมุนของโม่",
    contact_name: "ชื่อบุคคลติดต่อ",
    contact_telno: "เบอร์ติดต่อ",
    confirm_appointment_place: "อาจมีการเปลี่ยนแปลงจังหวัด และอำเภอ",
    created_by: "สร้างโดย",
    confirm_type: "ยืนยันการส่งโดย"
  },

  d: {
    dayTime: "ช่วงเวลากลางวัน",
    driving: "ขับพาหนะ",
    daily: "รายวัน",
    diff: "Diff",
    deliveryStatus: "สถานะการจัดส่ง",
    dailyCarStatusReport: "รายงาน แยกสถานะพาหนะ รายวัน",
    driveOverTheSpeedLimitInTheCrossroads: "ขับขี่ความเร็วเกินกำหนด ในทางแยก",
    dailyDrivingBehaviorReport: "รายงาน พฤติกรรมการขับขี่ รายวัน",
    DailyAlarmAndAlertReportByDriver:
      "รายงานแจ้ง Alarm และ Alert แยกตามพนักงานขับขี่ รายวัน",
    dateRange: "ช่วงวันที่",
    deletedAt: "ลบข้อมูลเมื่อ",
    deletedBy: "ลบข้อมูลโคย",
    deleteReason: "เหตุผลการลบ",
    draft: "draft",
    doYouWantToConfirmSend: "คุณต้องการยืนยันการส่งหรือไม่",
    deleteInformation: "ลบข้อมูล",
    delete: "ลบ",
    daYouWantToReActive: "คุณต้องการที่จะเปิดใช้งานอีกครั้งหรือไม่",
    draw: "วาด",
    data: "ข้อมูล",
    distance: "ระยะทาง",
    deliverPoint: "จุดส่งของ",
    doYouWantToCancel: "คุณต้องการยกเลิกหรือไม่",
    doYouWantToDeleteTheseItems: "คุณต้องการลบรายการเหล่านี้หรือไม่",
    doYouWantToAddTheseItems: "คุณต้องการเพิ่มรายการเหล่านี้หรือไม่",
    dateTime: "วัน/เวลา",
    description: "รายละเอียด",
    displayTime: "เวลาการเเสดงผล",
    dateOfSubmission: "วันที่ยื่นคำขอ",
    dateSubmit: "วันที่ส่งเอกสาร",
    deliveryAddress: "ที่อยู่สำหรับการจัดส่ง",
    detail: "รายละเอียด",
    driveTooFastInTheNormalRoute: "ขับขี่เร็วเกินกำหนด ในเส้นทางปกติ",
    driveTooFastInTheCommunity: "ขับเร็วเกินกำหนด ในเขตชุมชน",
    driveContinuouslyForMoreThan4Hours: "ขับต่อเนื่อง > 4 ชม.",
    driveContinuouslyForMoreThan10HoursDay: "ปฏิบัติงาน > 10 ชม./วัน",
    doNotParkBesideTheRoad: "ห้ามจอดไหล่ทาง",
    doNotSwipeCardsOrDamageCards: "ไม่รูดบัตรหรือบัตรเสียหายหนัก",
    drivingTime: "เวลาการขับขี่",
    doYouWanttoReActive: "คุณต้องการที่จะเปิดใช้งานอีกครั้งหรือไม่",
    destinationDetail: "ข้อมูลรายละเอียด จุดหมายปลายทาง",
    driverName: "ชื่อ พนักงานขับขี่",
    driverCode: "รหัส พนักงานขับขี่",
    driverPhoneNumber: "เบอร์โทร พนักงานขับขี่",
    destinationLocation: "สถานที่ปลายทาง",
    destination: "จุดหมายปลายทาง",
    done: "เสร็จสิ้น",
    drivingPeriodRange: "ช่วงระยะเวลาการขับขี่(นาที)",
    day: "วัน",
    descrip: "รายละเอียด",
    dashCamera: "ระบบเฝ้าดูผ่านแดชแคม",
    deliveryItemPool: "งานส่งอุปกรณ์",
    deliveryItemDeleteHistoryPool: "ประวัติงานส่งอุปกรณ์",
    deliveryItem: "งานส่งอุปกรณ์",
    dataVisibility: "การมองเห็นข้อมูล",
    disconnected: "ขาดการติดต่อ",
    dateOfBirth: "วันเกิด",
    drivinglicense: "Driving License",
    drivingIicenseNumber: "หมายเลขใบขับขี่",
    drivingIiceneType: "ประเภทใบขับขี่",
    driverHistory: "ประวัติพนักงานขับขี่",
    date: "วันที่",
    detailOfConcreteTruck: "Detail Of Concrete Truck",
    drumCapacity: "Drum Capacity",
    dischargeDenominator: "ค่าคำนวณปริมาณในการเท",
    dataForDLT: "Data For DLT",
    driver2: "พนักงานขับขี่ 2",
    driver1: "พนักงานขับขี่ 1",
    DrivingdistanceReport: "รายงานระยะทางการวิ่ง",
    DistfromlastmaintenanceReport: "รายงานพาหนะที่มีระยะทางวิ่งสะสมล่าสุด",
    duration: "ระยะเวลา",
    DriverinformationReport: "รายงานข้อมูลพนักงานขับขี่",
    distance_more_than: "ระยะทางที่ถือว่าทำงานต่อวัน",
    DrivinglicensescanlogReport: "รายงานการรูดใบขับขี่",
    DrivinglogReport: "รายงานบันทึกการขับขี่",
    DozeoffriskReport: "รายงานเสี่ยงหลับใน",
    DeliveryReport: "รายงานการส่งสินค้า",
    DailyReport: "รายงานสรุปการวิ่งประจำวัน",
    distance_less_than: "ระยะทางน้อยกว่า",
    DrivinghourReport: "รายงานพฤติกรรมการขับขี่",
    deliver: "การขนส่ง",
    DrivingoverlimitReport: "รายงานพาหนะวิ่งงานเกินเวลาที่กำหนด",
    dangerous: "อันตราย",
    detailproblem: "วิธีการแก้ไข",
    doc_no: "เลขเอกสาร",
    document: "เอกสาร",
    durationOfPTOGate1On: " ระยะเวลาเปิด PTO/ประตู1",
    mdvrdownloadfile: "ดาวโหลดวีดีโอ MDVR",
    download: "ดาวโหลด",
    detail_problem: "อาการของปัญหา(อื่นๆ โปรดระบุ)",
    deliverytrip: "ทริปการส่งของ",
    Delivered: "ส่งของแล้ว",
    deliver_status: "สถานะส่งของ",
    destinationLocationcode: "รหัสสถานที่ปลายทาง",
    device: "อุปกรณ์",
    deliver_duration: "เวลาเริ่ม - เวลาสิ้นสุด"
  },

  e: {
    eventEnd: "จบเหตุการณ์",

    expand: "ขยาย",
    edit: "เเก้ไข",
    estTransitDuration: "ระยะเวลา การเดินทาง",
    estTransitDistance: "ระยะทาง การเดินทาง",
    estFare: "ค่าโดยสาร",
    engine: "เครื่องยนต์",
    endLocationName: "ชื่อปลายทาง",
    endLocationCode: "รหัสปลายทาง",
    earning: "Earning",
    EarningReceivedSTATISTICS: "Earning/Received STATISTICS",
    earningType: "Earning Type",
    event: "เหตุการณ์",
    email: "อีเมล์",
    eventAlarm: "Event Alarm",
    emergencyCase: "กรณีฉุกเฉิน",
    emergencyStatus: "สถานะฉุกเฉิน",
    endDate: "วันสิ้นสุด",
    etc: "อื่นๆ",
    europeAmerican: "ยุโรป/อเมริกัน",
    getVehicles: "แสดงพาหนะ",
    endDestinationCode: "รหัสปลายทาง",
    endDestinationName: "ชื่อปลายทาง",
    employeeID: "รหัสพนักงาน",
    engineStatus: "สถานะเครื่องยนต์",
    engineOn: "ติดเครื่อง",
    engineOnWithImg: "ติดเครื่องพร้อมข้อมูลรูปภาพ",
    engineOff: "ดับเครื่อง",
    engineOnDesc: "ตำแหน่งที่ส่งข้อมูล GPS ขณะติดเครื่อง",
    engineOnWithImgDesc:
      "ตำแหน่งที่ส่งข้อมูล GPS พร้อมรูปภาพจาก Dash camera ขณะติดเครื่อง",
    engineOffDesc: "ตำแหน่งที่ส่งข้อมูล GPS ขณะดับเครื่อง",

    earningOverview: "ภาพรวมรายได้",
    eventMap: "ภาพรวมพิกัดการฝ่าฝืน",
    expireDate: "วันที่หมดอายุ",
    endValidDate: "End Valid Date",
    emailVerification: "การยืนยันอีเมล",
    expense: "ค่าใช้จ่าย",
    excel: "Excel",
    EventReport: "รายงานเหตุการณ์พาหนะรายคัน",
    engine_on_time_end_at: "เวลาติดเครื่องสิ้นสุด",
    engine_on_time_start_at: "เวลาติดเเครื่องเริ่มต้น",
    engine_off_time_end_at: "เวลาดับเครื่องสิ้นสุด",
    engine_off_time_start_at: "เวลาดับเครื่องเริ่มต้น",
    efficiency_report_menu: "ประสิทธิภาพ",
    energy_report_menu: "พลังงาน",
    export_date_at: "วันที่ออก",
    engineOnDuration: "ระยะเวลาติดเครื่องยนต์",
    endAt: "เวลาสิ้นสุด",
    expenseBaht: "ค่าใช้จ่าย (บาท)"
  },

  f: {
    fillPosition: "ป้อนตำแหน่ง",
    finished: "เสร็จสิ้น",
    fuel: "เชื้อเพลิง",
    facilityName: "ชื่อสถานที่",
    facilityName2: "ชื่อสถานที่2",
    facilityName3: "ชื่อสถานที่3",

    findDistance: "หาระยะทาง",
    findSpace: "หาพื้นที่",
    findCustomer: "ค้นหา ผู้ใช้บริการ",
    fillInFrom: "Fill In From",
    firstName: "ชื่อ",
    finish: "เสร็จสิ้น",
    fillInfomation: "กรอกข้อมูล",
    filter: "กรอง",
    fare: "ค่าโดยสาร",
    file: "ไฟล์",
    fine: "ปรับ",
    firmwareVersion: "Firmware Version",
    fuelType: "ประเภทน้ำมัน",
    flateNumber: "Flate Number",
    fuelTankSize: "ความจุถังน้ำมัน",
    fuelA: "Fuel A",
    fuelB: "Fuel B",
    fuelC: "Fuel C",
    fuelUsage: "อัตราสิ้นเปลืองเชื้อเพลิง",
    fuelRate: "Fuel Rate",
    fullTank: "เต็มถัง",
    forgot_password: "ลืมรหัสผ่าน",
    filter_type1: "ออกจากโรงงานครั้งแรกถึงเข้าโรงงานครั้งสุดท้าย",
    filter_type2: "ติดเครื่องครั้งแรกถึงดับเครื่องครั้งสุดท้าย",
    filter_type: "รูปแบบ",
    first_dest: "จุดเริ่มต้น",
    firstMaintenanceConditions: "เงื่อนไขการซ่อมบำรุงครั้งแรก",
    file_location: "สถานที่เก็บไฟล์",
    file_size: "ขนาดไฟล์"
  },

  g: {
    general: "ทั่วไป",
    generateMap: "Generate Map",
    gasStation: "ปั้มนั้ำมัน",
    gate1: "ประตู 1",
    gate2: "ประตู 2",
    gate3: "ประตู 3",
    groupVehicleType: "กลุ่ม/ประเภท พาหนะ",
    groupByLocation: "จัดกลุ่มตามสถานที่ตั้ง",
    gpsCertification: "ใบรับรองการติดตั้ง",
    gpsCertRequest: "ขอใบรับรองการตืดตั้ง",
    gpsCertRequestHistory: "ประวัติการขอใบรับรองการติดตั้ง",
    group: "กลุ่ม",
    generalLocation: "General Location",
    greater_than: "มากกว่า",
    GpsstatusReport: "รายงานพาหนะที่สัญญาณขาดหาย",
    group_by: "จัดกลุ่มโดย",
    group_idle: "เฉพาะจอดติดเครื่อง",
    group_speed: "เฉพาะความเร็วเกิน",
    general_report_menu: "ทั่วไป",
    greaterOrEqual: "มากกว่าเท่ากับ",
    gps_update: "ตำแหน่งล่าสุด"
  },

  h: {
    hour: "ชม.",
    hotel: "โรงเเรม",
    high: "สูง",
    home: "หน้าหลัก",
    header: "หัวข้อ",
    hardwareID: "รหัสฮาร์ดแวร์",
    horsePower: "เเรงม้า",
    height: "สูง",
    hours: "ชม.",
    help_menu: "ช่วยเหลือ"
  },

  i: {
    itemSelected: "เลือก",
    itemLeft: "เหลือ",
    ignoredAlert: "ไม่แจ้งเตือน",
    iconDesc: "รายละเอียดไอคอน",
    imageMustSmallerThan5MB: "รูปภาพต้องมีขนาดเล็กกว่า 5MB",
    infomation: "ข้อมูล",
    indian: "อินเดีย",
    internalOperation: "การจัดการภายใน",
    issuedDate: "วันที่ออก",
    id: "ID",
    idleFuelRate: "Idle Fuel Rate",
    imageFromDashCamera: "ภาพจากกล้อง",
    InsuranceReport: "รายงานประกันภัยพาหนะ",
    insurance_report_menu: "ประกันภัยพาหนะ",
    invoice_number: "เลข Invoice",
    invoice_date: "วันที่ Invoice",
    insure_report_menu: "ประกันภัย"
  },

  j: {
    jobId: "รหัสงาน",
    jobDetail: "รายละเอียดงาน",
    jobStatus: "สถานะงาน",
    jobType: "ประเภทงาน",
    jobTypeSTATISTICS: "สถิติประเภทของงาน",
    joinDate: "เข้าร่วมเมื่อ",
    jobStartAt: "เวลาเข้างาน"
  },

  k: {
    km: "กม.",
    kmH: "กม./ชม.",
    keywordAtleast3character: "...คำหลักอย่างน้อย 2 ตัวอักษร"
  },

  l: {
    limitSuddenlyBreakThreshold: "เบรคแรงได้ไม่เกิน",
    limitAggesiveAccelerationThreshold: "เร่งแรงได้ไม่เกิน",
    limitDrivingAggesiveThreshold: "ขับพาหนะจี้ท้ายได้ไม่เกิน",
    limitSnoozeThreshold: "สุ่มเสี่ยงหลับในได้ไม่เกิน",
    limitUsingPhoneThreshold: "ใช้โทรศัพท์ขณะขับขี่ได้ไม่เกิน",
    limitParking: "ห้ามจอดเกิน",
    load: "โหลด",
    loadStartAt: "โหลดเมื่อ",
    loadAmount: "จำนวนโหลด",
    lastName: "นามสกุล",
    location: "สถานที่",
    locationGroup: "กลุ่มสถานที่",
    lookingForSomething: "มองหาบางสิ่ง",
    lostConnectio: "ขาดการติดต่อ",
    lastRequestOn: "คำขอล่าสุดเมื่อ",
    low: "ต่ำ",
    logout: "ออกจากระบบ",
    login: "เข้าสู่ระบบ",
    locationCode: "รหัสสถานที่",
    latitude: "ละติจูด",
    longitude: "ลองจิจูด",
    loadpoint: "จุดโหลดของ",
    locationTemporary: "สถานที่ช่ั่วคราว",
    lazada: "ลาซาด้า",
    latestDriver: "พนักงานขับขี่ล่าสุด",
    link: "Link",
    locationInformation: "ข้อมูลสถานที่",
    length: "ความยาว",
    limitDrivingHourTime: "จำกัดระยะเวลาวิ่งงานต่อเนื่อง (นาที)",
    locationGroupName: "ชื่อกลุ่มสถานที่",
    less_than: "น้อยกว่า",
    LoginhistoryReport: "รายงานประวัติการเข้าใช้งานระบบรายคน",
    login_success: "เข้าระบบสำเร็จ",
    login_fail: "เข้าระบบไม่สำเร็จ",
    LocationInSystem: "สถานที่ที่มีอยู่ในระบบ",
    Location: "สถานที่",
    locationShareTo: "สถานที่ที่เเชร์",
    locationShareFrom: "สถานที่ที่ถูกเเชร์",
    lessOrEqual: "น้อยกว่าเท่ากับ",
    lastest_signal: "สัญญาณล่าสุด"
  },

  m: {
    monthly: "รายเดือน",
    moisture: "ความชื้น",
    month: "เดือน",
    miss: "นางสาว",
    mrs: "นาง",
    mr: "นาย",
    m: "เมตร",
    meter: "Meter",
    medium: "ปานกลาง",
    message: "ข้อความ",
    masterData: "ข้อมูลหลัก",
    min: "นาที",
    mixingDrum: "โม่ผสมปูน",
    map: "แผนที่",
    mapView: "มุมมองเเผนที่",
    meterStatus: "สถานะ Meter",
    monitoring: "ระบบติดตาม",
    match: "Match",
    maxDrumCapacity: "Max Drum Capacity",
    mileNo: "เลขไมล์",
    model: "รุ่น",
    machineSerialNumber: "หมายเลขเครื่องยนต์",
    meterSN: "Meter S/N",
    MaintenanceReport: "รายงานพาหนะที่ต้องบำรุงรักษา",
    more_1_min: "มากกว่า 1 นาที",
    more_5_mins: "มากกว่า 5 นาที",
    more_10_mins: "มากกว่า 10 นาที",
    more_5_sec: "มากกว่า 5 วินาที",
    more_10_sec: "มากกว่า 10 วินาที",
    more_30_sec: "มากกว่า 30 วินาที",
    more_60_sec: "มากกว่า 60 วินาที",
    Minimal: "อย่างย่อ",
    managementdevice_report_menu: "การจัดการอุปกรณ์",
    MaintenancedeviceconcludeReport: "รายงานการซ่อม/ถอดอุปกรณ์",
    maintenance_status: "สถานะ",
    maintenanceConditions: "เงื่อนไขการซ่อมบำรุง",
    maintenance: "การบำรุงรักษา",
    minute: "นาที",
    maintenance_type: "ประเภทการแจ้ง",
    move_in: "เข้าจุด",
    move_out: "ออกจุด",
    maintenancedevice_report_menu: "การจัดการอุปกรณ์",
    manage_product: "จัดการสินค้า"
  },

  n: {
    nameAndType: "ชื่อและประเภท",
    notification: "การแจ้งเตือน",
    notificationEndWhen: "จบแจ้งเตือนเมื่อ",
    nightTime: "ช่วงเวลากลางคืน",
    notReadyForWork: "ไม่พร้อมรับงาน",
    newUserGroup: "กลุ่มผู้ใช้บริการใหม่",
    numberOfExpirationDates: "จำนวนวันที่หมดอายุ",
    nameTitle: "คำนำหน้านาม",
    newRoute: "เส้นทางการวิ่งใหม่",
    newUser: "ผู้ใช้งานใหม่",
    nearPlants: "แพลนต์ใกล้ๆ",
    noParking: "จุดห้ามจอด",
    newLocation: "สถานที่ใหม่",
    newTicket: "Ticket ใหม่",
    nationalityZone: "โซนสัญชาติ",
    newCustomer: "ผู้ใช้บริการใหม่",
    noVehicleSelect: "ยังไม่เลือกพาหนะ",
    nationality: "สัญชาติ",
    name: "ชื่อ",
    newJob: "งานใหม่",
    no: "#",
    number: "จำนวน",
    nounsCar: "คัน",
    new: "เพิ่ม",
    next: "ถัดไป",
    notificationType: "ประเภทการเเจ้งเตือน",
    node: "Node",
    nodeName: "ชื่อ Node",
    nextRegisterDate: "วันต่ออายุประกันภัยพาหนะ",
    nearByLocation: "สถานที่ใกล้เคียง",
    nearbyPlants: "เเพลนต์ใกล้สุด",
    NumberofdaysworkedReport: "รายงานจำนวนวันที่พาหนะวิ่งงาน",
    number_of_days: "จำนวนวัน",
    no_filter: "ไม่กรอง",
    numberVehiclegroup: "จำนวน (คัน)",
    nextMaintenanceConditions: "เงื่อนไขการซ่อมบำรุงครั้งต่อไป"
  },

  o: {
    onProcess: "อยู่ระหว่างการส่ง",
    originToDestination: "ต้นทาง-ปลายทาง",
    overview: "ภาพรวม",
    other: "อื่น",
    obd: "OBD",
    openFilter: "เปิดการค้นหา",
    or: "หรือ",
    overSpeedInNormalRoutes: "ความเร็วเกินกำหนดในเส้นทางปกติ",
    overdueSpeedInTheCommunityArea: "ความเร็วเกินกำหนดในเขตชุมชน",
    overSpeedInTheJunction: "ความเร็วเกินกำหนดในทางเเยก",
    open: "เปิด",
    orderCode: "รหัสการสั่งซื้อ",
    ok: "ตกลง",
    operation: "Operation",
    ownerView: "เจ้าของพาหนะเห็นข้อมูล",
    operator: "ผู้ประกอบการ",
    online: "ออนไลน์",
    offline: "ออฟไลน์",
    vehicleOffline: "พาหนะสถานะออฟไลน์",
    okay: "ตกลง",
    over_hour: "พาหนะวิ่งเกิน(ชั่วโมง)",
    ordinal_numbers: "ครั้งที่"
  },

  p: {
    preventRePinLocationConfirm:
      "ตำแหน่งสถานที่จะหายไปเมื่อกดปักหมุดใหม่ ต้องการยืนยัน?",
    preventReDrawLocationConfirm:
      "ตำแหน่งสถานที่จะหายไปเมื่อกดปักหมุดใหม่ ต้องการยืนยัน?",
    parking: "จอดพาหนะ",
    policy: "นโยบาย",
    plantArrivingTime: "เวลาเข้าแพลนต์",
    plantCode: "รหัสแพลนต์",
    productType: "ประเภทสินค้า",
    phoneNumber: "เบอร์โทร",
    plateNo: "ทะเบียน",
    pickup: "Pick Up",
    pickupData: "Pick Up Date",
    passenger: "จำนวนผู้โดยสาร",
    pickUpTime: "PickUp Time",
    pickUpLocation: "Pick Up Location",
    pickUpDetail: "Pick Up Detail",
    pickUpDateTime: "Pick Up At",
    previous: "ย้อนกลับ",
    pinDown: "ปักหมุด",
    partiallyDamagedCard: "บัตรเสียหายบางส่วน",
    pto: "PTO",
    personal: "ส่วนบุคคล",
    partner: "ผู้ว่าจ้าง",
    parkingLot: "ลานจอดพาหนะ",
    polyGonRadius: "Polygon Radius",
    pdf: "PDF",
    photo: "รูป",
    privilegeฌroup: "กลุ่มสิทธิ์",
    purchaseChannel: "ช่องทางการซื้อ",
    pending: "รอดำเนินการ",
    PONumber: "P/O Number",
    pdfFilesUpTo5MBInSize: "ไฟล์ PDF ขนาดไม่เกิน 5MB",
    po: "ใบ PO",
    pleaseSelect: "กรุณาเลือก",
    pictureUpdate: "อัพเดทรูปภาพ",
    plant: "Plant",
    picture: "รูปภาพ",
    password: "รหัสผ่าน",
    personalID: "หมายเลขบัตรประชาชน",
    plantFactory: "Plant/Factory",
    period: "ระยะเวลา",
    periodOfInsuranceYear: "ระยะเวลาประกันภัยพาหนะ (ปี)",
    province: "จังหวัด",
    part_type: "อะไหล่พาหนะ",
    ParkingReport: "รายงานจุดจอดพาหนะ",
    preview: "แสดงตัวอย่าง",
    partnerCar: "ผู้เช่าพาหนะ",
    publicKey: "รหัสบริษัท",
    partType: "ชนิดอะไหล่พาหนะ",
    partgroup: "กลุ่มอะไหล่พาหนะ",
    product_type: "ชนิดของผลิตภัณฑ์",
    product_name: "ชื่อสินค้า",
    plan_start_deliver_at: "เวลาเริ่มจัดส่งตามแผน"
  },

  q: {
    quantity_liter: "ปริมาณ (ลิตร)",
    quantity: "ปริมาณ"
  },

  r: {
    rainingTime: "ช่วงฝนตก",
    replay: "Replay",
    replayDetail: "รายละเอียด Replay",
    received: "Received",
    reportSummarizingTheNumberOfOffSiteParking:
      "รายงานสรุป จำนวนครั้งการจอดพาหนะนอกพื้นที่",
    reportCardSwipeBehaviorBeforeDrivingDaily:
      "รายงาน พฤติกรรมการรูดบัตรก่อนขับขี่ รายวัน",
    reportType: "ประเภทรายงาน",
    reActivate: "เปิดใช้งานอีกครั้ง",
    reset: "รีเซ็ต",
    routeRadius: "รัศมีของเส้นทาง",
    remainingRequest: "คำขอคงค้าง",
    routeName: "ชื่อเส้นทาง",
    restArea: "จุดพักพาหนะ",
    radiuns: "รัศมี",
    radiunsM: "รัศมี (ม.)",
    require: "โปรดระบุ",
    remark: "ข้อคิดเห็น",
    rpm: "RPM",
    running: "วิ่ง",
    remark1: "Remark 1",
    remark2: "Remark 2",
    reminderData: "Reminder Data",
    reminderTime: "Reminder Time",
    remarkBooking: "Remark Booking",
    requestList: "รายการคำขอ",
    report: "รายงาน",
    reportAlarmFrequencyClassifiedByDailyDriver:
      "รายงานความถี่ alarm แยกตามพนักงานขับขี่ รายวัน",
    route: "เส้นทาง",
    rfid: "RFID",
    refuelAt: "เติมน้ำมันเมื่อ",
    refuel: "เติมเชื้อเพลิง",
    RefuelReport: "รายงานการเติมน้ำมัน",
    RiskbehaviourReport: "รายงานพฤติกรรมการขับขี่ไม่ปลอดภัย",
    risk: "เสี่ยง",
    routeRadiusM: "รัศมีของเส้นทาง (ม.)",
    round: "รอบ"
  },
  s: {
    speedKmPerHr: "ความเร็ว (km./h)",
    safety: "ความปลอดภัย",
    subType: "ประเภทย่อย",
    setting: "ตั้งค่า",
    shipTo: "ส่งไปยัง",
    siteCode: "รหัสไซต์",
    saveNext: "บันทึกเเละถัดไป",
    submit: "บันทึก",
    send: "ส่ง",
    save: "บันทึก",
    search: "ค้นหา..",
    searchPlants: "ค้นหา",
    surplus: "เหลือ",
    selected: "เลือก",
    status: "สถานะ",
    secondaryPhoneNumber: "เบอร์โทร 2",
    selectedVehicleAlready: "พาหนะที่เลือกไว้",
    southeastAsia: "เอเชียตะวันออกเฉียงใต้",
    showTraffic: "แสดงการจราจร",
    searchTextFromPlateNo: "ค้นหาจากเลขทะเบียน ...",
    speedRange: "ช่วงความเร็ว (km./h)",
    speedRangeNoKm: "ช่วงความเร็ว",
    slidingCard: "รูดบัตร",
    slideWrongCardType: "รูดบัตรผิดประเภท",
    speed: "ความเร็ว",
    support: "สนับสนุน",
    searchText: "ค้นหาคำ",
    someparts: "บางส่วน..",
    speedLimited: "เร็วเกินกำหนด",
    startDate: "วันเริ่มต้น",
    sentBy: "ส่งโดย",
    sender: "ผู้ส่ง",
    sendAsAGroupOfVehicles: "ส่งเเบบกลุ่มพาหนะ",
    sendSelectedVehicles: "ส่งเเบบเลือกพาหนะ",
    sentSuccessfully: "ส่งเรียบร้อย",
    sendFailure: "ส่งผิดพลาด",
    selectPlateNo: "เลือกทะเบียน",
    selectAssignTo: "เลือกผู้รับมอบหมาย",
    selectCriticalityLevel: "เลือกระดับความรุนเเรง",
    selectStatus: "เลือกสถานะ",
    selectVehicleGroup: "เลือกกลุ่มพาหนะ",
    selectNotificationChannel: "เลือกช่องทางเเจ้งเตือน",
    smsNotifyTo: "ส่ง sms แจ้งเตือนไปที่หมายเลข",
    startLocationName: "ชื่อต้นทาง",
    startDestinationCode: "รหัสต้นทาง",
    startDestinationName: "ชื่อต้นทาง",
    setTheRoute: "กำหนดเส้นทาง",
    startLocationCode: "รหัสต้นทาง",
    specialPrivilege: "สิทธิพิเศษ",
    selectOne: "เลือก 1 รายการ",
    state: "สถานะภาพ",
    shopee: "ช้อปปี้",
    salesChannels: "ช่องทางการขาย",
    selectFile: "เลือกไฟล์",
    simNumber: "SIM Number",
    startTrip: "จุดเริ่มต้นทริป",
    sos: "sos",
    satellite: "ดาวเทียม",
    SCCOCarComparisonReport: "รายงาน เปรียบเทียบพาหนะของ SCCO",
    shareTo: "พาหนะที่แชร์",
    shareFrom: "พาหนะที่ถูกแชร์",
    sharedAt: "แชร์เมื่อ",
    speedOverLimit: "ความเร็วเกินกำหนด",
    speedOverLimitByVehicleGroup: "ความเร็วเกินกำหนดตามกลุ่มพาหนะ",
    speedOverLimitByVehicleType: "ความเร็วเกินกำหนดตามประเภทพาหนะ",
    speedOverLimitByDriver: "ความเร็วเกินกำหนดตามพนักงานขับขี่",
    stopWithEngineOn: "จอดไม่ดับเครื่อง",
    startValidDate: "Start Valid Date",
    setup: "ติดตั้ง",
    smallTruck: "พาหนะเล็ก",
    shaft: "จำนวนเพลาล้อ",
    statusVehicleVideo: "สถานะ",
    status_moving: "พาหนะวิ่ง",
    status_parked: "จอดพาหนะ",
    status_engineoff: "ดับเครื่อง",
    status_vehicle: "สถานะพาหนะ",
    select_event: "เลือกเหตุการณ์",
    SpeedoverlimitsummaryReport: "รายงานสรุปความเร็วเกินกำหนด",
    SummaryKPIReport: "สรุปรายงาน KPI",
    startDeliveryAt: "วัน/เวลาเข้าถึงหน้างาน",
    startRefuel: "เริ่มเติมน้ำมัน",
    start_daily_time: "เวลาเริ่มในแต่ละวัน",
    second_dest: "จุดสิ้นสุด",
    ship_to: "รหัสไซต์งาน",
    scan_date_at: "วันที่อัพโหลด",
    shareLocation: "แชร์สถานที่",
    startAt: "เวลาเริ่ม",
    send_email: " ส่งอีเมล์",
    Servicerepairdevice: "แจ้งซ่อม/ถอดอุปกรณ์",
    submit_name: "ชื่อผู้ยื่นเรื่อง",
    submit_telno: "เบอร์ติดต่อผู้ยื่นเรื่อง",
    start_deliver_at: "เวลาเริ่มทริป"
  },

  t: {
    ticket: "ตั๋ว",
    title: "คำนำหน้า",
    today: "วันนี้",
    thisMonth: "เดือนนี้",
    thisYear: "ปีนี้",
    taxiMeter: "Taxi Meter",
    temperature: "อุณหภูมิ",
    temperature1: "อุณหภูมิ 1",
    temperature2: "อุณหภูมิ 2",
    TheUsualRoute: "เส้นทางปกติ",
    time: "เวลา",
    toggleHeatMap: "Toggle Heat Map",
    trackingNo: "หมายเลขการติดตาม",
    type: "ประเภท",
    total: "ทั้งหมด",
    totalEmergencyCase: "กรณีฉุกเฉินทั้งหมด",
    typeLocation: "ชนิดของสถานที่",
    theRoute: "เส้นทาง",
    toSiteDistance: "ระยะทางขาไป",
    timeThatCanBeUsed: "ระยะเวลาที่สามาพาหนะใช้งานได้",
    top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod:
      "รายงาน alert 5 อันดับแรกของแต่ละประเภท แบ่งตามช่วงเวลา รายเดือน",
    theDriverIsIicenseReportHasExpired: "รายงาน การแจ้งเตือนใบขับขี่หมดอายุ",
    theDayThatTheCarPulled: "วันที่ดึงพาหนะจม",
    trip: "ทริป",
    tripStatus: "สถานะทริป",
    topCustomer: "Top Customer",
    topEarning: "Top Earning",
    tool: "เครื่องมือ",
    tools: "เครื่องมือ",
    tracking: "ระบบติดตาม",
    traininglist: "รายการฝึกอบรม",
    trainingDate: "วันที่อบรม",
    trainer: "ผู้ฝึกอบรม",
    tagID: "รหัสเเท็ก",
    truckCategory: "Truck Category",
    truckType: "Truck Type",
    tire: "จำนวนยาง",
    truckDriverMatching: "Truck Driver Matching",
    TruckusageReport: "รายงานการใช้งานพาหนะ",
    totalPrice: "ราคารวม",
    TemperatureReport: "รายงานอุณหภูมิ",
    time_15: "15 นาที",
    time_30: "30 นาที",
    time_60: "1 ชั่วโมง",
    time_180: "3 ชั่วโมง",
    time_300: "5 ชั่วโมง",
    time_420: "7 ชั่วโมง",
    time_720: "12 ชั่วโมง",
    time_1440: "24 ชั่วโมง",
    TrackingdataReport: "รายงานรายละเอียดพิกัดพาหนะ",
    type_report: "ประเภทรายงาน",
    type_trip: "รายทริป",
    type_person: "รายบุคคล",
    type_duration: "ตามช่วงเวลา",
    TriptemperatureReport: "รายงานสรุปอุณหภูมิแยกตามทริป",
    TripsumbyvehReport: "รายงานสรุปการวิ่งแยกตามพาหนะ",
    TruckengineonReport: "รายงานพาหนะติดเครื่อง",
    TruckengineoffReport: "รายงานพาหนะไม่ติดเครื่อง",
    TrucknotuseReport: "รายงานพาหนะไม่ได้วิ่งงาน",
    tripCode: "รหัสทริป",
    TripsummaryReport: "รายงานสรุปการวิ่งแยกตามทริป",
    TripsumbylocationReport: "รายงานสรุปจำนวนงานตามสถานที่",
    trip_report_menu: "ทริป",
    typeMaintenance: "ประเภท",
    technician: "ช่าง",
    TruckcurrentlocationReport: "รายงานที่อยู่ปัจจุบันของพาหนะ",
    time_period: "ช่วงระยะเวลา",
    type_problem: "อาการของปัญหา",
    trip_date: "ช่วงเวลาเริ่ม-สิ้นสุดทริป",
    trip_duration: "ช่วงเวลาเริ่ม-สิ้นสุดทริป"
  },

  u: {
    userClose: "ผู้ใช้งานปิดเคส",
    updatedAt: "อัพเดทเมื่อเวลา",
    userProfile: "ข้อมูลผู้ใช้",
    userGroupPremission: "การอนุญาตกลุ่มผู้ใช้",
    unknown: "ไม่ระบุ",
    userName: "ชื่อผู้ใช้งาน",
    updatePictures: "อัพเดทรูปภาพ",
    updateLocation: "อัพเดทตำแหน่ง",
    unassign: "Unassign",
    updatedBy: "เเก้ไขข้อมูลโดย",
    user: "ผู้ใช้งาน",
    userGroup: "กลุ่มผู้ใช้งาน",
    unMatch: "Un Match",
    upload: "Upload",
    userExpire: "ระยะเวลาสิ้นสุดการใช้งาน",
    unitPrice: "หน่วยละ (บาท)"
  },

  v: {
    vnCode: "VN Code",
    vehicleAndDriver: "พาหนะและพนักงานขับขี่",
    video: "วีดีโอ",
    vehicleModel: "รุ่นพาหนะ",
    vehicleColor: "สีพาหนะ",
    vehicleType: "ประเภทพาหนะ",
    vehicle: "พาหนะ",
    vehicleID: "รหัสพาหนะ",
    vehicleStatus: "สถานะพาหนะ",
    view: "ดู",
    vehicleDetail: "ข้อมูลรายละเอียด พาหนะ",
    vehicleGroupName: "ชื่อกลุ่มพาหนะ",
    vehicleGroup: "กลุ่มพาหนะ",
    vehicleSharing: "แชร์พาหนะ",
    validDate: "Valid Date",
    vehicleRegisterType: "Vehicle Register Type",
    VelocitysummaryReport: "รายงานสรุปความเร็ว",
    vehicleCode: "รหัสพาหนะ",
    VehicleperformanceconcludeReport: "รายงานสรุปประสิทธิภาพพาหนะรายคัน",
    VehicleinareaReport: "รายงานพาหนะเข้าพื้นที่",
    video_type: "ประเภทวีดีโอ",
    vehicle_type: "ประเภทพาหนะ"
  },

  w: {
    whenEventOccurred: "ให้ระบบทำการ",
    weekly: "รายสัปดาห์",
    week: "สัปดาห์",
    warrantyEnd: "สิ้นสุดการรับประกัน",
    workOverTime: "ปฏิบัติงานเกินช่วงเวลา",
    waitingConfirm: "รอยืนยัน",
    wigth: "กว้าง",
    wheel: "จำนวนวงล้อ",
    waitingForJob: "รองาน"
  },

  x: {},

  y: {
    youCanOonlyUploadPdfFile: "คุณสามาพาหนะอัปโหลดไฟล์ PDF ได้เท่านั้น",
    yearly: "รายปี",
    year: "ปี"
  },

  z: {
    zoomIn: "ขยายเข้า",
    zoomOut: "ขยายออก",
    zone: "พื้นที่",
    zoneName: "ชื่อพื้นที่"
  },

  variable: {
    parking: "จอดพาหนะ",
    driving: "ขับพาหนะ",
    insurance: "ประกันภัย",
    maintenance_vehicle: "พาหนะ",
    safety: "ความปลอดภัย",
    prohibited_area: "พื้นที่หวงห้าม",
    abnormal_behavior: "พฤติกรรมการขับขี่ไม่ปลอดภัย",
    driving_hour: "ชั่วโมงการขับขี่",
    speed: "ความเร็ว",
    high: "สูง",
    medium: "กลาง",
    low: "ต่ำ",
    tire: "ยาง",
    engine_oil: "น้ำมันเครื่อง",
    suspension: "ช่วงล่าง",
    voluntary_motor_insurance: "ประกันภัยรถยนต์ภาคสมัครใจ",
    compulsory_motor_insurance: "ประกันภัยรถยนต์ภาคบังคับ"
  }
};

export { th };
