import {
  LOAD_TRUCKINSPECTIONMAINTENANCE,
  LOAD_COMPANY_TRUCKINSPECTIONMAINTENANCE,
  LOAD_PLANT_TRUCKINSPECTIONMAINTENANCE,
  LOAD_TRAINER_TRUCKINSPECTIONMAINTENANCE
} from "./actions";

const initialState = {
  alarmalertbyplatenoUrl: "",
  vehicleLists: [],
  plantLists: [],
  trainerLists: [],
  alarmalertbyplatenoLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRUCKINSPECTIONMAINTENANCE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        alarmalertbyplatenoLoading: loading
      };
    case LOAD_TRUCKINSPECTIONMAINTENANCE.SUCCESS:
      return {
        ...state,
        alarmalertbyplatenoUrl: action.payload.data.data,
        alarmalertbyplatenoLoading: false
      };
    case LOAD_COMPANY_TRUCKINSPECTIONMAINTENANCE.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        alarmalertbyplatenoLoading: loading_vehicle
      };
    case LOAD_COMPANY_TRUCKINSPECTIONMAINTENANCE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        alarmalertbyplatenoLoading: false
        //  loading: false
      };
    case LOAD_PLANT_TRUCKINSPECTIONMAINTENANCE.REQUEST:
      const { loading_plant } = action.payload;
      return {
        ...state,
        alarmalertbyplatenoLoading: loading_plant
      };
    case LOAD_PLANT_TRUCKINSPECTIONMAINTENANCE.SUCCESS:
      return {
        ...state,
        plantLists: action.payload.data.data,
        alarmalertbyplatenoLoading: false
        //  loading: false
      };
    case LOAD_TRAINER_TRUCKINSPECTIONMAINTENANCE.REQUEST:
      const { loading_trainer } = action.payload;
      return {
        ...state,
        alarmalertbyplatenoLoading: loading_trainer
      };
    case LOAD_TRAINER_TRUCKINSPECTIONMAINTENANCE.SUCCESS:
      return {
        ...state,
        trainerLists: action.payload.data.data,
        alarmalertbyplatenoLoading: false
        //  loading: false
      };
    default:
      return state;
  }
};
