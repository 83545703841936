import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Row,
    Col
} from "antd";

import * as actions from "../actions";
import Forms from "./Forms";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import history from "../../../../lib/history";

class Add extends Component {


    create = (values, loading) => {
        this.props.createServicerepairdevice(values, loading, this.props.history,this.props.match.params.company_id);

    };



    render() {
        const { servicerepairdeviceLoading } = this.props.servicerepairdevice;
        console.log(this.props);
        return (
            <GeneralStyledContent>
                <Row gutter={24}>
                    <Col span={24}>
                        <Breadcrumb
                            match={this.props.match}
                            style={{ margin: "0px 0px 14px 10px" }}
                        />
                    </Col>
                </Row>
                <Forms
                    onSubmit={this.create}
                    servicerepairdevice={this.props.servicerepairdevice}
                    loadVehicle={this.props.loadVehicle}
                    loadProvince={this.props.loadProvince}
                    loadAmphur={this.props.loadAmphur}
                    loadMaintenancetype={this.props.loadMaintenancetype}
                    loadProblem={this.props.loadProblem}
                    vehicle_id={this.props.match.params.id}
                />
            </GeneralStyledContent>
        );
    }
}

const mapStateToProps = ({
    servicerepairdevice
}) => ({
    servicerepairdevice,
});

const mapDispatchToProps = {
    createServicerepairdevice: actions.createServicerepairdevice.request,
    loadVehicle: actions.loadVehicle.request,
    loadProvince: actions.loadProvince.request,
    loadAmphur: actions.loadAmphur.request,
    loadMaintenancetype: actions.loadMaintenancetype.request,
    loadProblem: actions.loadProblem.request,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Add);
