import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import { getlocalstorage } from "./../../../constants/local_storage";

function* loadMaintenance(action) {
    const {
        data: {
            page,
            pageSize,
            companyID,
            orderBy,
            orderType,
            accessToken,
            filterObj
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/management/getmaintenence`,
            {
                page,
                pageSize,
                companyID,
                orderBy,
                orderType,
                filterObj
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.loadMaintenance.success(res.data, page));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadMaintenance.failure());
    }
}

function* loadAutocompleteVehiclePlateNo(action) {
    const { url, companyID, accessToken, queryString } = action.payload;

    const vehicle_visibility = getlocalstorage(
        localStorage.getItem("profile"),
        "vehicle_visibility"
    );

    try {
        if (queryString == "" || queryString.length <= 1) {
            yield put(actions.loadAutocompleteVehiclePlateNo.success([]));
        } else {
            const res = yield call(
                axios.post,
                `${process.env.REACT_APP_API_ENDPOINT}/${url}`,
                {
                    vehicle_visibility,
                    company_id: companyID,
                    plate_no: queryString
                },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );

            yield put(actions.loadAutocompleteVehiclePlateNo.success(res.data));
        }
    } catch (err) {

        console.log(err.message);
        yield put(actions.loadAutocompleteVehiclePlateNo.failure());
    }
}




function* loadAutocompleteVehicleCode(action) {
    const { url, companyID, accessToken, queryString } = action.payload;
    const vehicle_visibility = getlocalstorage(
        localStorage.getItem("profile"),
        "vehicle_visibility"
    );

    try {
        if (queryString == "" || queryString.length <= 1) {
            yield put(actions.loadAutocompleteVehicleCode.success([]));
        } else {
            const res = yield call(
                axios.post,
                `${process.env.REACT_APP_API_ENDPOINT}/${url}`,
                {
                    vehicle_visibility,
                    company_id: companyID,
                    code: queryString
                },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );

            yield put(actions.loadAutocompleteVehicleCode.success(res.data));
        }
    } catch (err) {

        console.log(err.message);
        yield put(actions.loadAutocompleteVehicleCode.failure());
    }
}

function* createMaintenance(action) {
    const {
        data: {
            companyID,
            vehicle_plate_no,
            vehicle_code,
            vehicle_id,
            start_at,
            end_at,
            accessToken,
        }, loading
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/management/maintenence/create`,
            {
                companyID,
                vehicle_plate_no,
                vehicle_code,
                vehicle_id,
                start_at,
                end_at,
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        loading(res.data)
        yield put(actions.createMaintenance.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.createMaintenance.failure());
    }
}

function* createDetailMaintenance(action) {
    const {
        data: {
            companyID,
            accessToken,
            maintenanec_id,
            part_id,
            quantity,
            mile_no,
            unit,
            maintain_at,
        }, loading
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/management/detailmaintenence/create`,
            {
                companyID,
                maintenanec_id,
                part_id,
                quantity,
                mile_no,
                unit,
                maintain_at,
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        loading(res.data)
        yield put(actions.createDetailMaintenance.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.createDetailMaintenance.failure());
    }
}

function* loadDetailMaintenance(action) {
    const {
        data: {
            page,
            pageSize,
            companyID,
            orderBy,
            orderType,
            maintenanec_id,
            accessToken
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/management/getdetailmaintenence`,
            {
                page,
                pageSize,
                companyID,
                maintenanec_id,
                orderBy,
                orderType,
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.loadDetailMaintenance.success(res.data, page));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadDetailMaintenance.failure());
    }
}

export default function* watchManagementMaintenance() {
    yield all([
        takeEvery(actions.LOAD_MAINTENANCE.REQUEST, loadMaintenance),
        takeEvery(actions.LOAD_AUTOCOMPLETE_PLATENO.REQUEST, loadAutocompleteVehiclePlateNo),
        takeEvery(actions.LOAD_AUTOCOMPLETE_CODE.REQUEST, loadAutocompleteVehicleCode),
        takeEvery(actions.CREATE_MAINTENANCE.REQUEST, createMaintenance),
        takeEvery(actions.CREATE_MAINTENANCE_DETAIL.REQUEST, createDetailMaintenance),
        takeEvery(actions.LOAD_MAINTENANCE_DETAIL.REQUEST, loadDetailMaintenance),
    ]);
}
export {
    loadMaintenance,
    loadAutocompleteVehicleCode,
    loadAutocompleteVehiclePlateNo,
    createDetailMaintenance,
    loadDetailMaintenance,
    createMaintenance
}