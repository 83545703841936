import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadZone(action) {
    const {
        data: {
            page,
            pageSize,
            companyID,
            orderBy,
            orderType,
            accessToken,
            filterObj
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/getzone`,
            {
                page,
                pageSize,
                companyID,
                orderBy,
                orderType,
                filterObj
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.loadZone.success(res.data, page));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadZone.failure());
    }
}

function* loadDetail(action) {
    const {
        data: {
            id,
            accessToken,
        }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/detailzone/${id}`,
            {
                id
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.loadDetail.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadDetail.failure());
    }
}

function* loadAutocompleteLocation(action) {
    const { url, companyID, accessToken, queryString } = action.payload;  
    try {
      if (queryString == "" || queryString.length <= 1) {
        yield put(actions.loadAutocompleteLocation.success([]));
      } else {
        const res = yield call(
          axios.get,
          `${process.env.REACT_APP_API_ENDPOINT}/${url}/${companyID}/${queryString}`,
  
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
  
        yield put(actions.loadAutocompleteLocation.success(res.data));
      }
    } catch (err) {
  
      console.log(err.message);
      yield put(actions.loadAutocompleteLocation.failure());
    }
}

function* createZone(action) {
    const {
        data: {
            company_id,
            name,
            geofence,
            accessToken
        }, loading
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/zone/create`,
            // `${DEV_API_ENDPOINT}/customer/create`,
            {
                company_id,
                name,
                geofence,
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        loading(res.data);
        yield put(actions.createZone.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.createZone.failure());
    }
}

function* updatezone(action)
{
    const {
        data: {
               id,
               name,
               geofence,
               accessToken
            }, loading
    }= action.payload;

    try {
        const res = yield call(
            axios.post,
              `${process.env.REACT_APP_API_ENDPOINT}/zone/update`,
            {
                id,
                name,
                geofence,
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        loading(res.data);
        yield put(actions.updatezone.success(res.data));
      } catch (err) {
        console.log(err.message);
        yield put(actions.updatezone.failure());
      }
}
  

export default function* watchZone() {
    yield all([
        takeEvery(actions.LOAD_ZONE.REQUEST, loadZone),
        takeEvery(actions.LOAD_AUTOCOMPLETE.REQUEST, loadAutocompleteLocation),
        takeEvery(actions.CREATE_MASTERDATA_ZONE.REQUEST, createZone),
        takeEvery(actions.LOAD_DETAIL_ZONE.REQUEST, loadDetail),
        takeEvery(actions.UPDATE_ZONE.REQUEST, updatezone),
    ]);
}

export {
    loadZone,
    loadDetail,
    updatezone,
    loadAutocompleteLocation,
    createZone
}