const en = {
  a: {
    after5min: "After 5 Min.",
    after30min: "After 30 Min.",
    after1hour: "After 1 Hour",
    after4hour: "After 4 Hour",
    after8hour: "After 8 Hour",
    after1day: "After 1 Day",
    alertNoti: "Alert",
    actionRequired: "Action Required",
    allowWorkingTime: "Allow Working Time",
    apply: "Apply",
    all: "All",
    add: "Add",
    amount: "Amount",
    addVehicleGroup: "Add Vehicle Group",
    action: "Action",
    arab: "Arab",
    african: "African",
    adjustTheTable: "Adjust The Table",
    actualTransitDistance: "Actual Transit Distance",
    alarm: "Alarm",
    alert: "Alert",
    account: "Account",
    address: "Address",
    active: "Active",
    agency: "Agency",
    administrator: "Administrators",
    addInformation: "Add Information",
    alarmAndAlertReportClassifiedByDailyCarRegistration:
      "Alarm And Alert Report Classified By Daily Car Registration",
    alarmAndAlertReportClassifiedByYearlyCarRegistration:
      "Alarm And Alert Report Classified By Yearly Car Registration",
    avgEarningJob: "Avg. Earning/Job",
    ACCUMULATEEARNINGSTATISTICS: "ACCUMULATE EARNING STATISTICS",
    assignTo: "Assign To",
    addARow: "Add A Row",
    alertAlarm: "Alert Alarm",
    announcement: "Announcement",
    announcementBroadcast: "Announcement Broadcast",
    allowSharedPartnerEdit: "Partner can Edit",
    addDrivinglicense: "Add Driving License",
    amountPlant: "Amount Plant",
    accessory: "Accessory",
    and: "AND",
    appointment_date: "Appointment date",
    appointment_place: "Appointment place",
    amphur: "District"
  },

  b: {
    BOOM: "BOOM",
    behavior: "Behavior",
    BOOKINGSTATISTICS: "BOOKING STATISTICS",
    booking: "Booking",
    baseTemplate: "Base Template",
    base: "Base",
    bookedDate: "Booked Date",
    busStopPoint: "Bus Stop Point",
    busStop1: "Bus Stop 1",
    busStop2: "Bus Stop 2",
    boxNumber: "Device No.",
    boxID: "Device ID",
    bookingDate: "Booking Date",
    button: "Search",
    back: "Back",
    bookingRemark: "Booking Remark",
    bookingPool: "Booking Pool",
    bookingOverview: "Booking Overview",
    brand: "Brand",
    behavior_report_menu: "Behavior"
  },

  c: {
    continuousDrive: "Continuously Drive",
    continuousDriveInADay: "Continuously Drive In a Day",
    criticalLevel: "Critical Level",
    category: "Category",
    concreteDeliveryDashboard: "Concrete Delivery Status",
    collapse: "Collapse",
    cancel: "Cancel",
    close: "Close",
    clear: "Clear",
    button: "Search",
    customer: "Passenger",
    customerDetail: "Passenger Detail",
    chineseKoreanJapanese: "Chinese/Korean/Japanese",
    cancelReason: "Reason Of Cancellation",
    clearUp: "Clear Up",
    configuration: "Configuration",
    connection: "Connection",
    currentAddress: "Current Address",
    chauffeur: "Driver",
    communityArea: "Community Area",
    crossroad: "Junction",
    createdAt: "Created At",
    customerType: "Customer Type",
    criticality: "Criticality",
    comment: "Description",
    channel: "Channel",
    closed: "Closed",
    changeLanguage: "Change Language",
    confirmToDeleteThisItem: "Confirm To Delete This Item.",
    carService: "Car Service",
    company: "Company",
    confirmPassword: "Confirm Password",
    cancelled: "Cancelled",
    confirm: "Confirm",
    changeProduct: "Change Product",
    customerName: "Passenger Name",
    confirmToAddThisItem: "Confirm To Add This Item.",
    custom: "Custom",
    contractor: "Contractor",
    currentVehicle: "Current Vehicle",
    courseName: "Course Name",
    Color: "Color",
    companyBilling: "Company Billing",
    chasisSerialNumber: "Chasis Serial Number",
    createdBy: "Created By",
    CompareVehicleSccoReport: "Compare Vehicle SCCO Report",
    cycleOfDrumRotation: "Cycle of drum rotation",
    channel: "Channel",
    contact_name: "Contact name",
    contact_telno: "Contact telno",
    confirm_appointment_place: "Confirm appointment place",
    created_by: "Created by",
    confirm_type: "Confirm type"
  },

  d: {
    dayTime: "Day Time",
    driving: "Driving",
    daily: "Daily",
    diff: "Diff",
    deliveryStatus: "Delivery Status",
    dailyCarStatusReport: "Daily Car Status Report",
    dailyDrivingBehaviorReport: "Daily Driving Behavior Report",
    driveOverTheSpeedLimitInTheCrossroads:
      "Drive Over The Speed Limit In The Crossroads",
    day: "Day",
    driveTooFastInTheNormalRoute: "Drive Too Fast In The Normal Route",
    driveTooFastInTheCommunity: "Drive Too Fast In The Community",
    dateRange: "Date Range",
    DailyAlarmAndAlertReportByDriver: "Daily Alarm And Alert Report By Driver",
    deletedAt: "Deleted At",
    deletedBy: "Deleted By",
    deleteReason: "Delete Reason",
    deleteInformation: "Delete Information",
    draft: "Draft",
    detail: "Detail",
    doYouWantToConfirmSend: "Do You Want To Confirm Send?",
    delete: "Delete",
    distance: "Distance",
    deliverPoint: "Deliver Point",
    doYouWantToDeleteTheseItems: "Do You Want To Delete These Items?",
    doYouWantToCancel: "Do You Want To Cancel?",
    doYouWantToAddTheseItems: "Do You Want To Add These Items?",
    descrip: "Description",
    dateTime: "Date/Time",
    description: "Description",
    dateOfSubmission: "Request Date",
    dateSubmit: "Delivery Date",
    displayTime: "Display Time",
    deliveryAddress: "Delivery Address",
    driveContinuouslyForMoreThan4Hours: "Driving Time > 4 hrs",
    driveContinuouslyForMoreThan10HoursDay: "Driving Time > 10 hrs/day",
    doNotParkBesideTheRoad: "No Parking On Sideways",
    drivingPeriodRange: "Driving Period Range (min)",
    doNotSwipeCardsOrDamageCards: "Please Check Your Card",
    doYouWanttoReActive: "Do You Want To Reactivate?",
    driverName: "Driver Name",
    driverCode: "Driver Code",
    driverPhoneNumber: "Phone Number",
    destinationDetail: "Destination Detail",
    destination: "Destination",
    done: "Done",
    drivingTime: "Driving Time",
    destinationLocation: "Destination",
    data: "Data",
    draw: "Draw",
    daYouWantToReActive: "Do You Want To Re-Active?",
    dashCamera: "Dash Camera",
    deliveryItemPool: "Delivery Item Pool",
    deliveryItemDeleteHistoryPool: "Delivery Item Delete History Pool",
    deliveryItem: "Delivery Item",
    dataVisibility: "Data Visibility",
    disconnected: "Disconnected",
    dateOfBirth: "Date of Birth",
    drivinglicense: "Driving License",
    drivingIicenseNumber: "Driving License Number",
    drivingIiceneType: "Driving Licene Type",
    driverHistory: "Diver History",
    date: "Date",
    detailOfConcreteTruck: "Detail Of Concrete Truck",
    drumCapacity: "Drum Capacity",
    dischargeDenominator: "Discharge Denominator",
    dataForDLT: "Data For DLT",
    driver2: "Driver 2",
    driver1: "Driver 1",
    DrivingdistanceReport: "Driving Distance Report",
    DistfromlastmaintenanceReport: "Distfromlastmaintenance Report",
    duration: "Duration",
    DriverinformationReport: "Driverinformation Report",
    distance_more_than: "Total distance is considered work/day (km)",
    DrivinglicensescanlogReport: "Driving license scan log Report",
    DrivinglogReport: "Driving Log Report",
    DozeoffriskReport: "Doze off risk Report",
    DeliveryReport: "Trip delivery Report",
    DailyReport: "Trip daily Report",
    distance_less_than: "Distance less than",
    DrivinghourReport: "Driving hour Report",
    deliver: "Deliver",
    DrivingoverlimitReport: "Driving over limit Report",
    dangerous: "Dangerous",
    detailproblem: "Detail Problem",
    doc_no: "Document No.",
    document: "Document",
    durationOfPTOGate1On: "Duration of PTO/Gate1 On",
    duration: "Duration",
    mdvrdownloadfile: "Download Video MDVR",
    download: "Download",
    detail_problem: "Detail problem (other)",
    deliverytrip: "Delivery Trip",
    Delivered: "Delivered",
    deliver_status: "Deliver status",
    destinationLocationcode: "Destination code",
    device: "Device",
    deliver_duration: "Start at - End at"
  },

  e: {
    eventEnd: "Event End",

    expand: "Expand",
    edit: "Edit",
    estFare: "Est. Fare",
    estTransitDuration: "Travel Duration",
    estTransitDistance: "Travel Distance",
    europeAmerican: "Europe/American",
    engineStatus: "Engine Status",
    engineOn: "Engine On",
    engineOnWithImg: "Engine On with Image",
    engineOff: "Engine Off",
    engineOnDesc: "GPS sending position while engine on.",
    engineOnWithImgDesc:
      "GPS sending position while engine on with Dash camera snapshot images.",
    engineOffDesc: "GPS sending position while engine off.",
    endLocationCode: "End Location Code",
    employeeID: "Employee ID",
    event: "Event",
    EarningReceivedSTATISTICS: "Earning/Received STATISTICS",
    earningType: "Earning Type",
    eventAlarm: "Event Alarm",
    etc: "Etc.",
    emergencyCase: "Emergency Case",
    emergencyStatus: "Emergency Status",
    engine: "Engine",
    endLocationName: "End Location Name",
    endDestinationCode: "End Destination Code",
    endDestinationName: "End Destination Name",
    email: "Email",
    endDate: "End Date",
    earningOverview: "Earning Overview",
    eventMap: "Event Map",
    expireDate: "Expire Date",
    endValidDate: "End Valid Date",
    emailVerification: "Email Verification",
    expense: "Expense",
    excel: "Excel",
    EventReport: "Event Report",
    engine_on_time_end_at: "Engine on time end at",
    engine_on_time_start_at: "Engine on time start at",
    engine_off_time_end_at: "Engine off time end at",
    engine_off_time_start_at: "Engine off time start at",
    efficiency_report_menu: "Efficiency",
    energy_report_menu: "Energy",
    export_date_at: "Issue date",
    engineOnDuration: "Engine-on duration",
    endAt: "End at",
    expenseBaht: "Expense (Baht)"
  },

  f: {
    fillPosition: "Fill Position",
    finished: "Finished",
    file: "File",
    fine: "Fine",
    fuel: "Fuel",
    firstName: "Firsname",
    facilityName: "Location Name",
    facilityName2: "Location Name2",
    facilityName3: "Location Name3",
    findDistance: "Find Distance",
    findSpace: "Find Space",
    fare: "Fare (฿)",
    findCustomer: "Search passenger",
    fillInFrom: "Fill In From",
    fillInfomation: "Fill Infomation",
    finish: "Finish",
    filter: "Filter",
    firmwareVersion: "Firmware Version",
    fuelType: "Fuel Type",
    flateNumber: "Flate Number",
    fuelTankSize: "Fuel Tank Size",
    fuelA: "Fuel A",
    fuelB: "Fuel B",
    fuelC: "Fuel C",
    fuelUsage: "Fuel Usage",
    fuelRate: "Fuel Rate",
    fullTank: "Full Tank",
    forgot_password: "Forgot Password",
    filter_type1: "First move out plant to last move in plant",
    filter_type2: "First engine on to last engine off",
    filter_type: "Filter type",
    first_dest: "Start Position",
    firstMaintenanceConditions: "First maintenance conditions",
    file_location: "File location",
    file_size: "File size"
  },

  g: {
    general: "General",
    getVehicles: "Get Vehicles!",
    groupByLocation: "Group By Location",
    groupVehicleType: "Vehicle Group/Type",
    group: "Group",
    gate1: "Door 1",
    gate2: "Door 2",
    gate3: "Door 3",
    generateMap: "Generate Map",
    gasStation: "Gas Station",
    gpsCertification: "GPS Certification",
    gpsCertRequest: "GPS Cert. Request",
    gpsCertRequestHistory: "GPS Cert. Request History",
    generalLocation: "General Location",
    greater_than: "Greater than",
    GpsstatusReport: "GPS Status Report",
    group_by: "Group by",
    group_idle: "Only Idle",
    group_speed: "Only Overspeed",
    general_report_menu: "General",
    greaterOrEqual: "Greater or equal",
    gps_update: "GPS Update"
  },

  h: {
    hour: "Hour",
    hotel: "Hotel",
    high: "High",
    home: "Home",
    hardwareID: "Hardware ID",
    header: "Header",
    horsePower: "Horse Power",
    height: "Height",
    hours: "Hours",
    help_menu: "Helps"
  },

  i: {
    itemSelected: "Select",
    itemLeft: "Not Select",
    ignoredAlert: "Ignored",
    iconDesc: "Icon Desc.",
    indian: "Indian",
    infomation: "Information",
    imageMustSmallerThan5MB: "Image Must Smaller Than 5MB!",
    internalOperation: "Internal Operation",
    issuedDate: "Issued Date",
    id: "ID",
    idleFuelRate: "Idle Fuel Rate",
    imageFromDashCamera: "Dash Camera",
    InsuranceReport: "Insurance Report",
    insurance_report_menu: "Insurance",
    invoice_number: "Invoice number",
    invoice_date: "Invoice Date",
    insure_report_menu: "Insure"
  },

  j: {
    jobDetail: "Job Detail",
    jobStatus: "Job Status",
    jobType: "Job Type",
    jobTypeSTATISTICS: "Job Type STATISTICS",
    jobId: "Job ID",
    joinDate: "Join Date",
    jobStartAt: "Job Start At"
  },

  k: {
    kmH: "km/h",
    keywordAtleast3character: "...Keyword At Least 2 Character",
    km: "km."
  },

  l: {
    limitSuddenlyBreakThreshold: "Suddenly Break Threshold",
    limitAggesiveAccelerationThreshold: "Aggesive Acceleration Threshold",
    limitDrivingAggesiveThreshold: "Driving Aggesive Threshold",
    limitSnoozeThreshold: "Snooze Threshold",
    limitUsingPhoneThreshold: "Using Phone Threshold",
    limitParking: "Limit Parking",
    load: "Load",
    loadStartAt: "Load Start At",
    loadAmount: "Load Amount",
    lastName: "Last Name",
    location: "Location",
    locationGroup: "Location Group",
    lookingForSomething: "Looking For Something?",
    lostConnectio: "Disconnected",
    lastRequestOn: "Latest Request At",
    low: "Low",
    logout: "Logout",
    login: "Login",
    locationCode: "Location Code",
    latitude: "Latitude",
    longitude: "Longitude",
    locationInformation: "Location Information",
    loadpoint: "Load Point",
    locationTemporary: "Location Temporary",
    link: "Link",
    latestDriver: "Latest Driver",
    lazada: "Lazada",
    length: "Length",
    limitDrivingHourTime: "Limit driving hour time (minutes)",
    locationGroupName: "Location Group Name",
    less_than: "Less than",
    LoginhistoryReport: "Login History Report",
    login_success: "Login success",
    login_fail: "Login Fail",
    LocationInSystem: "Location in system",
    Location: "Location",
    locationShareTo: "Share To",
    locationShareFrom: "Share From",
    lessOrEqual: "Less or equal",
    lastest_signal: "Lastest Pos"
  },

  m: {
    monthly: "Monthly",
    moisture: "Humidity",
    month: "Month",
    miss: "Miss",
    mrs: "Mrs.",
    mr: "Mr.",
    m: "m.",
    meter: "Meter",
    medium: "Medium",
    message: "Message",
    masterData: "Master data",
    min: "Min",
    mixingDrum: "Drum",
    map: "Map",
    mapView: "Map View",
    meterStatus: "Meter Status",
    monitoring: "Monitoring",
    match: "Match",
    maxDrumCapacity: "Max Drum Capacity",
    mileNo: "Mile No.",
    model: "Model",
    machineSerialNumber: "Machine Serial Number",
    meterSN: "Meter S/N",
    MaintenanceReport: "Maintenance Report",
    more_1_min: "> 1 min",
    more_5_mins: "> 5 mins",
    more_10_mins: "> 10 mins",
    more_5_sec: "> 5 secs",
    more_10_sec: "> 10 secs",
    more_30_sec: "> 30 secs",
    more_60_sec: "> 60 secs",
    Minimal: "Minimal",
    managementdevice_report_menu: "Management Devices",
    MaintenancedeviceconcludeReport: "Maintenance Device Report",
    maintenance_status: "Status",
    maintenanceConditions: "Maintenance Conditions",
    maintenance: "Maintenance",
    minute: "Minute",
    maintenance_type: "Maintenance type",
    move_in: "Move in",
    move_out: "Move out",
    maintenancedevice_report_menu: "Maintenance devices",
    manage_product: "Manage products"
  },

  n: {
    nameAndType: "Name & Type",
    notification: "Notification",
    notificationEndWhen: "Notification Close When",
    nightTime: "Night Time",
    notReadyForWork: "Not Ready For Work",
    nameTitle: "Name Title",
    newUserGroup: "New Usergroup",
    newRoute: "New Route",
    newUser: "New User",
    nearPlants: "Near Plants",
    noParking: "No Parking",
    newTicket: "New Ticket",
    nationalityZone: "Nationality Zone",
    newCustomer: "New Customer",
    notificationType: "Notification Type",
    nationality: "Nationality",
    name: "Fullname",
    new: "New",
    newJob: "New Job",
    no: "#",
    number: "Quantity",
    nounsCar: "Cars",
    next: "Next",
    noVehicleSelect: "No Selection",
    newLocation: "New Location",
    numberOfExpirationDates: "Number Of Expiration Dates",
    node: "Node",
    nodeName: "Node Name",
    nextRegisterDate: "Next Register Date",
    nearByLocation: "Nearby Location",
    nearbyPlants: "Nearby Plants",
    NumberofdaysworkedReport: "Number of days worked Report",
    number_of_days: "Number of days",
    no_filter: "No filter",
    numberVehiclegroup: "Quantity",
    nextMaintenanceConditions: "Next maintenance conditions"
  },

  o: {
    onProcess: "On Process",
    originToDestination: "Origin-Destination",
    ok: "Yes",
    overview: "Overview",
    other: "Other",
    obd: "OBD",
    or: "OR",
    openFilter: "Open Filter",
    overSpeedInNormalRoutes: "Speed Limited (General Route)",
    overdueSpeedInTheCommunityArea: "Speed Limited (Community Area)",
    overSpeedInTheJunction: "Speed Limited (Junction)",
    open: "Open",
    orderCode: "Order Code",
    operation: "Operation",
    ownerView: "Owner View",
    operator: "Osperator",
    online: "Online",
    offline: "Offline",
    vehicleOffline: "Vehicle is offline",
    okay: "OK",
    over_hour: "Driving over hour",
    ordinal_numbers: "Ordinal No."
  },

  p: {
    preventRePinLocationConfirm: "Location will lost when re pin?",
    preventReDrawLocationConfirm: "Location will lost when re draw?",
    parking: "Parking",
    policy: "Policy",
    plantArrivingTime: "Plant Arriving Time",
    plantCode: "Plant Code",
    productType: "Product Type",
    phoneNumber: "Phone Number",
    plateNo: "Plate No.",
    pickup: "Pick Up",
    pickupData: "Pick Up Date",
    passenger: "Passenger",
    pickUpTime: "Pick Up Time",
    pickUpLocation: "Pick Up Location",
    pickUpDetail: "Pick Up Detail",
    pickUpDateTime: "Pick Up At",
    previous: "Previous",
    pinDown: "Pinned",
    partiallyDamagedCard: "Card Can Not Be Used",
    pto: "PTO",
    personal: "Personal",
    partner: "Partner",
    photo: "Photo",
    privilegeฌroup: "Privilege Group",
    purchaseChannel: "Purchase Channel",
    pending: "Pending",
    PONumber: "P/O Number",
    pdfFilesUpTo5MBInSize: "PDF Files Up To 5MB In Size",
    po: "PO",
    picture: "Picture",
    pictureUpdate: "Picture Update",
    plant: "Plant",
    polyGonRadius: "Polygon Radius",
    parkingLot: "Parking Lot",
    pdf: "PDF",
    password: "Password",
    pleaseSelect: "Please Select",
    personalID: "Personal ID",
    plantFactory: "Plant/Factory",
    period: "Period",
    periodOfInsuranceYear: "Period Of Insurance (Year)",
    province: "Province",
    part_type: "Part",
    ParkingReport: "Parking Report",
    preview: "Preview",
    partnerCar: "Partner",
    publicKey: "Public key",
    partType: "Part Type",
    partgroup: "Part group",
    province: "Province",
    product_type: "Product type",
    product_name: "Product name",
    plan_start_deliver_at: "Plan start deliver at"
  },

  q: {
    quantity_liter: "Quantity (liter)",
    quantity: "Quantity"
  },

  r: {
    rainingTime: "Raining Time",
    replay: "Replay",
    replayDetail: "Replay Detail",
    received: "Received",
    reportSummarizingTheNumberOfOffSiteParking:
      "Report Summarizing The Number Of Off-Site Parking",
    reportCardSwipeBehaviorBeforeDrivingDaily:
      "Report Card Swipe Behavior Before Driving Daily",
    reportType: "Report Type",
    report: "Report",
    reActivate: "Re-Activate",
    reset: "Reset",
    routeRadius: "Route Radius",
    routeRadiusM: "Route Radius (m.)",
    routeName: "Route Name",
    restArea: "Rest Area",
    radiuns: "Radius",
    radiunsM: "Radius (m.)",
    remark: "Comment",
    remainingRequest: "Remaining Request",
    requestList: "Request List",
    require: "Require",
    rpm: "RPM",
    running: "Running",
    remark2: "Remark 2",
    remark1: "Remark 1",
    reminderData: "Reminder Date",
    reminderTime: "Reminder Time",
    remarkBooking: "Remark Booking",
    reportAlarmFrequencyClassifiedByDailyDriver:
      "Report alarm frequency Classified by daily driver",
    route: "Route",
    rfid: "RFID",
    refuelAt: "Refuel At",
    refuel: "Refuel",
    RefuelReport: "Refuel Report",
    RiskbehaviourReport: "Risk behaviour Report",
    risk: "Risk",
    round: "Round"
  },

  s: {
    speedKmPerHr: "Speed (km./h)",
    safety: "Safety",
    subType: "Sub Type",
    setting: "Setting",
    shipTo: "Ship To",
    siteCode: "Site Code",
    saveNext: "Save & Next",
    send: "Send",
    save: "Save",
    search: "search..",
    surplus: "Surplus",
    selected: "Selected",
    status: "Status",
    secondaryPhoneNumber: "2nd Phone Number",
    selectedVehicleAlready: "Selected Vehicle Already",
    southeastAsia: "Southeast Asia",
    submit: "Save",
    satellite: "Satellite",
    showTraffic: "Show Traffic",
    searchText: "Search Text",
    searchTextFromPlateNo: "Search Text From Plate No ...",
    speedRange: "Speed Range (km./h)",
    speedRangeNoKm: "Speed Range",
    slidingCard: "Card Swipe",
    speed: "Speed",
    support: "Support",
    someparts: "Some Parts",
    slideWrongCardType: "Invalid Card Type",
    speedLimited: "Speed Limited",
    startDate: "Start Date",
    sentBy: "Sent By",
    sender: "Sender",
    sendAsAGroupOfVehicles: "By Vehicle Group",
    sendSelectedVehicles: "By Selected Vehicle",
    sentSuccessfully: "Sent Successfully",
    sendFailure: "Send Failure",
    selectPlateNo: "Select Vehicle",
    selectAssignTo: "Select Assign To",
    selectCriticalityLevel: "Select Criticality Level",
    selectStatus: "Select Status",
    selectVehicleGroup: "Select Vehicle Group",
    selectNotificationChannel: "Select Notification Channel",
    smsNotifyTo: "SMS Notify To",
    startLocationName: "Start Location Name",
    startDestinationCode: "Start Destination Code",
    startDestinationName: "Start Destination Dame",
    setTheRoute: "Set The Route",
    startLocationCode: "Start Location Code",
    specialPrivilege: "Special Privilege",
    selectOne: "Select One",
    state: "State",
    shopee: "Shopee",
    salesChannels: "Sales Channels",
    selectFile: "Select File",
    simNumber: "SIM Number",
    startTrip: "Start Trip",
    sos: "sos",
    SCCOCarComparisonReport: "SCCO car comparison report",
    seenAll: "Seen All",
    shareTo: "Share To",
    shareFrom: "Share From",
    sharedAt: "Shared At",
    speedOverLimit: "Speed Over Limit",
    speedOverLimitByVehicleGroup: "Speed Over Limit By Vehicle Group",
    speedOverLimitByVehicleType: "Speed Over Limit By Vehicle Type",
    speedOverLimitByDriver: "Speed Over Limit By Driver",
    stopWithEngineOn: "Stop/Engine On",
    startValidDate: "Start Valid Date",
    setup: "Set Up",
    smallTruck: "Small Truck",
    shaft: "Shaft",
    statusVehicleVideo: "Status",
    searchPlants: "Search",
    status_moving: "Moving",
    status_parked: "Parked",
    status_engineoff: "Engine off",
    status_vehicle: "Vehicle status",
    select_event: "select event",
    SpeedoverlimitsummaryReport: "Speed over limit summary Report",
    SummaryKPIReport: "Summary KPI Report",
    startDeliveryAt: "Start Deliver At",
    startRefuel: "Start Refuel",
    start_daily_time: "Daily start time",
    second_dest: "End Position",
    ship_to: "Ship to",
    scan_date_at: "Uploaded Date",
    shareLocation: "Share location",
    startAt: " Start at",
    send_email: " Send email",
    Servicerepairdevice: "Repair inform",
    submit_name: "Submit name",
    submit_telno: "Submit telno",
    start_deliver_at: "Start Deliver At"
  },

  t: {
    ticket: "Ticket",
    title: "Title",
    today: "Today",
    thisMonth: "This Month",
    thisYear: "This Year",
    tool: "Tool",
    tools: "Tools",
    taxiMeter: "Taxi Meter",
    temperature: "Temperature",
    temperature1: "Temp. 1",
    temperature2: "Temp. 2",
    TheUsualRoute: "The Usual Route",
    time: "Time",
    toggleHeatMap: "Toggle Heat Map",
    trackingNo: "Tracking No.",
    total: "Total",
    totalEmergencyCase: "Total Emergency Case",
    typeLocation: "Type Location",
    theRoute: "Route",
    toSiteDistance: "To Site Distance",
    timeThatCanBeUsed: "Time That Can Be Used.",
    top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod:
      "Top 5 Alert Reports Of Each Type Divided By Monthly Time Period",
    theDriverIsIicenseReportHasExpired:
      "The Driver's Iicense Report Has Expired",
    theDayThatTheCarPulled: "The Day That The Car Pulled",
    trip: "Trip",
    tripStatus: "Trip Status",
    topCustomer: "Top Customer",
    topEarning: "Top Earning",
    type: "Type",
    tracking: "Tracking",
    traininglist: "Training List",
    trainingDate: "Training Date",
    trainer: "Trainer",
    tagID: "Tag ID",
    truckCategory: "Truck Category",
    truckType: "Truck Type",
    tire: "Tire",
    truckDriverMatching: "Truck Driver Matching",
    TruckusageReport: "Truck Usage Report",
    totalPrice: "Total Price",
    TemperatureReport: "Temperature Report",
    time_15: "15 mins",
    time_30: "30 mins",
    time_60: "1 hr",
    time_180: "3 hrs",
    time_300: "5 hrs",
    time_420: "7 hrs",
    time_720: "12 hrs",
    time_1440: "24 hrs",
    TrackingdataReport: "Tracking Data Report",
    type_report: "Type Report",
    type_trip: "By Trip",
    type_person: "By Person",
    type_duration: "By Duration",
    TriptemperatureReport: "Trip Temperature Report",
    TripsumbyvehReport: "Trip Summary Seperated By Vehicle Report",
    TruckengineonReport: "Truck engine on Report",
    TruckengineoffReport: "Truck engine off Report",
    TrucknotuseReport: "Truck not use Report",
    tripCode: "Trip Code",
    TripsummaryReport: "Trip Summary Report",
    TripsumbylocationReport: "Trip Summary By Location Report",
    trip_report_menu: "Trip",
    typeMaintenance: "Type",
    technician: "Technician",
    TruckcurrentlocationReport: "Current Address Report",
    time_period: "Time period",
    type_problem: "Type problem",
    trip_date: "Duration date trip",
    trip_duration: "Start at - End at"
  },

  u: {
    userClose: "User Closed",
    updatedAt: "Updated At",
    userProfile: "User Profile",
    unknown: "Unknown",
    userGroupPremission: "User Group Premision",
    updatePictures: "Update A Photo",
    updateLocation: "Update A Location",
    unassign: "Unassign",
    updatedBy: "Modified By",
    userName: "Username",
    user: "User",
    userGroup: "Usergroup",
    unMatch: "Un Match",
    upload: "Upload",
    userExpire: "User Expire",
    unitPrice: "Unit Price (Baht)"
  },

  v: {
    vnCode: "VN Code",
    vehicleAndDriver: "Vehicle & Driver",
    video: "Video",
    vehicleModel: "Vehicle Model",
    vehicleColor: "Vehicle Color",
    vehicleType: "Vehicle Type",
    vehicle: "Vehicle",
    vehicleID: "Vehicle ID",
    vehicleStatus: "Vehicle Status",
    view: "View",
    vehicleDetail: "Vehicle Detail",
    vehicleGroupName: "Vehicle Group Name",
    vehicleGroup: "Vehicle Group",
    vehicleSharing: "Vehicle Sharing",
    validDate: "Valid Date",
    vehicleRegisterType: "Vehicle Register Type",
    VelocitysummaryReport: "Velocity summary Report",
    vehicleCode: "Vehicle Code",
    VehicleperformanceconcludeReport: "Vehicle Performance Conclude Report",
    VehicleinareaReport: "Vehicle in area Report",
    video_type: "Video type",
    vehicle_type: "Vehicle type"
  },

  w: {
    week: "Week",
    weekly: "Weekly",
    warrantyEnd: "Warranty End",
    workOverTime: "Overtime",
    waitingConfirm: "Waiting Confirm",
    wigth: "Wigth",
    wheel: "Wheel",
    waitingForJob: "Waiting"
  },

  x: {},

  y: {
    youCanOonlyUploadPdfFile: "You Can Only Upload PDF File!",
    yearly: "Yearly",
    year: "year"
  },

  z: {
    zoomIn: "Zoom in",
    zoomOut: "Zoom out",
    zone: "Zone",
    zoneName: "Zone Name"
  },

  variable: {
    parking: "Parking",
    driving: "Driving",
    insurance: "Insurance",
    maintenance_vehicle: "Vehicle",
    safety: "Safety",
    prohibited_area: "Prohibited Area",
    abnormal_behavior: "Abnormal Behavior",
    driving_hour: "Driving Hour",
    speed: "Speed",
    high: "High",
    medium: "Medium",
    low: "Low",
    tire: "Tire",
    engine_oil: "Engine Oil",
    suspension: "Suspension",
    voluntary_motor_insurance: "Voluntary Motor Insurance",
    compulsory_motor_insurance: "Compulsory Motor Insurance"
  }
};

export { en };
