import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { ACCESSTOKEN } from "./../../constants/local_storage";
import * as actions from "./actions";
import history from "./../../lib/history";

function* loadBooking(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      vehicleVisibility,
      job_status,
      orderBy,
      orderType,
      filterObj,
      accessToken
    }
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getbooking`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        vehicleVisibility,
        job_status,
        orderBy,
        orderType,
        filterObj
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadBooking.success(res.data, page));
  } catch (err) {
    //1574160920
    var date = new Date(1574160920 * 1000);
    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime =
      hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
    console.log(formattedTime);
    // history.push("/");
    console.log(err.message);
    yield put(actions.loadBooking.failure(err));
  }
}

function* loadBookingDetail(action) {
  const {
    data: { id },
    cb
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getbookingdetail`,
      {
        id
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    cb(res.data[0]);

    yield put(actions.loadBookingDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadBookingDetail.failure());
  }
}

function* loadAutocomplete(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocomplete.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_API_ENDPOINT}/${url}/${companyID}/${queryString}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocomplete.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocomplete.failure());
  }
}

function* selectedAutocomplete(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocomplete.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocomplete.failure());
  }
}

function* loadBookingVehicle(action) {
  const {
    lat,
    lng,
    searchName,
    meterStatus,
    engineStatus,
    company_id,
    vehicle_visibility
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getvehicleformap`,
      {
        company_id,
        vehicle_visibility,
        pickup_lat: lat,
        pickup_lng: lng,
        searchName: searchName,
        meterStatus: meterStatus,
        engineStatus: engineStatus
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadBookingVehicle.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadBookingVehicle.failure());
  }
}

function* createBooking(action) {
  const {
    data: {
      jobType,
      title,
      firstname,
      lastname,
      nationality,
      phone_number,
      phone_number_2,
      passenger,
      pickup_date,
      pickup_time,
      reminder_date,
      reminder_time,
      pickup_text,
      pickup_remark,
      pickup_lat,
      pickup_lng,
      destination_text,
      destination_remark,
      destination_lat,
      destination_lng,
      device_id,
      company_id,
      user_id,
      booking_remark
    },
    history,
    handleActionChange,
    handleJobIdChange
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/createbooking`,
      {
        jobType,
        title,
        firstname,
        lastname: lastname == null ? "" : lastname,
        nationality,
        phone_number,
        phone_number_2,
        passenger,
        pickup_date,
        pickup_time,
        reminder_date,
        reminder_time,
        pickup_text,
        pickup_remark:
          typeof pickup_remark === "undefined" ? "" : pickup_remark,
        pickup_lat,
        pickup_lng,
        destination_text,
        destination_remark:
          typeof destination_remark === "undefined" ? "" : destination_remark,
        destination_lat,
        destination_lng,
        device_id,
        company_id,
        user_id,
        booking_remark:
          typeof booking_remark === "undefined" ? "" : booking_remark
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    history.push("/center_pool/detail/" + res.data);

    handleActionChange("edit");
    handleJobIdChange(res.data);
    yield put(actions.createBooking.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createBooking.failure());
  }
}

function* updateBooking(action) {
  const {
    data: {
      job_id,
      jobType,
      title,
      firstname,
      lastname,
      nationality,
      phone_number,
      phone_number_2,
      passenger,
      pickup_date,
      pickup_time,
      reminder_date,
      reminder_time,
      pickup_text,
      pickup_remark,
      pickup_lat,
      pickup_lng,
      destination_text,
      destination_remark,
      destination_lat,
      destination_lng,
      device_id,
      user_id,
      booking_remark
    },
    history,
    handleActionChange,
    handleJobIdChange
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/updatebooking`,
      {
        job_id,
        jobType,
        title,
        firstname,
        lastname: lastname == null ? "" : lastname,
        nationality,
        phone_number,
        phone_number_2,
        passenger,
        pickup_date,
        pickup_time,
        reminder_date,
        reminder_time,
        pickup_text,
        pickup_remark:
          typeof pickup_remark === "undefined" ? "" : pickup_remark,
        pickup_lat,
        pickup_lng,
        destination_text,
        destination_remark:
          typeof destination_remark === "undefined" ? "" : destination_remark,
        destination_lat,
        destination_lng,
        device_id,
        user_id,
        booking_remark:
          typeof booking_remark === "undefined" ? "" : booking_remark
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.updateBooking.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateBooking.failure());
  }
}

function* updateBookingStatus(action) {
  const {
    data: { device_id, job_id, status }
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/updatebookingdevice`,
      {
        device_id,
        job_id,
        status
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.updateBookingStatus.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateBookingStatus.failure());
  }
}

export default function* watchBookingPoolState() {
  yield all([
    takeEvery(actions.LOAD_AUTOCOMPLETE.REQUEST, loadAutocomplete),
    takeEvery(actions.LOAD_BOOKING_VEHICLE.REQUEST, loadBookingVehicle),
    takeEvery(actions.SELECTED_AUTOCOMPLETE.REQUEST, selectedAutocomplete),
    takeEvery(actions.CREATE_BOOKING.REQUEST, createBooking),
    takeEvery(actions.UPDATE_BOOKING_STATUS.REQUEST, updateBookingStatus),
    takeEvery(actions.LOAD_BOOKING.REQUEST, loadBooking),
    takeEvery(actions.LOAD_BOOKING_DETAIL.REQUEST, loadBookingDetail),
    takeEvery(actions.UPDATE_BOOKING.REQUEST, updateBooking)
  ]);
}

export {
  loadAutocomplete,
  selectedAutocomplete,
  createBooking,
  loadBookingVehicle,
  updateBookingStatus,
  loadBooking,
  loadBookingDetail,
  updateBooking
};
