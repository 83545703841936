import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";
import moment from "moment";

function* loadListVehicleMdvr(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: { list_vehicle_id }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getlistvehiclemdvr`,
      {
        vehicle_visibility,
        list_vehicle_id,
        company_id: COMPANY_ID,

      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadListVehicleMdvr.success(res.data));
  } catch (err) {
    yield put(actions.loadListVehicleMdvr.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getvehiclemdvr`,
      {
        vehicle_visibility,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}



export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_LISTVEHICLEMDVR.REQUEST,
      loadListVehicleMdvr
    ),
    takeEvery(actions.LOAD_VEHICLE_VIDEO.REQUEST, loadVehicle),

  ]);
}

export { loadListVehicleMdvr, loadVehicle };
