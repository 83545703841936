import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
//import { push } from "connected-react-router";

import * as actions from "./actions";
import moment from "moment";

function* loadTripsumbylocation(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const USER_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: { first_dest, second_dest, eventdate, type_file }
  } = action.payload;

  const date_start = moment(eventdate[0]["_d"]).format("DD/MM/YYYY HH:mm");
  const date_end = moment(eventdate[1]["_d"]).format("DD/MM/YYYY HH:mm");



  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_LOGIN}/service/report/tripsumbylocation`,
      {
        date_start,
        date_end,
        company_id: COMPANY_ID,
        user_id: USER_ID,
        type_file,
        vehicle_visibility,
        first_dest,
        second_dest,
      }
    );

    yield put(actions.loadTripsumbylocation.success(res.data));
    window.location = res.data.data;

  } catch (err) {
    yield put(actions.loadTripsumbylocation.failure());
  }
}


function* loadLocation(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const location_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "location_visibility"
  );

  const { data } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getlocation`,
      {
        company_id: COMPANY_ID,
        location_visibility: location_visibility,
        name: data
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadLocation.success(res.data));
  } catch (err) {
    yield put(actions.loadLocation.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_TRIPSUMBYLOCATION.REQUEST,
      loadTripsumbylocation
    ),
    takeEvery(
      actions.LOAD_LOCATION_TRIPSUMBYLOCATION.REQUEST,
      loadLocation
    ),

  ]);
}

export { loadTripsumbylocation, loadLocation };
