// etc
import watchUIState from "modules/ui/sagas";
import watchAuthState from "modules/auth/sagas";

// master data
import watchCustomerMasterDataState from "modules/master-data/customer/sagas";
import watchUsergroupMasterDataState from "modules/master-data/usergroup/sagas";
import watchVehiclepullsink from "modules/master-data/pullvehiclesink/sagas";
import watchPermissionMasterDataState from "modules/master-data/permission/sagas";
import watchDatavisibilityMasterDataState from "modules/master-data/datavisibility/sagas";
import watchPlaceMasterData from "modules/master-data/facility/sagas";
import watchUserMasterData from "modules/master-data/user/sagas";
import watchDataVehicleGroup from "modules/master-data/vehicleGroup/sagas";
import watchRouteMasterData from "modules/master-data/route/sagas";
import watchVehicle from "modules/master-data/vehicle/sagas";
import watchVerify from "modules/master-data/verifyemail/sagas";
import watchDeviceUser from "modules/master-data/deviceuser/sagas";
import watchVehicleSharingMasterDataState from "modules/master-data/vehicle-sharing/sagas";
// import watchPermissionMasterDataState from "modules/master-data/permission/sagas";
import watchLocationGroup from "modules/master-data/locationgroup/sagas";
import watchNearbyLocation from "modules/master-data/nearbylocation/sagas";
import watchZone from "modules/master-data/zone/sagas";
import watchRefuel from "modules/master-data/refuel/sagas";
import watchPartner from "modules/master-data/partner/sagas";
import watchLocationSharing from "modules/master-data/locations-sharing/sagas";
import watchPartType from "modules/master-data/parttype/sagas";
import watchPartGroup from "modules/master-data/partgroup/sagas";
import watchManagementMaintenance from "modules/master-data/maintenance/sagas";

// operation job
import watchGPSCertificationRequestState from "modules/operate-job/gps-certification/sagas";
import watchDeliveryItemMasterDataState from "modules/operate-job/delivery-item/sagas";
import watchDeliveryItemDeleteHistoryMasterDataState from "modules/operate-job/delivery-item-delete-history/sagas";

// help
import watchServicerepairdevice from "modules/help/servicerepairdevice/sagas";

// booking
import watchBookingPoolState from "modules/booking-pool/sagas";

// dashboard
import watchMonitoringState from "modules/monitoring/sagas";
import watchVideoMonitoring from "modules/monitoring/video/sagas";
import watchCashierState from "modules/cashier/sagas";
import watchMdvrdownloadfile from "modules/monitoring/mdvrdownloadfile/sagas";

//management
import watchDownloadcertificate from "modules/management/downloadcertificate/sagas";
import watchContactmanagement from "modules/management/contactmanagement/sagas";

// report
//import watchAlarmAlertByPlateno from "modules/reports/alarmalertbyplateno/sagas";
import watchAlarmAlertByPlatenoOnyear from "modules/reports/alarmalertbyplatenoonyear/sagas";
import watchAlarmAlertByDriver from "modules/reports/alarmalertbydriver/sagas";
import watchAlarmAlertByDurationtime from "modules/reports/alarmalertbydurationtime/sagas";
import watchDrivingbehavior from "modules/reports/drivingbehavior/sagas";
import watchDrivinglicensescanbehavior from "modules/reports/drivinglicensescanbehavior/sagas";
import watchSeparatevehiclestatus from "modules/reports/separatevehiclestatus/sagas";
import watchAlarmAlertFrequencyByDriver from "modules/reports/alarmalertfrequencybydriver/sagas";
import watchComparevehiclescco from "modules/reports/comparevehiclescco/sagas";
import watchAlertDriverLicense from "modules/reports/alertdriverlicense/sagas";
import watchParkingareaoutside from "modules/reports/parkingareaoutside/sagas";
import watchParking from "modules/reports/parking/sagas";
import watchDrivinglicensescanlog from "modules/reports/drivinglicensescanlog/sagas";
import watchSpeedoverlimitsummary from "modules/reports/speedoverlimitsummary/sagas";
import watchDrivinglog from "modules/reports/drivinglog/sagas";
import watchEvent from "modules/reports/event/sagas";
import watchSummarykpi from "modules/reports/summarykpi/sagas";
import watchLoginhistory from "modules/reports/loginhistory/sagas";
import watchGpsstatus from "modules/reports/gpsstatus/sagas";
import watchTrackingdata from "modules/reports/trackingdata/sagas";
import watchTruckusage from "modules/reports/truckusage/sagas";
import watchDrivingdistance from "modules/reports/drivingdistance/sagas";
import watchTemperature from "modules/reports/temperature/sagas";
import watchMaintenance from "modules/reports/maintenance/sagas";
import watchDistfromlastmaintenance from "modules/reports/distfromlastmaintenance/sagas";
import watchTruckcurrentlocation from "modules/reports/truckcurrentlocation/sagas";
import watchDriverinformation from "modules/reports/driverinformation/sagas";
import watchNumberofdaysworked from "modules/reports/numberofdaysworked/sagas";
import watchDozeoffrisk from "modules/reports/dozeoffrisk/sagas";
import watchDelivery from "modules/reports/delivery/sagas";
import watchTriptemperature from "modules/reports/triptemperature/sagas";
import watchTripsumbyveh from "modules/reports/tripsumbyveh/sagas";
import watchDaily from "modules/reports/daily/sagas";
import watchVelocitysummary from "modules/reports/velocitysummary/sagas";
import watchTruckengineon from "modules/reports/truckengineon/sagas";
import watchTruckengineoff from "modules/reports/truckengineoff/sagas";
import watchTrucknotuse from "modules/reports/trucknotuse/sagas";
import watchDrivinghour from "modules/reports/drivinghour/sagas";

import watchTripState from "modules/delivery-management/trip/sagas";
import watchDeliveryTripState from "modules/delivery-management/delivery-trip/sagas";

import watchTripsummary from "modules/reports/tripsummary/sagas";
import watchDrivingoverlimit from "modules/reports/drivingoverlimit/sagas";
import watchTripsumbylocation from "modules/reports/tripsumbylocation/sagas";
import watchInsurance from "modules/reports/insurance/sagas";
import watchVehicleperformanceconclude from "modules/reports/vehicleperformanceconclude/sagas";
import watchReportRefuel from "modules/reports/refuel/sagas";
import watchRiskbehaviour from "modules/reports/riskbehaviour/sagas";
import watchVehicleinarea from "modules/reports/vehicleinarea/sagas";
import watchMaintenancedeviceconclude from "modules/reports/maintenancedeviceconclude/sagas";
import watchTruckinspectionbyvendor from "modules/reports/truckinspectionbyvendor/sagas";
import watchTruckinspectionbytruck from "modules/reports/truckinspectionbytruck/sagas";
import watchSummaryScoreByVendor from "modules/reports/summaryscorebyvendor/sagas";
import watchTruckinspectionbyvendoragg from "modules/reports/truckinspectionbyvendoragg/sagas";
import watchSummaryScoreByVendoragg from "modules/reports/summaryscorebyvendoragg/sagas";
import watchTruckinspectionMaintenance from "modules/reports/truckinspectionmaintenance/sagas";
import watchTruckinspectionByMonth from "modules/reports/truckinspectionbymonth/sagas";

import watchPolicyState from "modules/setting/policy/sagas";

//import watchTruckusage from "modules/reports/MoveReport/Truckusage/sagas";
//import watchDrivingdistance from "modules/reports/MoveReport/drivingdistance/sagas";

import { fork, all } from "redux-saga/effects";

export default function* rootSagas() {
  yield all([
    fork(watchUIState),
    fork(watchBookingPoolState),
    fork(watchAuthState),
    fork(watchMonitoringState),
    fork(watchCashierState),
    fork(watchCustomerMasterDataState),
    //fork(watchAlarmAlertByPlateno),
    fork(watchTruckinspectionbyvendor),
    fork(watchTruckinspectionbytruck),
    fork(watchSummaryScoreByVendor),
    fork(watchTruckinspectionbyvendoragg),
    fork(watchSummaryScoreByVendoragg),
    fork(watchTruckinspectionMaintenance),
    fork(watchTruckinspectionByMonth),
    fork(watchAlarmAlertByPlatenoOnyear),
    fork(watchGPSCertificationRequestState),
    fork(watchAlarmAlertByDriver),
    fork(watchAlarmAlertByDurationtime),
    fork(watchDrivingbehavior),
    fork(watchDrivinglicensescanbehavior),
    fork(watchSeparatevehiclestatus),
    fork(watchComparevehiclescco),
    fork(watchAlertDriverLicense),
    fork(watchParkingareaoutside),
    fork(watchUsergroupMasterDataState),
    fork(watchDeliveryItemMasterDataState),
    fork(watchDeliveryItemDeleteHistoryMasterDataState),
    fork(watchVehiclepullsink),
    fork(watchSpeedoverlimitsummary),
    fork(watchSummarykpi),
    fork(watchPermissionMasterDataState),
    fork(watchDatavisibilityMasterDataState),
    fork(watchPlaceMasterData),
    fork(watchUserMasterData),
    fork(watchDataVehicleGroup),
    fork(watchRouteMasterData),
    fork(watchVehicle),
    fork(watchVehicleSharingMasterDataState),
    fork(watchAlarmAlertFrequencyByDriver),
    fork(watchParking),
    fork(watchLoginhistory),
    fork(watchGpsstatus),
    fork(watchTrackingdata),
    fork(watchVideoMonitoring),
    fork(watchTruckusage),
    fork(watchDrivingdistance),
    fork(watchDrivinglicensescanlog),
    fork(watchDrivinglog),
    fork(watchEvent),
    fork(watchVerify),
    fork(watchDeviceUser),
    fork(watchLocationGroup),
    fork(watchNearbyLocation),
    fork(watchZone),
    fork(watchRefuel),
    fork(watchDrivingdistance),
    fork(watchTemperature),
    fork(watchMaintenance),
    fork(watchDistfromlastmaintenance),
    fork(watchTruckcurrentlocation),
    fork(watchDriverinformation),
    fork(watchNumberofdaysworked),
    fork(watchDozeoffrisk),
    fork(watchDelivery),
    fork(watchTriptemperature),
    fork(watchTripsumbyveh),
    fork(watchDaily),
    fork(watchVelocitysummary),
    fork(watchTruckengineon),
    fork(watchTruckengineoff),
    fork(watchTrucknotuse),
    fork(watchDrivinghour),
    fork(watchTripsummary),
    fork(watchDrivingoverlimit),
    fork(watchTripsumbylocation),
    fork(watchInsurance),
    fork(watchVehicleperformanceconclude),
    fork(watchReportRefuel),
    fork(watchRiskbehaviour),
    fork(watchPartner),
    fork(watchLocationSharing),
    fork(watchVehicleinarea),
    fork(watchMaintenancedeviceconclude),
    fork(watchDownloadcertificate),
    fork(watchPolicyState),
    fork(watchTripState),
    fork(watchPartType),
    fork(watchPartGroup),
    fork(watchManagementMaintenance),
    fork(watchContactmanagement),
    fork(watchMdvrdownloadfile),
    fork(watchServicerepairdevice),
    fork(watchDeliveryTripState)

  ]);
}
