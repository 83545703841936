import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID
} from "./../../../constants/local_storage";
import * as actions from "./actions";

function* loadVehicleSharingFrom(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      filterObj,
      orderBy,
      orderType
    }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/vehicle_sharing/shared_from`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        filterObj,
        orderBy,
        orderType
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadVehicleSharingFrom.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleSharingFrom.failure());
  }
}

function* loadVehicleSharingTo(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      filterObj,
      orderBy,
      orderType
    }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/vehicle_sharing/shared_to`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        filterObj,
        orderBy,
        orderType
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadVehicleSharingTo.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleSharingTo.failure());
  }
}

function* loadVehicleSharingToDetail(action) {
  const {
    data: { id }
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/vehicle_sharing/detail/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    // cb(res.data[0]);
    // console.log(res.data.data);
    yield put(actions.loadVehicleSharingToDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleSharingToDetail.failure());
  }
}

function* createVehicleSharing(action) {
  const {
    data: { partner, company_id, vehicle, allow_shared_partner_edit },
    cb
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/vehicle_sharing/create`,
      {
        partner,
        company_id,
        vehicle_list: vehicle,
        allow_shared_partner_edit
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    cb(res.data);
    yield put(actions.createVehicleSharing.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createVehicleSharing.failure());
  }
}

export default function* watchVehicleSharingMasterDataState() {
  yield all([
    takeEvery(actions.CREATE_VEHICLESHARING.REQUEST, createVehicleSharing),
    takeEvery(actions.LOAD_VEHICLESHARING_TO.REQUEST, loadVehicleSharingTo),
    takeEvery(actions.LOAD_VEHICLESHARING_FROM.REQUEST, loadVehicleSharingFrom),
    takeEvery(
      actions.LOAD_VEHICLESHARING_TO_DETAIL.REQUEST,
      loadVehicleSharingToDetail
    )
  ]);
}

export {
  createVehicleSharing,
  loadVehicleSharingTo,
  loadVehicleSharingFrom,
  loadVehicleSharingToDetail
};
