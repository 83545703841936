import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadMasterUser(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      is_super
    }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/masterDataUser`,
      // `http://booking-api/api/customer`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
        is_super
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadMasterUser.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadMasterUser.failure());
  }
}

function* createMasterUser(action) {
  const {
    data: {
      company_id,
      title,
      code,
      firstname,
      lastname,
      password,
      username,
      email,
      phone,
      is_admin,
      active,
      start_at,
      expired_at,
      created_by,
      permissions,
      accessToken,
      dataVisibilityNode
    }
  } = action.payload;
  // console.log(dataVisibilityNode)
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/masterDataUser/add`,
      // `${DEV_API_ENDPOINT}/customer/create`,
      {
        company_id,
        title,
        code,
        firstname,
        lastname,
        username,
        password,
        email,
        phone,
        is_admin,
        active,
        start_at,
        expired_at,
        created_by,
        permissions,
        dataVisibilityNode
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.createMasterUser.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createMasterUser.failure());
  }
}

function* loadDetailMasterUser(action) {
  const {
    data: { id, accessToken }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/user/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadDetailMasterUser.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDetailMasterUser.failure());
  }
}

function* loadDetailMasterDataForEdit(action) {
  const {
    data: { id, accessToken }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/masterdatauser/detail/edit/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadDetailMasterDataForEdit.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDetailMasterDataForEdit.failure());
  }
}

function* updateMasterDataUser(action) {
  const {
    data: {
      id_m_user,
      company_id,
      code,
      title,
      firstname,
      lastname,
      username,
      password,
      email,
      is_admin,
      active,
      start_at,
      expired_at,
      created_by,
      permissions,
      phone,
      dataVisibility,
      accessToken
    }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/masterdatauser/edit`,
      // `${DEV_API_ENDPOINT}/customer/create`,
      {
        id_m_user,
        company_id,
        title,
        code,
        firstname,
        lastname,
        username,
        password,
        email,
        phone,
        is_admin,
        active,
        start_at,
        expired_at,
        created_by,
        permissions,
        dataVisibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.updateMasterDataUser.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateMasterDataUser.failure());
  }
}

export default function* watchUserMasterData() {
  yield all([
    takeEvery(actions.LOAD_MASTERDATA_USER.REQUEST, loadMasterUser),
    takeEvery(actions.CREATE_MASTERDATA_USER.REQUEST, createMasterUser),
    takeEvery(actions.LOAD_DETAILMASTERDATA_USER.REQUEST, loadDetailMasterUser),
    takeEvery(
      actions.LOAD_DETAILMASTERDATA_FOR_EDIT.REQUEST,
      loadDetailMasterDataForEdit
    ),
    takeEvery(actions.UPDATE_MASTERDATA_USER.REQUEST, updateMasterDataUser)
  ]);
}
export {
  loadMasterUser,
  createMasterUser,
  loadDetailMasterUser,
  loadDetailMasterDataForEdit,
  updateMasterDataUser
};
