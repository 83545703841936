import { CALL_API } from "middleware/api";
import history from "lib/history";
import { createTypes, createAction } from "lib/action";

const CHECK_LOGIN = createTypes("login", "check");

const BASE_ACTION = "app/auth";

const LOAD_CREDENTIALS = `${BASE_ACTION}/credentials/load`;
const REMOVE_CREDENTIALS = `${BASE_ACTION}/credentials/remove`;
const CREATE_USER_REQUEST = `${BASE_ACTION}/register/request`;
const CREATE_USER_SUCCESS = `CREATE_USER_SUCCESS`;
const CREATE_USER_FAILURE = `/register/failure`;
const SET_CONFIG_TRACKING_COLUMNS = `${BASE_ACTION}/config/set/trackingcol`;
const SET_CONFIG_HOME_DASHBOARD_WIDGET = `${BASE_ACTION}/config/set/homedashboard/widget/position`;

const asyncLocalStorage = {
  setItem: function(key, value) {
    return Promise.resolve().then(function() {
      localStorage.setItem(key, value);
    });
  },
  getItem: function(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key);
    });
  }
};

function loadCredentials() {
  const token = localStorage.getItem("access-token");
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");

  let currentTime = null;
  let tokenExp = null;
  let done = false;
  let msg = "";

  if (JSON.stringify(profile) !== "{}") {
    done = true;
    tokenExp = profile.exp;

    currentTime = Math.floor(Date.now() / 1000);
    if (currentTime > tokenExp) {
      done = false;
      msg = "Token Expired";
    }
  }

  return {
    type: LOAD_CREDENTIALS,
    payload: { token, tokenExp, profile, done, msg }
  };
}

function removeCredentials() {
  localStorage.removeItem("access-token");
  localStorage.removeItem("profile");
  return {
    type: REMOVE_CREDENTIALS
  };
}

function storeCredentials(
  token,
  profile,
  permissions,
  vehicle_list,
  location_list,
  vehicle_group_list
) {
  return asyncLocalStorage.setItem("access-token", token).then(function() {
    profile = {
      ...profile,
      permissions,
      vehicle_visibility: vehicle_list,
      location_visibility: location_list,
      vehicle_group_visibility: vehicle_group_list
    };

    return asyncLocalStorage
      .setItem("profile", JSON.stringify(profile))
      .then(function(value) {
        return loadCredentials();
      });
  });

  // localStorage.setItem("access-token", token);
  // localStorage.setItem("profile", JSON.stringify(profile));
  // loadCredentials();
}

function register(credentials) {
  return {
    [CALL_API]: {
      endpoint: "/users",
      method: "POST",
      body: credentials,
      types: [
        CREATE_USER_REQUEST,
        {
          type: CREATE_USER_SUCCESS,
          payload: async (_action, _state, res) => {
            const token = res.headers.get("Authorization");
            const { user: profile } = await res.json();

            storeCredentials(token, profile);
            history.push("/");

            return {
              token,
              profile
            };
          }
        },
        CREATE_USER_FAILURE
      ]
    }
  };
}

const checkLogin = {
  request: credentials => createAction(CHECK_LOGIN.REQUEST, { credentials }),
  success: (
    token,
    tokenPayload,
    permissions,
    vehicle_list,
    location_list,
    vehicle_group_list
  ) =>
    createAction(CHECK_LOGIN.SUCCESS, {
      token,
      tokenPayload,
      permissions,
      vehicle_list,
      location_list,
      vehicle_group_list
    }),
  failure: (status, msg) => createAction(CHECK_LOGIN.FAILURE, { status, msg })
};

function changeConfigTrackingColumns(columns) {
  return {
    type: SET_CONFIG_TRACKING_COLUMNS,
    payload: columns
  };
}

function changeConfigHomeDashboardLayout(layout) {
  return {
    type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
    payload: layout
  };
}

function changeLocalStorageConfigHomeDashboardLayout(profile, layout) {
  const token = localStorage.getItem("access-token");
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({ ...profile.config, home_dashboard: { layout } })
    };

    localStorage.setItem("profile", JSON.stringify(profile));

    return {
      type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
      payload: layout
    };
  }
  return {
    type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
    payload: []
  };
}

export {
  LOAD_CREDENTIALS,
  REMOVE_CREDENTIALS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  loadCredentials,
  storeCredentials,
  removeCredentials,
  register,
  CHECK_LOGIN,
  checkLogin,
  SET_CONFIG_TRACKING_COLUMNS,
  changeConfigTrackingColumns,
  SET_CONFIG_HOME_DASHBOARD_WIDGET,
  changeConfigHomeDashboardLayout,
  changeLocalStorageConfigHomeDashboardLayout
};
