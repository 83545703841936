import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";
import moment from "moment";

function* loadAlarmAlertFrequrncyByDriver(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const {
    data: { list_driver_id, alarmalert_type_id, eventdate }
  } = action.payload;

  const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD");
  const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD");
  const configAlarmType = action.payload.dataAlarm;
  // console.log(configAlarmType);
  // console.log(date_start);
  // console.log(date_end);

  try {
    const res = yield call(
      axios.post,
      // `${process.env.REACT_APP_API_ENDPOINT}/alarmalertbydriver`,
      `${process.env.REACT_APP_API_ENDPOINT}/alarmalertfrequencybydriver`,
      {
        date_start,
        date_end,
        list_driver_id,
        company_id: COMPANY_ID,
        alarmalert_type_id,
        configAlarmType
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadAlarmAlertFrequrncyByDriver.success(res.data));
    window.location = res.data.data;
  } catch (err) {
    yield put(actions.loadAlarmAlertFrequrncyByDriver.failure());
  }
}

function* loadDriver(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const VUC = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_under_control"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getdriver`,
      {
        allow_vehicles: VUC,
        company_id: COMPANY_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadDriver.success(res.data));
  } catch (err) {
    yield put(actions.loadDriver.failure());
  }
}

export default function* watchAlarmAlertFrequencyByDriver() {
  yield all([
    takeEvery(actions.LOAD_ALARMALERTBYDRIVER.REQUEST, loadAlarmAlertFrequrncyByDriver),
    takeEvery(actions.LOAD_DRIVER.REQUEST, loadDriver)
  ]);
}

export { loadAlarmAlertFrequrncyByDriver, loadDriver };
