import React, { Component, Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  withRouter
} from "react-router-dom";
// import { Connector } from "mqtt-react";
import { Layout as AppLayout, Alert, Modal } from "antd";
import { connect } from "react-redux";
import loadable from "@loadable/component";

import history from "lib/history";
import "../../../styled/common.css";
import * as authActions from "modules/auth/actions";
import * as uiActions from "modules/ui/actions";
import PrivateRoute from "./PrivateRoute";
import Header from "./Header";
import LangContext, { i18n } from "modules/shared/context/langContext";
import SliderBar from "./SliderBar";
import { askForSoundPlayingText } from "../../../constants/constants";
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
////////                      Site Page                           ////////
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////

import Login from "modules/auth/components/Login";

import Deliverytrip from "modules/delivery-management/delivery-trip/components/Trip";
import Servicerepairdevice from "modules/help/servicerepairdevice/components/Servicerepairdevice";
import Contactmanagement from "modules/management/contactmanagement/components/Contactmanagement";
import Mdvrdownloadfile from "modules/monitoring/mdvrdownloadfile/components/Mdvrdownloadfile";
import HomeDashboard from "modules/home-dashboard/index/components/HomeDashboard";

// import Policy from "modules/setting/policy/components/Policy";

// report
const Alarmalertbyplateno = loadable(() =>
  import("modules/reports/alarmalertbyplateno/components/Alarmalert")
);

const Truckinspectionbytruck = loadable(() =>
  import(
    "modules/reports/truckinspectionbytruck/components/Truckinspectionbytruck"
  )
);

const Truckinspectionbyvendor = loadable(() =>
  import(
    "modules/reports/truckinspectionbyvendor/components/Truckinspectionbyvendor"
  )
);

const Summaryscorebyvendor = loadable(() =>
  import("modules/reports/summaryscorebyvendor/components/Summaryscorebyvendor")
);

const Truckinspectionbyvendoragg = loadable(() =>
  import(
    "modules/reports/truckinspectionbyvendoragg/components/Truckinspectionbyvendoragg"
  )
);

const Summaryscorebyvendoragg = loadable(() =>
  import(
    "modules/reports/summaryscorebyvendoragg/components/Summaryscorebyvendoragg"
  )
);

const Truckinspectionmaintenance = loadable(() =>
  import(
    "modules/reports/truckinspectionmaintenance/components/Truckinspectionmaintenance"
  )
);

const Truckinspectionbymonth = loadable(() =>
  import(
    "modules/reports/truckinspectionbymonth/components/Truckinspectionbymonth"
  )
);

const Alarmalertbyplatenoonyear = loadable(() =>
  import("modules/reports/alarmalertbyplatenoonyear/components/Alarmalert")
);
const Alarmalertbydriver = loadable(() =>
  import("modules/reports/alarmalertbydriver/components/Alarmalert")
);
const Alarmalertbydurationtime = loadable(() =>
  import("modules/reports/alarmalertbydurationtime/components/Alarmalert")
);
const Drivingbehavior = loadable(() =>
  import("modules/reports/drivingbehavior/components/Alarmalert")
);
const Drivinglicensescanbehavior = loadable(() =>
  import(
    "modules/reports/drivinglicensescanbehavior/components/Drivinglicensescan"
  )
);
const Separatevehiclestatus = loadable(() =>
  import("modules/reports/separatevehiclestatus/components/Routereport")
);
const Comparevehiclescco = loadable(() =>
  import("modules/reports/comparevehiclescco/components/Comparevehiclescco")
);
const Alertdriverlicense = loadable(() =>
  import("modules/reports/alertdriverlicense/components/Alert")
);
const Parkingareaoutside = loadable(() =>
  import("modules/reports/parkingareaoutside/components/Parking")
);
const Parking = loadable(() =>
  import("modules/reports/parking/components/Parking")
);
const Drivinglicensescanlog = loadable(() =>
  import(
    "modules/reports/drivinglicensescanlog/components/Drivinglicensescanlog"
  )
);
const Speedoverlimitsummary = loadable(() =>
  import(
    "modules/reports/speedoverlimitsummary/components/Speedoverlimitsummary"
  )
);
const Drivinglog = loadable(() =>
  import("modules/reports/drivinglog/components/Drivinglog")
);
const Event = loadable(() => import("modules/reports/event/components/Event"));
const Summarykpi = loadable(() =>
  import("modules/reports/summarykpi/components/Summarykpi")
);
const AlarmalertbydriverNewformet = loadable(() =>
  import(
    "modules/reports/alarmalertfrequencybydriver/components/AlarmalertNewformet"
  )
);
const Loginhistory = loadable(() =>
  import("modules/reports/loginhistory/components/Loginhistory")
);
const Gpsstatus = loadable(() =>
  import("modules/reports/gpsstatus/components/Gpsstatus")
);

const Trackingdata = loadable(() =>
  import("modules/reports/trackingdata/components/Trackingdata")
);

const Truckusage = loadable(() =>
  import("modules/reports/truckusage/components/Truckusage")
);

const Drivingdistance = loadable(() =>
  import("modules/reports/drivingdistance/components/Drivingdistance")
);

const Temperature = loadable(() =>
  import("modules/reports/temperature/components/Temperature")
);

const Maintenance = loadable(() =>
  import("modules/reports/maintenance/components/Maintenance")
);

const Distfromlastmaintenance = loadable(() =>
  import(
    "modules/reports/distfromlastmaintenance/components/Distfromlastmaintenance"
  )
);

const Truckcurrentlocation = loadable(() =>
  import("modules/reports/truckcurrentlocation/components/Truckcurrentlocation")
);

const Driverinformation = loadable(() =>
  import("modules/reports/driverinformation/components/Driverinformation")
);

const Numberofdaysworked = loadable(() =>
  import("modules/reports/numberofdaysworked/components/Numberofdaysworked")
);

const Dozeoffrisk = loadable(() =>
  import("modules/reports/dozeoffrisk/components/Dozeoffrisk")
);

const Delivery = loadable(() =>
  import("modules/reports/delivery/components/Delivery")
);

const Tripsumbyveh = loadable(() =>
  import("modules/reports/tripsumbyveh/components/Tripsumbyveh")
);

const Triptemperature = loadable(() =>
  import("modules/reports/triptemperature/components/Triptemperature")
);

const Daily = loadable(() => import("modules/reports/daily/components/Daily"));

const Velocitysummary = loadable(() =>
  import("modules/reports/velocitysummary/components/Velocitysummary")
);

const Truckengineon = loadable(() =>
  import("modules/reports/truckengineon/components/Truckengineon")
);

const Truckengineoff = loadable(() =>
  import("modules/reports/truckengineoff/components/Truckengineoff")
);

const Trucknotuse = loadable(() =>
  import("modules/reports/trucknotuse/components/Trucknotuse")
);

const Drivinghour = loadable(() =>
  import("modules/reports/drivinghour/components/Drivinghour")
);

const Tripsummary = loadable(() =>
  import("modules/reports/tripsummary/components/Tripsummary")
);

const Drivingoverlimit = loadable(() =>
  import("modules/reports/drivingoverlimit/components/Drivingoverlimit")
);

const Tripsumbylocation = loadable(() =>
  import("modules/reports/tripsumbylocation/components/Tripsumbylocation")
);

const Insurance = loadable(() =>
  import("modules/reports/insurance/components/Insurance")
);

const Vehicleperformanceconclude = loadable(() =>
  import(
    "modules/reports/vehicleperformanceconclude/components/Vehicleperformanceconclude"
  )
);

const Refuel = loadable(() =>
  import("modules/reports/refuel/components/Refuel")
);

const Riskbehaviour = loadable(() =>
  import("modules/reports/riskbehaviour/components/Riskbehaviour")
);

const Vehicleinarea = loadable(() =>
  import("modules/reports/vehicleinarea/components/Vehicleinarea")
);

const Maintenancedeviceconclude = loadable(() =>
  import(
    "modules/reports/maintenancedeviceconclude/components/Maintenancedeviceconclude"
  )
);

//management
const Downloadcertificate = loadable(() =>
  import(
    "modules/management/downloadcertificate/components/Downloadcertificate"
  )
);

// const Drivingdistance = loadable(() =>
//   import("modules/reports/MoveReport/drivingdistance/components/DrivingdistanceOverview")
// );

// const Truckusage = loadable(() =>
//   import("modules/reports/truckusage/components/Truckusage")
// );

// notification
const NotificationCenter = loadable(() =>
  import("modules/ui/components/NotificationCenter")
);
// dashboard
const Tracking = loadable(() =>
  import("modules/monitoring/tracking/components/Tracking")
);
const DashCameras = loadable(() =>
  import("modules/monitoring/dash-cameras/components/DashCameras")
);
const AlertAlarm = loadable(() =>
  import("modules/monitoring/alert-alarm/components/AlertAlarm")
);

const BookingOverview = loadable(() =>
  import("modules/monitoring/booking-overview/components/BookingOverview")
);
const CashingOverview = loadable(() =>
  import("modules/monitoring/cashing-overview/components/CashingOverview")
);
const EventMap = loadable(() =>
  import("modules/monitoring/event-map/components/EventMap")
);
const ConcreteDelivery = loadable(() =>
  import("modules/monitoring/concrete-delivery/components/ConcreteDelivery")
);

const Replay = loadable(() =>
  import("modules/monitoring/replay/components/Replay")
);

const Video = loadable(() =>
  import("modules/monitoring/video/components/Video")
);

const Trip = loadable(() =>
  import("modules/delivery-management/trip/components/Trip")
);

const Policy = loadable(() =>
  import("modules/setting/policy/components/Policy")
);

// master data
const Customer = loadable(() =>
  import("modules/master-data/customer/components/Customer")
);
const Usergroup = loadable(() =>
  import("modules/master-data/usergroup/components/Usergroup")
);
const Pullvehiclesink = loadable(() =>
  import("modules/master-data/pullvehiclesink/components/Pullvehiclesink")
);
const Permission = loadable(() =>
  import("modules/master-data/permission/components/Permission")
);
const Datavisibility = loadable(() =>
  import("modules/master-data/datavisibility/components/Datavisibility")
);
const VehicleGroupOwerview = loadable(() =>
  import("modules/master-data/vehicleGroup/components/VehicleGroupOwerview")
);
const RouteOverview = loadable(() =>
  import("modules/master-data/route/components/RouteOverview")
);
const VehicleSharing = loadable(() =>
  import("modules/master-data/vehicle-sharing/components/VehicleSharing")
);
const Facility = loadable(() =>
  import("modules/master-data/facility/components/FacilityOverviwe")
);
const Vehicle = loadable(() =>
  import("modules/master-data/vehicle/components/VehicleOverview")
);
const DriverUser = loadable(() =>
  import("modules/master-data/deviceuser/components/DeviceUserOverview")
);
const LocationGroup = loadable(() =>
  import("modules/master-data/locationgroup/components/LocationGroupOverview")
);
const NearbyLocation = loadable(() =>
  import("modules/master-data/nearbylocation/componants/NearByLocationOverview")
);
const Zone = loadable(() =>
  import("modules/master-data/zone/components/ZoneOverview")
);
const User = loadable(() =>
  import("modules/master-data/user/components/UserOverview")
);
const Verifyemail = loadable(() =>
  import("modules/master-data/verifyemail/components/verifyemailOverview")
);
const Refeul = loadable(() =>
  import("modules/master-data/refuel/components/RefuelOverview")
);
const Partner = loadable(() =>
  import("modules/master-data/partner/components/PartnerOverview")
);
const LocationSharing = loadable(() =>
  import(
    "modules/master-data/locations-sharing/components/LocationSharingOverview"
  )
);
const PartType = loadable(() =>
  import("modules/master-data/parttype/components/PartTypeOverview")
);
const PartGroup = loadable(() =>
  import("modules/master-data/partgroup/components/PartGroupOverview")
);
const ManagementMaintenance = loadable(() =>
  import("modules/master-data/maintenance/components/MainntenencsOverview")
);
// const ManagementMaintenance = loadable(() =>
//   import("modules/master-data/maintenance/components/MaintenanceOverview")
// );

// booking
const CenterPool = loadable(() =>
  import("modules/booking-pool/center-pool/components/CenterPool")
);
const FinishedPool = loadable(() =>
  import("modules/booking-pool/finished-pool/components/FinishedPool")
);
const CancelledPool = loadable(() =>
  import("modules/booking-pool/cancelled-pool/components/CancelledPool")
);
const CashReceived = loadable(() =>
  import("modules/cashier/cash-received/components/CashReceived")
);
const ReceiveHistory = loadable(() =>
  import("modules/cashier/receive-history/components/ReceiveHistory")
);

// operation job
const GPSCertificationRequest = loadable(() =>
  import(
    "modules/operate-job/gps-certification/gps-certification-request/components/GPSCertificationRequest"
  )
);
const GPSCertificationRequestHistory = loadable(() =>
  import(
    "modules/operate-job/gps-certification/gps-certification-request-history/components/GPSCertificationRequestHistory"
  )
);
const Announcement = loadable(() =>
  import(
    "modules/operate-job/announcement/announcement-broadcast/components/Announcement"
  )
);
const DeliveryItem = loadable(() =>
  import("modules/operate-job/delivery-item/components/DeliveryItem")
);
const DeliveryItemDeleteHistory = loadable(() =>
  import(
    "modules/operate-job/delivery-item-delete-history/components/DeliveryItemDeleteHistory"
  )
);

class Layout extends Component {
  state = {
    collapsed: true,
    pageReload: false,
    notiCount: 0,
    haveNewNoti: false,
    i18n: i18n.th,
    langBtn: "th",
    checkSelectLang: "",
    url: ""
  };

  changeLang = inputLng => {
    this.setState({
      i18n: i18n[inputLng],
      langBtn: inputLng
    });
  };

  checkPageReload() {
    if (window.performance) {
      if (
        window.performance.navigation.type == 1 &&
        history.action == "POP" &&
        history.location.pathname != "/" &&
        !history.location.pathname.includes("/center_pool/detail")
      ) {
        this.setState({ pageReload: true });
      }
    }
  }

  componentDidMount() {
    this.props.loadCredentials();
    this.checkPageReload();

    const url = new URL(window.location.href);

    this.setState({
      url: url.pathname.substring(18, -1)
    });
  }

  setMsg = (title, msg) => this.props.setFlashMessage(title, msg);

  onRemoveCredentials = () => {
    this.props.removeCredentials();
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    return (
      <Router>
        <Fragment>
          {this.state.url == "/verifyemail/check" ? (
            <Route
              path="/verifyemail/check"
              component={Verifyemail}
              done={this.props.done}
            />
          ) : !this.props.done ? (
            <Login auth={this.props.path} />
          ) : (
            <LangContext.Provider value={this.state.i18n}>
              <AppLayout>
                <SliderBar
                  collapsed={this.state.collapsed}
                  toggle={this.toggle}
                />
                )}
                <AppLayout
                  style={{ minHeight: `${window.innerHeight - 25}px` }}
                >
                  <Header
                    collapsed={this.state.collapsed}
                    toggle={this.toggle}
                    langBtn={this.state.langBtn}
                    changeLang={this.changeLang}
                    checkSelectLang={this.state.checkSelectLang}
                  />

                  {this.state.pageReload && (
                    <Alert
                      message={askForSoundPlayingText}
                      type="info"
                      showIcon
                      closeText={"Enable"}
                      closable
                      style={{ margin: "4px 4px 4px 4px" }}
                    />
                  )}
                  <PrivateRoute
                    key="/notification"
                    path="/notification"
                    component={props => <NotificationCenter {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/center_pool"
                    path="/center_pool"
                    component={props => <CenterPool {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/finished_pool"
                    path="/finished_pool"
                    component={props => <FinishedPool {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/cancelled_pool"
                    path="/cancelled_pool"
                    component={props => <CancelledPool {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/tracking"
                    path="/tracking"
                    component={props => <Tracking {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/dash_cameras"
                    path="/dash_cameras"
                    component={props => <DashCameras {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/alert_alarm"
                    path="/alert_alarm"
                    component={props => <AlertAlarm {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/booking_overview"
                    path="/booking_overview"
                    component={props => <BookingOverview {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/cashing_overview"
                    path="/cashing_overview"
                    component={props => <CashingOverview {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/cash_received"
                    path="/cash_received"
                    component={props => <CashReceived {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/receive_history"
                    path="/receive_history"
                    component={props => <ReceiveHistory {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/customer"
                    path="/customer"
                    component={props => <Customer {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/deliveryitem"
                    path="/deliveryitem"
                    component={props => <DeliveryItem {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/deliveryitem_delete_history"
                    path="/deliveryitem_delete_history"
                    component={props => (
                      <DeliveryItemDeleteHistory {...props} />
                    )}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/usergroup"
                    path="/usergroup"
                    component={props => <Usergroup {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/alarmalertbyplateno"
                    path="/report/alarmalertbyplateno"
                    component={props => <Alarmalertbyplateno {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/truckinspectionbytruck"
                    path="/report/truckinspectionbytruck"
                    component={props => <Truckinspectionbytruck {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/truckinspectionbyvendor"
                    path="/report/truckinspectionbyvendor"
                    component={props => <Truckinspectionbyvendor {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/summaryscorebyvendor"
                    path="/report/summaryscorebyvendor"
                    component={props => <Summaryscorebyvendor {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/truckinspectionbyvendoragg"
                    path="/report/truckinspectionbyvendoragg"
                    component={props => <Truckinspectionbyvendoragg {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/summaryscorebyvendoragg"
                    path="/report/summaryscorebyvendoragg"
                    component={props => <Summaryscorebyvendoragg {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/truckinspectionmaintenance"
                    path="/report/truckinspectionmaintenance"
                    component={props => <Truckinspectionmaintenance {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/truckinspectionbymonth"
                    path="/report/truckinspectionbymonth"
                    component={props => <Truckinspectionbymonth {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/gps_certification_request"
                    path="/gps_certification_request"
                    component={props => <GPSCertificationRequest {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/gps_certification_request_history"
                    path="/gps_certification_request_history"
                    component={props => (
                      <GPSCertificationRequestHistory {...props} />
                    )}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/alarmalertbydriver"
                    path="/report/alarmalertbydriver"
                    component={props => <Alarmalertbydriver {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/alarmalertfrequencybydriver"
                    path="/report/alarmalertfrequencybydriver"
                    component={props => (
                      <AlarmalertbydriverNewformet {...props} />
                    )}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/alarmalertbydurationtime"
                    path="/report/alarmalertbydurationtime"
                    component={props => <Alarmalertbydurationtime {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/drivingbehavior"
                    path="/report/drivingbehavior"
                    component={props => <Drivingbehavior {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/drivinglicensescanbehavior"
                    path="/report/drivinglicensescanbehavior"
                    component={props => (
                      <Drivinglicensescanbehavior {...props} />
                    )}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/alarmalertbyplatenoonyear"
                    path="/report/alarmalertbyplatenoonyear"
                    component={props => (
                      <Alarmalertbyplatenoonyear {...props} />
                    )}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/separatevehiclestatus"
                    path="/report/separatevehiclestatus"
                    component={props => <Separatevehiclestatus {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/announcement_broadcast"
                    path="/announcement_broadcast"
                    component={props => <Announcement {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/event_map"
                    path="/event_map"
                    component={props => <EventMap {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/concrete_delivery"
                    path="/concrete_delivery"
                    component={props => <ConcreteDelivery {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/replay"
                    path="/replay"
                    component={props => <Replay {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/comparevehiclescco"
                    path="/report/comparevehiclescco"
                    component={props => <Comparevehiclescco {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/alertdriverlicense"
                    path="/report/alertdriverlicense"
                    component={props => <Alertdriverlicense {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/report/parkingareaoutside"
                    path="/report/parkingareaoutside"
                    component={props => <Parkingareaoutside {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/pullvehiclesink"
                    path="/pullvehiclesink"
                    component={props => <Pullvehiclesink {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/location"
                    path="/location"
                    component={props => <Facility {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/user"
                    path="/user"
                    component={props => <User {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/vehiclegroup"
                    path="/vehiclegroup"
                    component={props => <VehicleGroupOwerview {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/route"
                    path="/route"
                    component={props => <RouteOverview {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/vehicle"
                    path="/vehicle"
                    component={props => <Vehicle {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/parking"
                    path="/report/parking"
                    component={props => <Parking {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/drivinglicensescanlog"
                    path="/report/drivinglicensescanlog"
                    component={props => <Drivinglicensescanlog {...props} />}
                    done={this.props.done}
                  />

                  {/* <PrivateRoute


                        <PrivateRoute
                          key="/report/drivinglicensescanlog"
                          path="/report/drivinglicensescanlog"
                          component={props => <Drivinglicensescanlog {...props} />}
                          done={this.props.done}
                        />

                        <PrivateRoute
                          key="/"
                          path="/"
                          component={props => <HomeDashboard {...props} />}

                        />


                        {/* <PrivateRoute

                        key="/vehicle"
                        path="/vehicle"
                        component={Vehicle}
                        done={this.props.done}
                      /> */}
                  <PrivateRoute
                    key="/"
                    path="/"
                    component={props => <HomeDashboard {...props} />}
                  />

                  <PrivateRoute
                    key="/report/speedoverlimitsummary"
                    path="/report/speedoverlimitsummary"
                    component={props => <Speedoverlimitsummary {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/drivinglog"
                    path="/report/drivinglog"
                    component={props => <Drivinglog {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/event"
                    path="/report/event"
                    component={props => <Event {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/summarykpi"
                    path="/report/summarykpi"
                    component={props => <Summarykpi {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/permission"
                    path="/permission"
                    component={props => <Permission {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/vehicle_sharing"
                    path="/vehicle_sharing"
                    component={props => <VehicleSharing {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/drivingdistance"
                    path="/drivingdistance"
                    component={props => <Drivingdistance {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/datavisibility"
                    path="/datavisibility"
                    component={props => <Datavisibility {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/loginhistory"
                    path="/report/loginhistory"
                    component={props => <Loginhistory {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/gpsstatus"
                    path="/report/gpsstatus"
                    component={props => <Gpsstatus {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/trackingdata"
                    path="/report/trackingdata"
                    component={props => <Trackingdata {...props} />}
                    done={this.props.done}
                  />

                  {/* <PrivateRoute
                      key="/verifyemail"
                      path="/verifyemail"
                      component={Verifyemail}
                      done={this.props.done}
                    /> */}

                  <PrivateRoute
                    key="/deviceuser"
                    path="/deviceuser"
                    component={props => <DriverUser {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/video"
                    path="/video"
                    component={props => <Video {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/locationgroup"
                    path="/locationgroup"
                    component={props => <LocationGroup {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/nearbylocation"
                    path="/nearbylocation"
                    component={props => <NearbyLocation {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/refuel"
                    path="/refuel"
                    component={props => <Refeul {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/truckusage"
                    path="/report/truckusage"
                    component={props => <Truckusage {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/drivingdistance"
                    path="/report/drivingdistance"
                    component={props => <Drivingdistance {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/temperature"
                    path="/report/temperature"
                    component={props => <Temperature {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/maintenance"
                    path="/report/maintenance"
                    component={props => <Maintenance {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/distfromlastmaintenance"
                    path="/report/distfromlastmaintenance"
                    component={props => <Distfromlastmaintenance {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/truckcurrentlocation"
                    path="/report/truckcurrentlocation"
                    component={props => <Truckcurrentlocation {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/driverinformation"
                    path="/report/driverinformation"
                    component={props => <Driverinformation {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/numberofdaysworked"
                    path="/report/numberofdaysworked"
                    component={props => <Numberofdaysworked {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/dozeoffrisk"
                    path="/report/dozeoffrisk"
                    component={props => <Dozeoffrisk {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/delivery"
                    path="/report/delivery"
                    component={props => <Delivery {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/triptemperature"
                    path="/report/triptemperature"
                    component={props => <Triptemperature {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/tripsumbyveh"
                    path="/report/tripsumbyveh"
                    component={props => <Tripsumbyveh {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/daily"
                    path="/report/daily"
                    component={props => <Daily {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/velocitysummary"
                    path="/report/velocitysummary"
                    component={props => <Velocitysummary {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/truckengineon"
                    path="/report/truckengineon"
                    component={props => <Truckengineon {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/truckengineoff"
                    path="/report/truckengineoff"
                    component={props => <Truckengineoff {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/drivinghour"
                    path="/report/drivinghour"
                    component={props => <Drivinghour {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/tripsummary"
                    path="/report/tripsummary"
                    component={props => <Tripsummary {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/drivingoverlimit"
                    path="/report/drivingoverlimit"
                    component={props => <Drivingoverlimit {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/tripsumbylocation"
                    path="/report/tripsumbylocation"
                    component={props => <Tripsumbylocation {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/insurance"
                    path="/report/insurance"
                    component={props => <Insurance {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/vehicleperformanceconclude"
                    path="/report/vehicleperformanceconclude"
                    component={props => (
                      <Vehicleperformanceconclude {...props} />
                    )}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/refuel"
                    path="/report/refuel"
                    component={props => <Refuel {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/trucknotuse"
                    path="/report/trucknotuse"
                    component={props => <Trucknotuse {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/riskbehaviour"
                    path="/report/riskbehaviour"
                    component={props => <Riskbehaviour {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/partner"
                    path="/partner"
                    component={props => <Partner {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/sharelocation"
                    path="/sharelocation"
                    component={props => <LocationSharing {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/vehicleinarea"
                    path="/report/vehicleinarea"
                    component={props => <Vehicleinarea {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/report/maintenancedeviceconclude"
                    path="/report/maintenancedeviceconclude"
                    component={props => (
                      <Maintenancedeviceconclude {...props} />
                    )}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/zone"
                    path="/zone"
                    component={props => <Zone {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/trip"
                    path="/trip"
                    component={props => <Trip {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/policy"
                    path="/policy"
                    component={props => <Policy {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/parttype"
                    path="/parttype"
                    component={props => <PartType {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/partgroup"
                    path="/partgroup"
                    component={props => <PartGroup {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/maintenance"
                    path="/maintenance"
                    component={props => <ManagementMaintenance {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/management/downloadcertificate"
                    path="/management/downloadcertificate"
                    component={props => <Downloadcertificate {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/management/contactmanagement"
                    path="/management/contactmanagement"
                    component={props => <Contactmanagement {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/mdvrdownloadfile"
                    path="/mdvrdownloadfile"
                    component={props => <Mdvrdownloadfile {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/servicerepairdevice"
                    path="/servicerepairdevice"
                    component={props => <Servicerepairdevice {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/deliverytrip"
                    path="/deliverytrip"
                    component={props => <Deliverytrip {...props} />}
                    done={this.props.done}
                  />
                </AppLayout>
              </AppLayout>
            </LangContext.Provider>
          )}
        </Fragment>
      </Router>
    );
  }
}
//export default Layout;

const mapStateToProps = ({
  auth: { accessToken, done, profile, tokenExp },
  ui: { flash }
}) => ({
  accessToken,
  done,
  profile,
  flash,
  tokenExp
});
const mapDispatchToProps = {
  removeCredentials: authActions.removeCredentials,
  loadCredentials: authActions.loadCredentials,
  clearFlashMessage: uiActions.clearFlashMessage,
  setFlashMessage: uiActions.setFlashMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
