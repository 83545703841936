import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";
import moment from "moment";
import { push } from "connected-react-router";




function* loadVehiclePullvehiclesink(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const { plate_no } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getvehicle`,
      {
        companyID: COMPANY_ID,
        vehicle_under_control:vehicle_visibility,
        plate_no
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehiclePullvehiclesink.success(res.data));
  } catch (err) {
    yield put(actions.loadVehiclePullvehiclesink.failure());
  }
}

function* loadPullvehiclesink(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const {
    data: { page, pageSize, searchName, orderBy, orderType, filterObj }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/pullvehiclesink`,
      {
        page,
        pageSize,
        searchName,
        orderBy,
        orderType,
        filterObj,
        company_id: COMPANY_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadPullvehiclesink.success(res.data));
  } catch (err) {
    yield put(actions.loadPullvehiclesink.failure());
  }
}

function* createPullvehiclesink(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const {
    data: { list_vehicle_id, pull_vehicle_sink_date }, loading, history
  } = action.payload;

  const date = moment(pull_vehicle_sink_date["_d"]).format("YYYY-MM-DD");
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/pullvehiclesink/create`,
      {
        list_vehicle_id,
        pull_vehicle_sink_date: date,
        company_id: COMPANY_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.createPullvehiclesink.success(res.data));
    history.push("/pullvehiclesink")

    yield put(push("/pullvehiclesink"));
  } catch (err) {
    yield put(actions.createPullvehiclesink.failure());
  }
}




function* updatePullvehiclesink(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const {
    data: { plate_no, pull_vehicle_sink_date },
    id, loading
  } = action.payload;
  const date = moment(pull_vehicle_sink_date["_d"]).format("YYYY-MM-DD");
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/pullvehiclesink/update`,
      {
        vehicle_id: plate_no,
        pull_vehicle_sink_date: date,
        company_id: COMPANY_ID,
        id
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.updatePullvehiclesink.success(res.data));
    //props.history.push("/pullvehiclesink");
  } catch (err) {
    yield put(actions.updatePullvehiclesink.failure());
  }
}

function* getPullvehiclesinkByID(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { id } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/pullvehiclesink/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.getPullvehiclesinkByID.success(res.data));
  } catch (err) {
    yield put(actions.getPullvehiclesinkByID.failure());
  }
}

function* deletePullvehiclesink(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { id } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENDPOINT}/pullvehiclesink/delete/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.deletePullvehiclesink.success(res.data));
  } catch (err) {
    yield put(actions.deletePullvehiclesink.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_PULLVEHICLESINK.REQUEST, loadPullvehiclesink),
    takeEvery(actions.CREATE_PULLVEHICLESINK.REQUEST, createPullvehiclesink),
    takeEvery(actions.UPDATE_PULLVEHICLESINK.REQUEST, updatePullvehiclesink),
    takeEvery(actions.GET_PULLVEHICLESINK.REQUEST, getPullvehiclesinkByID),
    takeEvery(
      actions.LOAD_VEHICLE_PULLVEHICLESINK.REQUEST,
      loadVehiclePullvehiclesink
    ),
    takeEvery(actions.DELETE_PULLVEHICLESINK.REQUEST, deletePullvehiclesink)
  ]);
}

export {
  loadPullvehiclesink,
  createPullvehiclesink,
  updatePullvehiclesink,
  getPullvehiclesinkByID,
  deletePullvehiclesink,
  loadVehiclePullvehiclesink
};
