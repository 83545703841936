import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { ACCESSTOKEN, COMPANY_ID } from "./../../constants/local_storage";
import * as actions from "./actions";

function* loadReceipt(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      vehicleVisibility,
      orderBy,
      orderType
    }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getreceipt`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        vehicleVisibility,
        orderBy,
        orderType
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadReceipt.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadReceipt.failure());
  }
}

function* loadReceiptDetail(action) {
  const {
    data: { receiptID },
    cb
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getreceiptdetail`,
      {
        receiptID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    //cb(res.data[0]);

    yield put(actions.loadReceiptDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadReceiptDetail.failure());
  }
}

export default function* watchBookingPoolState() {
  yield all([
    takeEvery(actions.LOAD_RECEIPT.REQUEST, loadReceipt),
    takeEvery(actions.LOAD_RECEIPT_DETAIL.REQUEST, loadReceiptDetail)
  ]);
}

export { loadReceipt, loadReceiptDetail };
