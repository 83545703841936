import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
import history from "./../../../lib/history";

import * as actions from "./actions";
import moment from "moment";

function* loadCompareVehicleScco(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/comparevehiclescco`,
      {
        company_id: COMPANY_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadCompareVehicleScco.success(res.data));
    window.location = res.data.data;
  } catch (err) {
    yield put(actions.loadCompareVehicleScco.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_COMPAREVEHICLESCCO.REQUEST, loadCompareVehicleScco)
  ]);
}

export { loadCompareVehicleScco };
