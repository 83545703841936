import {
    LOAD_REFUEL,
    LOAD_AUTOCOMPLETE,
    LOAD_AUTOCOMPLETE_VEHICLE_CODE,
    CREATE_REFUEl,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    lists: [],
    total: 1,
    loading: false,
    autoComplete: [],
    autoCompleteVehicleCode: [],
    check: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_REFUEL.REQUEST:
            const { loading } = action.payload.data;
            return {
                ...state,
                loading: loading,
            }

        case LOAD_REFUEL.SUCCESS:
            const { page } = action.payload;
                   
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                    action.payload.lists.data[i].rowNumber = i + 1;
                        if (page > 1)
                            action.payload.lists.data[i].rowNumber =
                                action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }
        
                return {
                    ...state,
                    lists: action.payload.lists.data,
                    total: 
                        action.payload.lists.total - 1 <= 0
                        ? 1
                        : action.payload.lists.total <= 0
                        ? 1
                        : action.payload.lists.total,
                        loading: false,
                    draw: Math.floor(Math.random() * 999 + 1)
                };

        case LOAD_AUTOCOMPLETE.SUCCESS:
            return {
                ...state,
                autoComplete: action.payload
            };

        case LOAD_AUTOCOMPLETE_VEHICLE_CODE.SUCCESS:
            return {
                ...state,
                autoCompleteVehicleCode: action.payload
            };

        case CREATE_REFUEl.SUCCESS:
            return {
                ...state,
                loading: false
            };
            
        default:
            return state;
    }
}