import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";
import moment from "moment";

function* loadAlarmAlertByDriver(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const {
    data: { list_driver_id, alarmalert_type_id, eventdate }
  } = action.payload;
  const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD");
  const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD");
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/alarmalertbydriver`,
      // `${process.env.REACT_APP_DEVELOP_TEST}/alarmalertbydriver`,
      {
        date_start,
        date_end,
        list_driver_id,
        company_id: COMPANY_ID,
        alarmalert_type_id
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadAlarmAlertByDriver.success(res.data));
    window.location = res.data.data;
  } catch (err) {
    yield put(actions.loadAlarmAlertByDriver.failure());
  }
}

function* loadDriver(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const VUC = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_ENDPOINT}/getdriver`,
      {
        allow_vehicles: VUC,
        company_id: COMPANY_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadDriver.success(res.data));
  } catch (err) {
    yield put(actions.loadDriver.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_ALARMALERTBYDRIVER.REQUEST, loadAlarmAlertByDriver),
    takeEvery(actions.LOAD_DRIVER_ALARMALERTBYDRIVER.REQUEST, loadDriver)
  ]);
}

export { loadAlarmAlertByDriver, loadDriver };
