import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Layout, Menu, Icon, Col } from "antd";
import styled from "styled-components";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { StyledSider, StyledImageHeader } from "../../../styled/common-styled";
import { AutorizeMenu } from "../../shared/components/CustomMenu";

import { autoGenKey } from "../../../lib/helper";

class SliderBar extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    const { collapsed } = this.props;

    const { permissions } = this.props.profile;

    return (
      <StyledSider trigger={null} collapsible collapsed={collapsed}>
        <Link to="/">
          <StyledImageHeader>
            <img style={{ width: "85px" }} src="/img/logo02.jfif" alt="" />
          </StyledImageHeader>

        </Link>

        <Menu theme="dark" mode="inline" inlineIndent={0}>
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="table" />
                <span>
                  <LangContext.Consumer>
                    {i18n => i18n.b.bookingPool}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="booking_pool"
            >
              <Link to="/center_pool">
                <Icon type="file-sync" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.b.booking}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="finished_pool"
            >
              <Link to="/finished_pool">
                <Icon type="file-done" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.f.finished}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="cancelled_pool"
            >
              <Link to="/cancelled_pool">
                <Icon type="file-done" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.c.cancelled}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>

          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="eye" />
                <span>
                  <LangContext.Consumer>
                    {i18n => i18n.m.monitoring}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="tracking_dashboard"
            >
              <Link to="/tracking">
                <Icon type="fullscreen-exit" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.t.tracking}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="concrete_dashboard"
            >
              <Link to="/concrete_delivery">
                <Icon type="filter" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.c.concreteDeliveryDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="dash_camera_dashboard"
            >
              <Link to="/dash_cameras">
                <Icon type="appstore" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.d.dashCamera}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="alert_alarm_dashboard"
            >
              <Link to="/alert_alarm">
                <Icon type="notification" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.a.alertAlarm}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="booking_overview_dashboard"
            >
              <Link to="/booking_overview">
                <Icon type="area-chart" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.b.bookingOverview}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="cashing_overview_dahsboar"
            >
              <Link to="/cashing_overview">
                <Icon type="area-chart" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.e.earningOverview}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="event_map_dashboard"
            >
              <Link to="/event_map">
                <Icon type="heat-map" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.e.eventMap}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="replay"
            >
              <Link to="/replay">
                <Icon type="caret-right" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.r.replay}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="video_mdvr"
            >
              <Link to="/video">
                <Icon type="video-camera" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.v.video}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="download_video_mdvr"
            >
              <Link to="/mdvrdownloadfile">
                <Icon type="video-camera" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.d.mdvrdownloadfile}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>

          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="folder" />
                <span>
                  <LangContext.Consumer>
                    {i18n => i18n.m.masterData}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="customer"
            >
              <Link to="/customer">
                <Icon type="user-add" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.c.customer}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="vehicle"
            >
              <Link to="/vehicle">
                <Icon type="car" />

                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.v.vehicle}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="vehiclegroup"
            >
              <Link to="/vehiclegroup">
                <Icon type="car" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.v.vehicleGroup}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="pull_vehicle_sink"
            >
              <Link to="/pullvehiclesink">
                <Icon type="user-add" />
                <span className="nav-text">ดึงรถจม</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="location"
            >
              <Link to="/location">
                <Icon type="ant-design" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.l.Location}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="locationgroup"
            >
              <Link to="/locationgroup">
                <Icon type="ant-design" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.l.locationGroup}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="nearbylocation"
            >
              <Link to="/nearbylocation">
                <Icon type="ant-design" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.n.nearByLocation}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key={autoGenKey("slider-sub-menu")} matching_name="zone">
              <Link to="/zone">
                <Icon type="cloud" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.z.zone}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="route"
            >
              <Link to="/route">
                <Icon type="stock" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.r.route}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="deviceuser"
            >
              <Link to="/deviceuser">
                <Icon type="solution" />

                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.c.chauffeur}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="partner"
            >
              <Link to="/partner">
                <Icon type="apartment" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.p.partnerCar}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="vehicle_sharing"
            >
              <Link to="/vehicle_sharing">
                <Icon type="share-alt" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.v.vehicleSharing}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="sharelocation"
            >
              <Link to="/sharelocation">
                <Icon type="share-alt" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.s.shareLocation}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="permission"
            >
              <Link to="/permission">
                <Icon type="user-add" />
                <span className="nav-text">Permission</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="usergroup"
            >
              <Link to="/usergroup">
                <Icon type="team" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.u.userGroup}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key={autoGenKey("slider-sub-menu")} matching_name="user">
              <Link to="/user">
                <Icon type="user" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.u.user}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="datavisibility"
            >
              <Link to="/datavisibility">
                <Icon type="share-alt" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.d.dataVisibility}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="parttype"
            >
              <Link to="/parttype">
                <Icon type="tool" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.p.partType}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="partgroup"
            >
              <Link to="/partgroup">
                <Icon type="tool" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.p.partgroup}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="maintenance"
            >
              <Link to="/maintenance">
                <Icon type="tool" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.m.maintenance}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="sendemail"
            >
              <Link to="/verifyemail">
                <Icon type="safety-certificate" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.a.accountVerification}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>

          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="retweet" />
                <span>
                  <LangContext.Consumer>
                    {i18n => i18n.d.deliver}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item key={autoGenKey("slider-sub-menu")} matching_name="trip">
              <Link to="/trip">
                <Icon type="pull-request" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.t.trip}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="delivery_trip"
            >
              <Link to="/deliverytrip">
                <Icon type="pull-request" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.d.deliverytrip}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>

          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="audit" />
                <span>
                  {" "}
                  <LangContext.Consumer>
                    {i18n => i18n.i.internalOperation}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.d.deliveryItem}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="deliveryitem"
              >
                <Link to="/deliveryitem">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.deliveryItemPool}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="deliveryitem_delete_history"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/deliveryitem_delete_history">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.deliveryItemDeleteHistoryPool}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey("slider-sub-menu")}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.g.gpsCertification}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="gps_certification_request"
              >
                <Link to="/gps_certification_request">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.g.gpsCertRequest}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                className={collapsed ? "" : "menu-item-wrap"}
                matching_name="gps_certification_request_history"
              >
                <Link to="/gps_certification_request_history">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.g.gpsCertRequestHistory}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey("slider-sub-menu")}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.a.announcement}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                className={collapsed ? "" : "menu-item-wrap"}
                matching_name="announcement_broadcast"
              >
                <Link to="/announcement_broadcast">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.a.announcementBroadcast}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </AutorizeMenu>

          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="profile" />
                <span>
                  {" "}
                  <LangContext.Consumer>
                    {i18n => i18n.r.report}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.SubMenu
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title="SCCO"
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_truckinspectionbyvendor"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/truckinspectionbyvendor">
                  <span className="nav-text">
                  รายงานการตรวจรถ รายผู้รับเหมา
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_summaryscorebyvendor"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/summaryscorebyvendor">
                  <span className="nav-text">
                  รายงานสรุปผลคะแนนรวมของผู้รับเหมา
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title="AGG"
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_truckinspectionbyvendoragg"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/truckinspectionbyvendoragg">
                  <span className="nav-text">
                  รายงานการตรวจรถ รายผู้รับเหมา
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_summaryscorebyvendoragg"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/summaryscorebyvendoragg">
                  <span className="nav-text">
                  รายงานสรุปผลคะแนนรวมของผู้รับเหมา
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.b.behavior_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alarmalertbyplateno"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alarmalertbyplateno">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n =>
                        i18n.a
                          .alarmAndAlertReportClassifiedByDailyCarRegistration
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alarmalertbydriver"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alarmalertbydriver">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DailyAlarmAndAlertReportByDriver}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alarmalertfrequencybydriver"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alarmalertfrequencybydriver">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n =>
                        i18n.r.reportAlarmFrequencyClassifiedByDailyDriver
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alarmalertbydurationtime"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alarmalertbydurationtime">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n =>
                        i18n.t
                          .top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivingbehavior"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivingbehavior">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.dailyDrivingBehaviorReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivinglicensescanbehavior"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivinglicensescanbehavior">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.r.reportCardSwipeBehaviorBeforeDrivingDaily}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alarmalertbyplatenoonyear"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alarmalertbyplatenoonyear">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n =>
                        i18n.a
                          .alarmAndAlertReportClassifiedByYearlyCarRegistration
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_separatevehiclestatus"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/separatevehiclestatus">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.dailyCarStatusReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alertdriverlicense"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alertdriverlicense">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.theDriverIsIicenseReportHasExpired}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_parkingareaoutside"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/parkingareaoutside">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n =>
                        i18n.r.reportSummarizingTheNumberOfOffSiteParking
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivinglog"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivinglog">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DrivinglogReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_speedoverlimitsummary"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/speedoverlimitsummary">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.s.SpeedoverlimitsummaryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_summarykpi"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/summarykpi">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.s.SummaryKPIReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_riskbehaviour"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/riskbehaviour">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.r.RiskbehaviourReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_vehicleinarea"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/vehicleinarea">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.v.VehicleinareaReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivinglicensescanlog"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivinglicensescanlog">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DrivinglicensescanlogReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivinghour"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivinghour">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DrivinghourReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.g.general_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_truckcurrentlocation"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/truckcurrentlocation">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.TruckcurrentlocationReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_driverinformation"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/driverinformation">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DriverinformationReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_numberofdaysworked"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/numberofdaysworked">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.n.NumberofdaysworkedReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_distfromlastmaintenance"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/distfromlastmaintenance">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DistfromlastmaintenanceReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_maintenance"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/maintenance">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.m.MaintenanceReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_temperature"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/temperature">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.TemperatureReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivingdistance"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivingdistance">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DrivingdistanceReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_event"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/event">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.e.EventReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_truckusage"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/truckusage">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.TruckusageReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_parking"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/parking">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.p.ParkingReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_comparevehiclescco"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/comparevehiclescco">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.c.CompareVehicleSccoReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivinghour"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivinghour">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.d.DrivinghourReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivingoverlimit"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivingoverlimit">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.d.DrivingoverlimitReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_dozeoffrisk"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/dozeoffrisk">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DozeoffriskReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_loginhistory"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/loginhistory">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.l.LoginhistoryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_gpsstatus"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/gpsstatus">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.g.GpsstatusReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_trackingdata"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/trackingdata">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.TrackingdataReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.e.efficiency_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_trucknotuse"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/trucknotuse">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.t.TrucknotuseReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_truckengineoff"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/truckengineoff">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.t.TruckengineoffReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_truckengineon"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/truckengineon">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.t.TruckengineonReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_velocitysummary"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/velocitysummary">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.v.VelocitysummaryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_vehicleperformanceconclude"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/vehicleperformanceconclude">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.v.VehicleperformanceconcludeReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.t.trip_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_tripsumbylocation"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/tripsumbylocation">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.t.TripsumbylocationReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_tripsummary"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/tripsummary">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.t.TripsummaryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_daily"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/daily">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DailyReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_tripsumbyveh"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/tripsumbyveh">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.TripsumbyvehReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_triptemperature"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/triptemperature">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.TriptemperatureReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_delivery"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/delivery">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DeliveryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.e.energy_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_refuel"
              >
                <Link to="/report/refuel">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.r.RefuelReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.m.maintenancedevice_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_maintenancedeviceconclude"
              >
                <Link to="/report/maintenancedeviceconclude">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.m.MaintenancedeviceconcludeReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.i.insure_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_insurance"
              >
                <Link to="/report/insurance">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {i18n => i18n.i.InsuranceReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </AutorizeMenu>

          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="question" />
                <span>
                  <LangContext.Consumer>
                    {i18n => i18n.h.help_menu}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="servicerepairdevice"
            >
              <Link to="/servicerepairdevice">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.s.Servicerepairdevice}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>

          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="setting" />
                <span>
                  <LangContext.Consumer>
                    {i18n => i18n.s.setting}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="policy"
            >
              <Link to="/policy">
                <Icon type="read" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.p.policy}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
        </Menu>
      </StyledSider>
    );
  }
}

// export default SliderBar;
const mapStateToProps = ({ auth: { profile } }) => ({
  profile
});

export default connect(mapStateToProps, null)(SliderBar);
