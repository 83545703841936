import { createTypes, createAction } from "lib/action";

const LOAD_SUMMARYSCOREBYVENDOR = createTypes("summaryscorebyvendor", "load");
const LOAD_COMPANY_SUMMARYSCOREBYVENDOR = createTypes(
  "company_summaryscorebyvendor",
  "load"
);
//const LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO = createTypes("vehicletype_alarmalertbyplateno", "load");

const loadAlarmAlertByPlateno = {
  request: (data, loading) =>
    createAction(LOAD_SUMMARYSCOREBYVENDOR.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SUMMARYSCOREBYVENDOR.SUCCESS, { data }),
  failure: () => createAction(LOAD_SUMMARYSCOREBYVENDOR.FAILURE)
};

const loadVehicle = {
  request: (loading_vehicle) =>
    createAction(LOAD_COMPANY_SUMMARYSCOREBYVENDOR.REQUEST, {
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_COMPANY_SUMMARYSCOREBYVENDOR.SUCCESS, { data }),
  failure: () => createAction(LOAD_COMPANY_SUMMARYSCOREBYVENDOR.FAILURE)
};



export {
  loadAlarmAlertByPlateno,
  LOAD_SUMMARYSCOREBYVENDOR,
  loadVehicle,
  LOAD_COMPANY_SUMMARYSCOREBYVENDOR
  //loadVehicleType,
  //LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO
};
