import {all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";


function* loadVehicleGroup(action) {

    const {
        data: { page, pageSize, orderBy, orderType, vehicle_visibility, accessToken, searchName, companyID, filterObj}
    } = action.payload;

    try {
        const res = yield call(
            axios.post,
                `${process.env.REACT_APP_API_ENDPOINT}/masterDataVehicleGroup`,
                //  `http://booking-api/api/vehicleGroup/${id}`,
            {  
                page,
                pageSize,
                vehicle_visibility,
                orderBy,
                orderType,
                searchName,
                companyID,
                filterObj
            },           
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
 
        yield put(actions.loadVehicleGroup.success(res.data, page));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadVehicleGroup.failure());
    }
}

function* createVehicleGroup(action) {
    const {
      data: {companyID, selected, vehiclegroupname, accessToken}, cb
    } = action.payload;
    try {
      const res = yield call(
        axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/createVehicleGroup`, 
        // `${DEV_API_ENDPOINT}/customer/create`,
        {
            companyID,
            selected,
            vehiclegroupname
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      cb(res.data);
      yield put(actions.createVehicleGroup.success(res.data));
      
    } catch (err) {
      console.log(err.message);
      yield put(actions.createVehicleGroup.failure());
    }
  }

function* loadvehicleGroupDetail(action){
    const {
        data: { id, page, pageSize, orderBy, orderType, searchName, accessToken}
    } = action.payload;

    try {
        const res = yield call(
            axios.post,
                `${process.env.REACT_APP_API_ENDPOINT}/vehiclegroupname/${id}`,
            {  
                id,
                page,
                pageSize,
                orderBy,
                orderType,
                searchName
            },           
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
 
        yield put(actions.loadvehicleGroupDetail.success(res.data, page));

    } catch (err) {
        console.log(err.message);
        yield put(actions.loadvehicleGroupDetail.failure());
    }
}

function* upDateVehicleGroup(action)
{
    const {
        data: {vehicle_groups_id, companyId, groupname, selected, accessToken}, loading
      } = action.payload;

      try {
          const res = yield call(
            axios.post,
                `${process.env.REACT_APP_API_ENDPOINT}/editVehicleGroup`,
            {
                vehicle_groups_id,
                companyId,
                groupname,
                selected,
                accessToken
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
          );
          loading(res.data);
          yield put(actions.upDateVehicleGroup.success(res.data));
      } catch (err) {
        console.log(err.message);
        yield put(actions.upDateVehicleGroup.failure());
      }

}

export default function* watchDataVehicleGroup() {
    yield all([
        takeEvery(actions.LOAD_VEHICLEGROUP.REQUEST, loadVehicleGroup),
        takeEvery(actions.CREATE_VEHICLEGROUP.REQUEST, createVehicleGroup),
        takeEvery(actions.LOAD_VEHICLEGROUP_DETAIL.REQUEST, loadvehicleGroupDetail),
        takeEvery(actions.UPDATE_VEHICLEGROUP.REQUEST, upDateVehicleGroup)
    ]);
}

export{loadVehicleGroup, createVehicleGroup, loadvehicleGroupDetail, upDateVehicleGroup};