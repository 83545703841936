import {all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadAutocompleteLocation(action) {
    const { url, companyID, accessToken, queryString } = action.payload;  
    try {
      if (queryString == "" || queryString.length <= 1) {
            yield put(actions.loadAutocompleteLocation.success([]));
      } else {
            const res = yield call(
                axios.get,
                    `${process.env.REACT_APP_API_ENDPOINT}/${url}/${companyID}/${queryString}`,
                    { headers: { Authorization: `Bearer ${accessToken}` } }
            );
            yield put(actions.loadAutocompleteLocation.success(res.data));
        }
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadAutocompleteLocation.failure());
    }
}

function* selectedAutocompleteLocation(action) {
    const { id, autoCompleteLists } = action.payload;
    try {
      let selected = autoCompleteLists.filter(function(ele) {
        return ele.id == id;
      })[0];
  
      yield put(actions.selectedAutocompleteLocation.success(selected));
    } catch (err) {
      console.log(err.message);
      yield put(actions.selectedAutocompleteLocation.failure());
    }
  }

export default function* watchNearbyLocation() {
    yield all([
        takeEvery(actions.LOAD_AUTOCOMPLETE.REQUEST, loadAutocompleteLocation),
        takeEvery(actions.SELECTED_AUTOCOMPLETE.REQUEST, selectedAutocompleteLocation),
    ]);
}
export {
    loadAutocompleteLocation,
    selectedAutocompleteLocation
}