import {
  SET_FLASH_MESSAGE,
  CLEAR_FLASH_MESSAGE,
  LOAD_NOTIFICATION_COUNT,
  DECREASE_NOTIFICATION_COUNT,
  INCREASE_NOTIFICATION_COUNT,
  UPDATE_DRAWING_TABLE_AT,
  REMOVE_UI_STATE
} from "./actions";

const initialState = {
  lastestNotificationAt: null,
  lastestNotificationTopic: null,
  flash: null,
  notificationCount: 0,
  drawingTableAt: new Date().getTime()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INCREASE_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: state.notificationCount + 1,
        lastestNotificationAt: new Date().getTime(),
        lastestNotificationTopic: action.payload.topic,
        lastestNotificationMessage: action.payload.message
      };
    case UPDATE_DRAWING_TABLE_AT:
      return {
        ...state,
        lastestNotificationTopic: action.payload.topic,
        drawingTableAt: new Date().getTime()
      };
    case SET_FLASH_MESSAGE:
      return { ...state, flash: action.payload };
    case CLEAR_FLASH_MESSAGE:
      return { ...state, flash: null };
    case LOAD_NOTIFICATION_COUNT.SUCCESS:
      return {
        ...state,
        notificationCount: action.payload.data
      };
    case DECREASE_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: state.notificationCount - 1
      };
    case REMOVE_UI_STATE:
      return initialState;
    default:
      return state;
  }
};
