import { createTypes, createAction } from "lib/action";

const LOAD_ALARMALERTBYPLATENOAGG = createTypes("alarmalertbyplatenoagg", "load");
const LOAD_VEHICLE_ALARMALERTBYPLATENOAGG = createTypes(
  "vehicle_alarmalertbyplatenoagg",
  "load"
);
const LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENOAGG = createTypes("vehicletype_alarmalertbyplatenoagg", "load");

const loadAlarmAlertByPlateno = {
  request: (data, loading) =>
    createAction(LOAD_ALARMALERTBYPLATENOAGG.REQUEST, { data, loading }),
  success: data => createAction(LOAD_ALARMALERTBYPLATENOAGG.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARMALERTBYPLATENOAGG.FAILURE)
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_ALARMALERTBYPLATENOAGG.REQUEST, {
      vehicletypeID,
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_ALARMALERTBYPLATENOAGG.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_ALARMALERTBYPLATENOAGG.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENOAGG.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENOAGG.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENOAGG.FAILURE)
};

export {
  loadAlarmAlertByPlateno,
  LOAD_ALARMALERTBYPLATENOAGG,
  loadVehicle,
  LOAD_VEHICLE_ALARMALERTBYPLATENOAGG,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENOAGG
};
