import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadDeviceUser(action) {
    const {
        data: {
            page,
            pageSize,
            companyID,
            orderBy,
            orderType,
            accessToken,
            filterObj
        }
    } = action.payload;

    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/deviceuser`,
            {
                page,
                pageSize,
                companyID,
                orderBy,
                orderType,
                filterObj
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        yield put(actions.loadDeviceUser.success(res.data, page));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadDeviceUser.failure());
    }
}

function* createDeviceUser(action) {
    const {
        data: {
            company_id, first_name, employee_id,
            personal_id, issued_date, expire_date, address, last_name,
            full_name, date_of_birth, phone_number, join_date, contractor,
            accounts, start_valid_date, end_valid_date, plant_factory, rfid,
            driving_license_number, driving_license_expire_date, plant_factory_id, accessToken,
            image
        }, loading
    } = action.payload;

    const formData = new FormData();
    let uploadFile = null;

    if(image != ""){
        image.forEach(File => {
        uploadFile = File;
        });
    }
    
      formData.append("files", uploadFile);
      formData.append("company_id", company_id);
      formData.append("employee_id", employee_id);
      formData.append("personal_id", personal_id);
      formData.append("issued_date", issued_date);
      formData.append("expire_date", expire_date);
      formData.append("address", address);
      formData.append("last_name", last_name);
      formData.append("full_name", full_name);
      formData.append("first_name", first_name);
      formData.append("date_of_birth", date_of_birth);
      formData.append("phone_number", phone_number);
      formData.append("join_date", join_date);
      formData.append("contractor", contractor);
      formData.append("accounts", accounts);
      formData.append("start_valid_date", start_valid_date);
      formData.append("end_valid_date", end_valid_date);
      formData.append("plant_factory", plant_factory);
      formData.append("plant_factory_id", plant_factory_id);
      formData.append("rfid", rfid);
      formData.append("driving_license_number", driving_license_number);
      formData.append("driving_license_expire_date", driving_license_expire_date); 
      formData.append("driving_license_expi", driving_license_expire_date); 
    //   formData.append("images", uploadFile);

    try{ 

        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/adddeviceuser`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            }
        );
        loading(res.data);
        yield put(actions.createDeviceUser.success(res.data));
    } catch (err){
        console.log(err.message);
        yield put(actions.createDeviceUser.failure());
    }
}


function* updateDeviceUser(action)
{
    const {
        data: {       
            device_user_id, company_id, first_name, employee_id,
            personal_id, issued_date, expire_date, address, last_name,
            full_name, date_of_birth, phone_number, join_date, contractor,
            accounts, start_valid_date, end_valid_date , plant_factory, rfid,
            driving_license_number, driving_license_expire_date, image, accessToken,
        }, loading
    } = action.payload;

    try{
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/updatedrivinglicense`,
            {
                device_user_id, company_id, first_name, employee_id,
                personal_id, issued_date, expire_date, address, last_name,
                full_name, date_of_birth, phone_number, join_date, contractor,
                accounts, start_valid_date, end_valid_date, plant_factory, rfid,
                driving_license_number, driving_license_expire_date, image
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        loading(res.data);
        yield put(actions.updateDeviceUser.success(res.data));
    } catch (err){
        console.log(err.message);
        yield put(actions.updateDeviceUser.failure());
    }
}

function* loadAutocompleteLocation(action) {
    const { url, companyID, accessToken, queryString } = action.payload;  
    try {
      if (queryString == "" || queryString.length <= 1) {
        yield put(actions.loadAutocompleteLocation.success([]));
      } else {
        const res = yield call(
          axios.get,
          `${process.env.REACT_APP_API_ENDPOINT}/${url}/${companyID}/${queryString}`,
  
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
  
        yield put(actions.loadAutocompleteLocation.success(res.data));
      }
    } catch (err) {
  
      console.log(err.message);
      yield put(actions.loadAutocompleteLocation.failure());
    }
  }

function* loadDeviceuserDetail(action)
{
    const {
        data: {
            id, accessToken
        }
    } = action.payload;
    try{

        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/deviceuser/detali`,
            {
                id
            },
            { headers: { Authorization: `Bearer ${accessToken}` }}
        );
        yield put(actions.loadDeviceuserDetail.success(res.data));

    } catch (err) {
        console.log(err.message);
        yield put(actions.loadDeviceuserDetail.failure());
    }
}

function* loadDatadrivinglicans(action)
{
    const {
        data: {
            id, accessToken, page, pageSize,
        }
    } = action.payload;

    try {

        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/drivinglicense`,
            {
                id,
                page,
                pageSize
            },
            { headers: { Authorization: `Bearer ${accessToken}` }}
        );
        yield put(actions.loadDatadrivinglicans.success(res.data, page));

    } catch (err) {
        console.log(err.message);
        yield put(actions.loadDatadrivinglicans.failure());
    }
}

function* loadDatadrivingtranining(action)
{
    const {
        data: {
            id, accessToken, page, pageSize,
        }
    } = action.payload;

    try {

        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_API_ENDPOINT}/drivingtraining`,
            {
                id,
                page,
                pageSize
            },
            { headers: { Authorization: `Bearer ${accessToken}` }}
        );
        yield put(actions.loadDatadrivingtranining.success(res.data, page));

    } catch (err) {
        console.log(err.message);
        yield put(actions.loadDatadrivingtranining.failure());
    }
}

export default function* watchDeviceUser() {
    yield all([
        takeEvery(actions.LOAD_DEVICE_USER.REQUEST, loadDeviceUser),
        takeEvery(actions.CREATE_DEVICE_USER.REQUEST, createDeviceUser),
        takeEvery(actions.LOAD_DEVICE_USER_DETAIL.REQUEST, loadDeviceuserDetail),
        takeEvery(actions.LOAD_DRIVEING_LICENSEL.REQUEST, loadDatadrivinglicans),
        takeEvery(actions.LOAD_DRIVEING_TRAINING.REQUEST, loadDatadrivingtranining),
        takeEvery(actions.UPDATE_DEVICE_USER.REQUEST, updateDeviceUser),
        takeEvery(actions.LOAD_AUTOCOMPLETE.REQUEST, loadAutocompleteLocation),
    ]);
}

export {
    loadDeviceUser,
    createDeviceUser,
    loadDeviceuserDetail,
    loadDatadrivinglicans,
    loadDatadrivingtranining,
    updateDeviceUser,
    loadAutocompleteLocation
};