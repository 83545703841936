import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { Button, Form, Input, Row, Col, DatePicker, AutoComplete, Icon, Select } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option } = AutoComplete;
export default class Filter extends Component {
  state = {
    platenoList: [],
  };

  componentDidMount() {
    const { loadVehicle } = this.props;
    loadVehicle();
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleLists } = nextProps;

    const platenolists = [];
    vehicleLists.map(item =>
      platenolists.push({
        key: item.id,
        name: item.plate_no,
        devidno: item.devidno,
        online: item.online
      })
    );

    this.setState({ platenoList: platenolists });
  };


  changeVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue("vehicle_plate_no", option.props.plate_no);
    setFieldValue("plate_no", option.props.children);
    setFieldValue("devidno", option.props.label);

  };

  selectVehiclePlateno = (value, option, setFieldValue) => {
    //console.log(option);
    setFieldValue("vehicle_plate_no", option.props.plate_no);
    setFieldValue("plate_no", option.props.children);
    setFieldValue("devidno", option.props.label);
  };

  render() {
    const { OnClose, onSubmit } = this.props;
    const platenolists = this.state.platenoList
      .map((v) => (
        <Option key={v.key} label={v.devidno} plate_no={v.name}>
          {v.name}{" "}{v.online == 1 ? <Icon type="car" theme="twoTone" twoToneColor="#00BF06" /> : <Icon type="car" theme="twoTone" twoToneColor="#c91c1c" />}
        </Option>
      ))

    return (
      <div>
        <Formik
          initialValues={{
            plate_no: "",
            eventdate: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
          }}
          validate={values => {
            let errors = {};
            if (values.eventdate.length > 0) {
              const date_start = moment(values.eventdate[0]["_d"]);
              const date_end = moment(values.eventdate[1]["_d"]);
              let day_diff = date_end.diff(date_start, "days");
              if (day_diff > 1) {
                errors.eventdate = "ช่วงวันที่ที่เลือกไม่ควรเกิน 1 วัน";
              }
            }

            return errors;
          }}
          validationSchema={yup.object().shape({
            plate_no: yup.string().required("กรุณาเลือกทะเบียนพาหนะ"),
            eventdate: yup.array().required("กรุณาเลือกช่วงวันที่"),
          })}
          onSubmit={values => {
            const loading = true;
            onSubmit(values, loading);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm
          }) => (
              <Row type="flex">
                <Form onSubmit={handleSubmit}>
                  <FormItem

                    label={<LangContext.Consumer>
                      {i18n => i18n.p.plateNo}
                    </LangContext.Consumer>}
                    validateStatus={
                      touched.plate_no && errors.plate_no && "error"
                    }
                    help={touched.plate_no && errors.plate_no}
                  >
                    <AutoComplete
                      dataSource={platenolists}
                      style={{ width: 200 }}
                      optionLabelProp="children"
                      filterOption={true}
                      optionFilterProp="children"
                      // placeholder="ค้นหาทะเบียน"
                      value={values.plate_no}
                      onChange={(value, option) =>
                        this.changeVehiclePlateno(
                          value,
                          option,
                          setFieldValue
                        )
                      }
                      onSelect={(value, option) =>
                        this.selectVehiclePlateno(
                          value,
                          option,
                          setFieldValue
                        )
                      }


                    />
                  </FormItem>

                  <Form.Item
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.d.dateRange}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.eventdate && errors.eventdate && "error"
                    }
                    help={touched.eventdate && errors.eventdate}
                  >
                    <RangePicker
                      showTime={{ format: "HH:mm:ss" }}
                      onChange={value => setFieldValue("eventdate", value)}
                      format="DD/MM/YYYY HH:mm:ss"
                      placeholder={["Start Time", "End Time"]}
                      defaultValue={[
                        moment("00:00:00", "HH:mm:ss"),
                        moment("23:59:59", "HH:mm:ss")
                      ]}
                    />
                  </Form.Item>


                  <FormItem>
                    <Row gutter={24}>
                      <Col span={3}>
                        <Button
                          onClick={OnClose}
                          style={{ paddingLeft: 5 }}
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.c.cancel}
                          </LangContext.Consumer>

                        </Button>
                      </Col>
                      <Col span={2} />
                      <Col span={3}>
                        <Button htmlType="submit" type="primary">
                          <LangContext.Consumer>
                            {i18n => i18n.a.apply}
                          </LangContext.Consumer>
                        </Button>
                      </Col>
                    </Row>
                  </FormItem>
                </Form>

              </Row>
            )}
        />
      </div>
    );
  }
}
