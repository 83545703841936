import React, { Component, Fragment } from "react";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Button, Row, Col, Icon, Spin } from "antd";
import { ResponsiveBar } from "@nivo/bar";

import { StyledWidget } from "../../../../styled/common-styled";

const ButtonGroup = Button.Group;

const fakeData = [
  {
    id: "Jan",
    label: "Jan",
    value: 89
  },
  {
    id: "Feb",
    label: "Feb",
    value: 110
  },
  {
    id: "Mar",
    label: "Mar",
    value: 100
  },
  {
    id: "Apr",
    label: "Apr",
    value: 134
  },
  {
    id: "May",
    label: "May",
    value: 65
  },
  {
    id: "Jun",
    label: "Jun",
    value: 78
  },
  {
    id: "Jul",
    label: "Jul",
    value: 55
  },
  {
    id: "Aug",
    label: "Aug",
    value: 40
  },
  {
    id: "Sep",
    label: "Sep",
    value: 38
  },
  {
    id: "Oct",
    label: "Oct",
    value: 46
  },
  {
    id: "Nov",
    label: "Nov",
    value: 69
  },
  {
    id: "Dec",
    label: "Dec",
    value: 0
  }
];

const Chart = ({ data, changeChartType, chartType }) => (
  <ResponsiveBar
    onClick={changeChartType}
    data={data}
    keys={["value"]}
    indexBy="label"
    margin={{ top: 20, right: 30, bottom: 50, left: 45 }}
    padding={0.3}
    colors={["#379AEC", "#37DF26", "#E0E52E", "#E52E3F"]}
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend:
        chartType == "yearly" ? (
          <LangContext.Consumer>{i18n => i18n.m.month}</LangContext.Consumer>
        ) : (
          <LangContext.Consumer>{i18n => i18n.d.day}</LangContext.Consumer>
        ),
      legendPosition: "middle",
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: (
        <LangContext.Consumer>{i18n => i18n.a.amount}</LangContext.Consumer>
      ),
      legendPosition: "middle",
      legendOffset: -37
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 2.5]] }}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);

export class SpeedOverlimitWidget extends Component {
  state = {
    chartType: "yearly",
    loading: false,
    data: []
  };

  loadData = (loading, chartType) => {
    if (loading) this.setState({ loading: true });
    this.fetch(this, chartType);
  };

  fetch = (self, chartType) =>
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/widget/getvehiclespeedoverlimitchart`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${self.props.auth.accessToken}`
        },
        body: JSON.stringify({
          user_id: self.props.auth.profile.id,
          company_id: self.props.auth.profile.company_id,
          chart_type: chartType,
          vehicle_visibility: self.props.auth.profile.vehicle_visibility
        })
      }
    )
      .then(response => response.json())
      .then(res => {
        if (Array.isArray(res)) {
          self.setState({
            loading: false,
            data: res
          });
        }
      })
      .catch(function() {
        self.setState({
          loading: false,
          data: []
        });
      });

  componentDidMount() {
    if (!this.props.demo) {
      this.loadData(true, this.state.chartType);
      this.interval = setInterval(
        () => this.loadData(false, this.state.chartType),
        1000 * 60 * 60
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    if (this.props.demo)
      return (
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ height: "90%", width: "100%" }}
        >
          <h4>
            <LangContext.Consumer>
              {i18n => i18n.s.speedOverLimit}
            </LangContext.Consumer>
          </h4>

          <Chart data={fakeData} chartType={this.state.chartType} />
        </Col>
      );
    else
      return (
        <StyledWidget>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ height: "95%" }}
          >
            <Spin
              spinning={this.state.loading}
              style={{
                textAlign: "center",
                display: "block",
                marginTop: "15%",
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h4>
                    <LangContext.Consumer>
                      {i18n => i18n.s.speedOverLimit}
                    </LangContext.Consumer>
                    {this.state.chartType != "yearly" &&
                      ` : ${this.state.chartType}`}{" "}
                  </h4>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                  <ButtonGroup size="small">
                    {this.state.chartType != "yearly" && (
                      <Button
                        onClick={e => {
                          this.setState({ chartType: "yearly" });
                          this.loadData(true, "yearly");
                        }}
                      >
                        <Icon type="arrow-left" />{" "}
                        <LangContext.Consumer>
                          {i18n => i18n.b.back}
                        </LangContext.Consumer>
                      </Button>
                    )}
                    {/* <Button>
                    <Icon type="control" />
                  </Button> */}
                  </ButtonGroup>
                </Col>
              </Row>
            </Spin>
            <Chart
              data={this.state.data}
              changeChartType={(e, i) => {
                if (isNaN(e.data.label)) {
                  this.setState({ chartType: e.data.label });
                  this.loadData(true, e.data.month);
                }
              }}
              chartType={this.state.chartType}
            />
          </Col>
        </StyledWidget>
      );
  }
}

export default SpeedOverlimitWidget;
